import React, { useEffect, useRef, useState } from 'react';
import moment from 'moment';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import MessagesApi, { Conversation, Message } from '@services/messages';
import sortBy from 'lodash/sortBy';
import {
    Alert,
    Avatar,
    Badge,
    Button,
    Card,
    Col,
    Comment,
    Empty,
    Form,
    Input,
    List,
    Menu,
    Modal,
    Row,
    Select,
    Space,
    Spin,
    Tooltip,
    Typography,
} from 'antd';
import { AxiosError } from 'axios';
import { useForm } from 'antd/lib/form/Form';
import { getInitials } from '@util/initials';
import { AdminLayout } from '@components/layouts/admin';
import AdminUsersApi, { UserType } from '@services/admin-users';
import { deepSelect } from '@util/react-query';

interface SendMessageForm {
    message: string;
    recipient_id: number;
}

export const AdminMessagesPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);
    const queryClient = useQueryClient();
    const bottomRef = useRef<HTMLDivElement | null>(null);

    const [selectedConversation, setSelectedConversation] = useState<Conversation>();
    const [showNewConversationModal, setShowNewConversationModal] = useState(false);
    const [form] = useForm();
    const [createConversationForm] = useForm();

    const { data: users, isLoading: loadingUsers } = useQuery(
        ['users'],
        () => AdminUsersApi.getUsers(UserType.ALL, '', { pageSize: 1000 }),
        {
            staleTime: 500,
            select: deepSelect,
        },
    );

    // console.log('AdminMessagesPage - users: ', users);

    const { isLoading, data: conversationsData } = useQuery(['conversations-admin'], MessagesApi.getConversations, {
        staleTime: 500,
        select: deepSelect,
    });

    // console.log('AdminMessagesPage - conversationsData: ', conversationsData);

    const { isLoading: isLoadingMessages, data: messagesData, refetch: refetchMessages } = useQuery(
        ['messages', selectedConversation?.id as number],
        async () => {
            if (selectedConversation) {
                const data = await MessagesApi.getMessages(Number(selectedConversation?.id));
                scrollToBottom();
                return data;
            }
        },
        {
            enabled: !!selectedConversation?.id,
            select: deepSelect,
        },
    );

    const scrollToBottom = () => {
        setTimeout(() => {
            bottomRef?.current?.scrollIntoView({
                behavior: 'smooth',
                block: 'end',
            });
        }, 100);
    };

    useEffect(() => {
        if (!selectedConversation) {
            return;
        }

        if (!conversationsData?.conversations) {
            return;
        }

        queryClient.setQueryData(['conversations-admin'], {
            conversations: sortBy(
                [
                    ...conversationsData?.conversations.filter((c) => c.id !== selectedConversation.id),
                    {
                        ...conversationsData?.conversations.find((c) => c.id === selectedConversation.id),
                        seen_by_admin: true,
                    },
                ],
                (conversation: Conversation) =>
                    -(!conversation.seen_by_admin ? 10 : 1) * moment(conversation.updated_at).valueOf(),
            ),
        });

        scrollToBottom();
    }, [selectedConversation]);

    const mutation = useMutation<void, AxiosError, SendMessageForm & { newConversation: boolean }>(
        async ({ message, recipient_id, newConversation = false }) => {
            const hasConversation = !!conversationsData?.conversations?.find((c) => c.recipient_id === recipient_id);

            if ((hasConversation || selectedConversation) && !newConversation) {
                await MessagesApi.sendMessage(selectedConversation?.id as number, {
                    detail: message,
                    recipient_id: recipient_id || selectedConversation?.recipient_id,
                });
                scrollToBottom();
            } else {
                const { id } = await MessagesApi.createConversation({ detail: message, recipient_id });
                const conversationData = await MessagesApi.getMessages(id);
                setSelectedConversation(conversationData.conversation);
            }
            await refetchMessages();
            form.resetFields();
            createConversationForm.resetFields();

            if (showNewConversationModal) {
                setShowNewConversationModal(false);
            }
        },
        {
            onSuccess() {
                queryClient.invalidateQueries('conversations-admin');
            },
        },
    );

    const parseMessages = (messages: Message[] | undefined) => {
        return messages
            ?.map((message) => ({
                isMe: message.type !== 'user',
                content: message.detail,
                author:
                    message.type === 'user'
                        ? selectedConversation?.recipients?.name
                        : `Support (${message?.user?.email})`,
                avatar: (
                    <Avatar style={{ background: message.type === 'user' ? '#4B0082' : '#6495ED' }}>
                        {getInitials(message.type === 'user' ? selectedConversation?.recipients?.name : 'Support')}
                    </Avatar>
                ),
                datetime: (
                    <Tooltip title={moment(message.created_at).format('YYYY-MM-DD HH:mm:ss')}>
                        <span>{moment(message.created_at).fromNow()}</span>
                    </Tooltip>
                ),
            }))
            .reverse();
    };

    const newMessageForm = (
        <Form
            name="new-message-form"
            form={createConversationForm}
            onFinish={(values) => mutation.mutate({ ...values, newConversation: true })}
        >
            <Form.Item name="recipient_id" rules={[{ required: true, message: 'Please select a user!' }]}>
                <Select
                    loading={loadingUsers}
                    showSearch
                    placeholder="Select a user"
                    optionFilterProp="children"
                    filterOption={(input, option) => {
                        return option?.children?.join(' ')?.toLowerCase()?.indexOf(input?.toLowerCase()) >= 0;
                    }}
                >
                    {users
                        ?.filter((u) => !conversationsData?.conversations?.some((c) => c.recipient_id === u.id))
                        ?.map((user) => (
                            <Select.Option key={user.id} value={user.id}>
                                {user.name} ({user.email})
                            </Select.Option>
                        ))}
                </Select>
            </Form.Item>

            <Form.Item>
                <Button form="new-message-form" htmlType="submit" type="primary">
                    Start a Conversation
                </Button>
            </Form.Item>
        </Form>
    );

    return (
        <AdminLayout>
            <Helmet>
                <title>Messages | Copyknight Admin</title>
            </Helmet>

            {!(isLoading || isLoadingMessages) && !conversationsData?.conversations?.length && (
                <Empty description="There are no conversations at the moment.">{newMessageForm}</Empty>
            )}

            {!!conversationsData?.conversations?.length && (
                <Row gutter={12} style={{ height: '100%' }}>
                    <Col span={6} style={{ maxHeight: '79vh', overflow: 'auto' }}>
                        {isLoading && <Spin size="large" spinning />}

                        <Button
                            size="large"
                            type="primary"
                            style={{ width: '100%' }}
                            onClick={() => setShowNewConversationModal(true)}
                        >
                            + New conversation
                        </Button>

                        <Menu
                            mode="vertical"
                            selectable
                            selectedKeys={[selectedConversation?.id?.toString() ?? '']}
                            onSelect={({ key }) =>
                                setSelectedConversation(
                                    conversationsData?.conversations?.find((c) => c.id.toString() === key),
                                )
                            }
                        >
                            {sortBy(
                                conversationsData?.conversations,
                                (conversation: Conversation) =>
                                    -(!conversation.seen_by_admin ? 10 : 1) * moment(conversation.updated_at).valueOf(),
                            )?.map((conversation) => (
                                <Menu.Item className="message-item-admin" key={conversation.id}>
                                    <Space direction="vertical" size="small">
                                        <Badge dot count={conversation.seen_by_admin ? 0 : 1}>
                                            <Typography.Text style={{ paddingRight: 10 }} strong>
                                                {conversation.recipients?.name}
                                            </Typography.Text>
                                        </Badge>
                                        <Typography.Text type="secondary">
                                            {conversation.recipients?.email}
                                        </Typography.Text>
                                    </Space>
                                </Menu.Item>
                            ))}
                        </Menu>
                    </Col>

                    <Col span={18} style={{ maxHeight: '79vh', display: 'flex', flexDirection: 'column' }}>
                        {isLoadingMessages && <Spin size="large" spinning />}
                        {!selectedConversation && <Alert message="Select a conversation to get started." />}

                        {!!selectedConversation && (
                            <>
                                <List
                                    locale={{ emptyText: <Empty description="No messages yet" /> }}
                                    className="message-list"
                                    style={{ flex: 1, overflow: 'auto' }}
                                    dataSource={parseMessages(messagesData?.messages)}
                                    itemLayout="horizontal"
                                    renderItem={(props) => (
                                        <Card
                                            className="message-item"
                                            size="small"
                                            style={{
                                                width: '60%',
                                                flex: 1,
                                                // eslint-disable-next-line react/prop-types
                                                alignSelf: props?.isMe ? 'flex-end' : 'flex-start',
                                            }}
                                        >
                                            <Comment {...props} />
                                        </Card>
                                    )}
                                    footer={<div ref={bottomRef} />}
                                />

                                <Comment
                                    avatar={<Avatar style={{ background: '#6495ED' }}>{getInitials('Support')}</Avatar>}
                                    content={
                                        <Form form={form} onFinish={mutation.mutate}>
                                            <Row gutter={12}>
                                                <Col style={{ flex: 1 }}>
                                                    <Form.Item
                                                        name="message"
                                                        rules={[
                                                            { required: true, message: 'Please enter the message!' },
                                                        ]}
                                                    >
                                                        <Input.TextArea
                                                            style={{ marginBottom: '0' }}
                                                            rows={2}
                                                            placeholder="Type your message here..."
                                                        />
                                                    </Form.Item>
                                                </Col>

                                                <Col>
                                                    <Form.Item>
                                                        <Button
                                                            htmlType="submit"
                                                            loading={mutation.isLoading}
                                                            type="primary"
                                                        >
                                                            Send
                                                        </Button>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Form>
                                    }
                                />
                            </>
                        )}
                    </Col>
                </Row>
            )}

            <Modal
                visible={showNewConversationModal}
                onCancel={() => setShowNewConversationModal(false)}
                footer={null}
                title="Start a conversation"
            >
                {newMessageForm}
            </Modal>
        </AdminLayout>
    );
};
