import React, { PropsWithChildren } from 'react';
import { Layout, PageHeader } from 'antd';
import { GuestNav } from '@components/nav/guest';
import { Logo } from '@components/logo';
import { SiteFooter } from '@components/footer';

const { Content } = Layout;

export const SupportLayout: React.FC = ({ children }: PropsWithChildren<unknown>) => {
    return (
        <Layout className="layout guest">
            <GuestNav />
            <Content>
                <PageHeader>
                    <Logo noLink dark className="page-logo" />
                    <div className="site-layout-content">{children}</div>
                </PageHeader>
                <SiteFooter />
            </Content>
        </Layout>
    );
};
