/* eslint-disable indent */
import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useMutation, useQuery } from 'react-query';
import { AdminLayout } from '@components/layouts/admin';
import { Card, Col, notification, PageHeader, Row, Spin, Form, InputNumber, Button } from 'antd';
import SettingsApi, { Settings, UpdateSettingsRequest } from '@services/settings';
import { AxiosError } from 'axios';
import { useForm } from 'antd/lib/form/Form';
import { ApiError } from '@components/api-error';
import { deepSelect } from '@util/react-query';

export const AdminSettingsPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);
    const [form] = useForm();

    const { isLoading, data } = useQuery(['admin', 'settings'], SettingsApi.get, {
        staleTime: 500,
        select: deepSelect,
    });

    const engineThreshold = data?.engineThreshold as number;

    const mutation = useMutation<void, AxiosError, UpdateSettingsRequest>(async (data) => {
        try {
            data.engineThreshold = 1 - (data.engineThreshold || 95) / 100;
            await SettingsApi.update(data);
            notification.success({
                message: 'Settings updated!',
            });
        } catch (error) {
            notification.error({ message: 'Error updating settings' });
        }
    });

    function parseSettings(data?: number): Settings | undefined {
        if (!data) {
            return;
        }

        const engineThreshold = parseFloat(Number((1 - data) * 100).toFixed(2));
        return { engineThreshold };
    }

    return (
        <AdminLayout>
            <Helmet>
                <title>Admin Settings | Copyknight Admin</title>
            </Helmet>

            <PageHeader title="Admin Settings">
                <Row justify="center">
                    <Col span={24} md={12} lg={12} xl={12}>
                        <Card>
                            {isLoading ? (
                                <Spin size="large" spinning={true} />
                            ) : (
                                <Form
                                    layout="vertical"
                                    name="update-settings"
                                    initialValues={parseSettings(engineThreshold)}
                                    form={form}
                                    onFinish={mutation.mutate}
                                >
                                    <Form.Item
                                        label="Engine Threshold"
                                        name="engineThreshold"
                                        rules={[{ required: true, message: 'Please enter the engine threshold!' }]}
                                        extra="Please enter the threshold as a similarity percentage. For example, entering 95% means that we require 95% similarity result in order to consider an engine match as valid."
                                    >
                                        <InputNumber
                                            min={0}
                                            max={100}
                                            formatter={(value) => `${value}%`}
                                            parser={(value) => (value || '0').replace('%', '')}
                                        />
                                    </Form.Item>

                                    {mutation.isError && (
                                        <Form.Item>
                                            <ApiError error={mutation.error} />
                                        </Form.Item>
                                    )}

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={mutation.isLoading}
                                            style={{ width: '100%' }}
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </Card>
                    </Col>
                </Row>
            </PageHeader>
        </AdminLayout>
    );
};
