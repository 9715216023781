import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useQuery } from 'react-query';
import { Button, Card, Descriptions, PageHeader, Tag } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { AdminLayout } from '@components/layouts/admin';
import WebhooksApi from '@services/webhooks';
import moment from 'moment';
import { DATE } from '@config/date';

import OwnerRoles from '@pages/admin/webhooks/details/owner-roles';
import ErrorMessages from '@pages/admin/webhooks/details/error-messages';
import Matches from '@pages/admin/webhooks/details/matches.jsx';
import { deepSelect } from '@util/react-query';

interface Params {
    webhookId: string;
}

export const AdminWebhookDetailsPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);

    const { webhookId } = useParams<Params>();

    const { data: record, isLoading } = useQuery(
        ['admin-webhook-details', webhookId],
        () => WebhooksApi.getWebhook(webhookId),
        {
            keepPreviousData: true,
            select: deepSelect,
        },
    );

    const webhook = record?.entity;
    const site = webhook?.site;
    const errors = webhook?.messages;
    const reversedErrorsArray = errors?.sort((a, b) => Number(b.id) - Number(a.id));
    const additionalInfoWebsite = webhook?.additionalInfo?.website;
    const additionalInfoMatches = webhook?.additionalInfo.matches;

    return (
        <AdminLayout>
            <Helmet>
                <title>{`${webhookId} | Copyknight Admin`}</title>
            </Helmet>
            <PageHeader
                title={
                    <>
                        <Link to={ROUTES.ADMIN.WEBHOOKS}>
                            <Button type="link" icon={<ArrowLeftOutlined />} style={{ padding: 0 }}>
                                Webhooks
                            </Button>
                        </Link>
                    </>
                }
            >
                <Card loading={isLoading} bodyStyle={{ marginBottom: '30px', marginTop: '10px' }}>
                    <Descriptions
                        title="General Info"
                        bordered
                        column={{
                            xxl: 1,
                            xl: 1,
                            lg: 1,
                            md: 1,
                            sm: 1,
                            xs: 1,
                        }}
                    >
                        <Descriptions.Item label="Call status">
                            {webhook?.call_status === 'SUCCESSFUL' ? (
                                <Tag color="success">{webhook?.call_status}</Tag>
                            ) : (
                                <Tag color="warning">{webhook?.call_status}</Tag>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Signature">{webhook?.signature}</Descriptions.Item>

                        <Descriptions.Item label="Created at">
                            {moment(webhook?.created_at).format(DATE.dateTimeWithSecondsFormat)}
                        </Descriptions.Item>
                        <Descriptions.Item label="Last tried">
                            {moment(webhook?.updated_at ? webhook?.updated_at : '').format(
                                DATE.dateTimeWithSecondsFormat,
                            )}
                        </Descriptions.Item>

                        <Descriptions.Item label="Website">{webhook?.site.name}</Descriptions.Item>
                        <Descriptions.Item label="Attempts">{webhook?.attempt}</Descriptions.Item>
                    </Descriptions>
                </Card>
                <Card loading={isLoading}>
                    <Descriptions
                        title="Website Info"
                        bordered
                        column={{
                            xxl: 1,
                            xl: 1,
                            lg: 1,
                            md: 1,
                            sm: 1,
                            xs: 1,
                        }}
                    >
                        <Descriptions.Item label="Base URL">
                            <a href={site?.base_url} rel="noreferrer" target="_blank">
                                {site?.base_url}
                            </a>
                        </Descriptions.Item>

                        <Descriptions.Item label="Website name">{site?.name}</Descriptions.Item>
                        <Descriptions.Item label="Webhook URL">{site?.webhook_url}</Descriptions.Item>

                        <Descriptions.Item label="Website Owner">{site?.owner?.name}</Descriptions.Item>
                        <Descriptions.Item label="Website Owner's email">{site?.owner?.email}</Descriptions.Item>
                        {additionalInfoWebsite?.owner?.roles && (
                            <Descriptions.Item label="Website owner roles">
                                <OwnerRoles roles={additionalInfoWebsite?.owner?.roles} />
                            </Descriptions.Item>
                        )}
                        <Descriptions.Item label="Created at">
                            {moment(site?.created_at).format(DATE.dateTimeWithSecondsFormat)}
                        </Descriptions.Item>
                    </Descriptions>
                </Card>

                {reversedErrorsArray?.length !== 0 && (
                    <Card loading={isLoading}>
                        <Descriptions
                            title="Error Messages"
                            bordered
                            column={{
                                xxl: 1,
                                xl: 1,
                                lg: 1,
                                md: 1,
                                sm: 1,
                                xs: 1,
                            }}
                        >
                            <Descriptions.Item>
                                <ErrorMessages errors={reversedErrorsArray} />
                            </Descriptions.Item>
                        </Descriptions>
                    </Card>
                )}
                {additionalInfoMatches && (
                    <Card loading={isLoading}>
                        <p style={{ fontSize: '15px', fontWeight: 'bold' }}>Matches Info</p>
                        <div style={{ padding: '0' }}>
                            <Matches className="col-6" matches={additionalInfoMatches} />
                        </div>
                    </Card>
                )}
            </PageHeader>
        </AdminLayout>
    );
};
