import AdminUsersApi from '@services/admin-users';
import { notification } from 'antd';
import { useMutation, UseMutationResult } from 'react-query';

const useLoginAs = (): UseMutationResult<void, unknown, string, unknown> => {
    return useMutation<void, unknown, string>(async (userId) => {
        try {
            const loginData = await AdminUsersApi.loginAsUser(userId);
            window.open(
                `${window.location.origin}/dashboard?loginAs=${userId}&data=${JSON.stringify(loginData)}`,
                '_blank',
            );
        } catch (error) {
            notification.error({
                message: 'Error logging as user',
            });
        }
    });
};

export default useLoginAs;
