import { Pagination, ServerPagination } from '@interfaces/pagination';
import http from './http';

export interface Notification {
    id: number;
    type: string;
    notifiable_type: string;
    notifiable_id: number;
    data: Record<string, string> & Record<string, string[]>;
    read_at: string | null;
    created_at: string;
    updated_at: string;
}

export type NotificationsIndexResponse = ServerPagination<Notification[]>;

export type NotificationStatsResponse = {
    data: {
        has_unread: boolean;
    };
};

export default class NotificationsApi {
    public static getNotifications(pagination: Pagination): Promise<NotificationsIndexResponse> {
        return http.get('/notifications', {
            params: {
                page: pagination.current,
            },
        });
    }

    public static getNotificationsStats(): Promise<NotificationStatsResponse> {
        return http.get('/notifications/stats');
    }

    public static markAsRead(): Promise<void> {
        return http.post('/notifications/read');
    }
}
