import { ROLES } from '@config/roles';
import { ROUTES } from '@config/routes';
import { LoginResponse } from '@services/account';
import { useAuthUser } from 'react-auth-kit';
import { useHistory } from 'react-router-dom';
import { useDefaultRoute } from './default-route';

export const useProtectedRoute = (role: string): void => {
    const { push } = useHistory();
    const auth = useAuthUser();
    const user = auth() as LoginResponse;
    const defaultRoute = useDefaultRoute();

    const isAdmin = user?.is_admin || user?.is_mod;
    const isPublisher = user?.is_publisher;

    if (isAdmin && role !== ROLES.ADMIN) {
        push(defaultRoute);
    } else if (isPublisher && role !== ROLES.PUBLISHER) {
        push(defaultRoute);
    } else if (!isAdmin && !isPublisher && role !== ROLES.USER) {
        push(defaultRoute);
    } else if (!user) {
        push(ROUTES.LOGIN);
    }
};
