import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
// import { Helmet } from 'react-helmet-async';
import { Helmet, HelmetProvider } from 'react-helmet-async';

import { Routes } from './routes';

import './App.less';
import { HttpProvider } from '@context/HttpContext';
import { MessagesProvider } from '@context/MessagesContext';
import { AppAuthProvider } from '@context/AppAuthContext';
import { LoginAsProvider } from '@context/LoginAsContext';
import { NotificationsProvider } from '@context/NotificationsContext';
import { ReactQueryDevtools } from 'react-query/devtools';

const queryClient = new QueryClient();

function App(): JSX.Element {
    return (
        <HelmetProvider>
            <AppAuthProvider>
                <HttpProvider>
                    <LoginAsProvider>
                        <QueryClientProvider client={queryClient}>
                            <MessagesProvider>
                                <NotificationsProvider>
                                    <Helmet>
                                        <meta charSet="utf-8" />
                                        <title>Copyknight</title>
                                    </Helmet>
                                    <Routes />
                                    <ReactQueryDevtools />
                                </NotificationsProvider>
                            </MessagesProvider>
                        </QueryClientProvider>
                    </LoginAsProvider>
                </HttpProvider>
            </AppAuthProvider>
        </HelmetProvider>
    );
}

export default App;
