import http from './http';

export interface Settings {
    engineThreshold: number;
    success?: boolean;
    data?: { engineThreshold: number };
}

export interface SettingsResponse {
    data: Settings;
}

export type UpdateSettingsRequest = Partial<Settings>;

export default class SettingsApi {
    public static async get(): Promise<SettingsResponse> {
        const result = await http.get('/admin/settings');
        return result;
    }

    public static update(data: UpdateSettingsRequest): Promise<void> {
        return http.put('/admin/settings', data);
    }
}
