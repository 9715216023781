import { Nullable } from '@util/nullable';
import hat from 'hat';
import { Pagination, ServerPagination } from 'interfaces/pagination';
import { User } from './admin-users';
import { CopyrightEntryFile } from './copyright-entries';
import http from './http';

export interface WebsiteEngine {
    id: number;
    website_id: number;
    tcp_address: string;
    tcp_port: string;
    created_at: string;
    updated_at: string;
}

export interface Website {
    id: number;
    owner_id: number;
    name: string;
    base_url: string;
    comment: string;
    approved: boolean;
    disapproved: boolean;
    disaproval_reason: string | null;
    api_key: string | null;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    remote_content_id: string | null;

    owner?: User;
    website_engine?: Nullable<WebsiteEngine>;
    data?: Website;
}

export interface WebsiteFile {
    id: number;
    website_id: number;
    website?: Website;
    remote_url: string;
    remote_content_id: string;
    name: string;
    ext: string;
    type: 'image' | 'video';
    url: string;
    thumbnail_url: string;
    path: string;
    thumbnail_path: string;
    imagine_engine_id: Nullable<string>;
    status: string;
    created_at: string;
    updated_at: string;
}

export type WebsiteListResponse = ServerPagination<Website[]>;

export interface VerificationMatch {
    id: number;
    website_owner_id: number;
    website_file_id: number;
    copyright_entry_file_id: number;
    website_file?: WebsiteFile;
    copyright_entry_file?: CopyrightEntryFile;
    created_at: string;
    updated_at: string;
}

export type VerificationMatchesResponse = ServerPagination<VerificationMatch[]>;

export type WebsiteDetailResponse = {
    data: Website;
};
export interface ChangeWebsiteStatusRequest {
    approved: boolean;
    disapproved: boolean;
    disaproval_reason: string;
}
export interface CreateWebsiteRequest {
    approved: boolean;
    disapproved: boolean;
    disaproval_reason: string;
}

export interface WebsiteVerificationStatusResponse {
    running: boolean;
}

export default class WebsiteApi {
    public static getWebsitesAdmin(pagination: Pagination): Promise<WebsiteListResponse> {
        return http.get('/admin/website/list', {
            params: {
                page: pagination.current,
            },
        });
    }

    public static getPendingWebsitesAdmin(pagination: Pagination): Promise<WebsiteListResponse> {
        return http.get('/admin/website/pending', {
            params: {
                page: pagination.current,
            },
        });
    }

    public static changeStatus(websiteId: string, data: ChangeWebsiteStatusRequest): Promise<void> {
        return http.put(`/website/${websiteId}`, data);
    }

    public static resetApiKey(websiteId: string): Promise<void> {
        const api_key = hat();
        return http.put(`/website/${websiteId}`, { api_key });
    }

    public static getWebsites(pagination: Pagination, forUser?: string): Promise<WebsiteListResponse> {
        return http.get('/website', {
            params: {
                page: pagination.current,
                forUser,
            },
        });
    }

    public static getWebsite(websiteId: string): Promise<WebsiteDetailResponse> {
        return http.get(`/website/${websiteId}`);
    }

    public static create(data: CreateWebsiteRequest): Promise<void> {
        const api_key = hat();
        return http.post(`/website`, { ...data, api_key });
    }

    public static delete(websiteId: string): Promise<void> {
        return http.delete(`/website/${websiteId}`);
    }

    public static requestVerification(websiteId: string): Promise<void> {
        return http.post(`/website/${websiteId}/request`);
    }

    public static startEngine(websiteId: string): Promise<void> {
        return http.post(`/admin/website/${websiteId}/engine`);
    }

    public static stopEngine(websiteId: string): Promise<void> {
        return http.delete(`/admin/website/${websiteId}/engine`);
    }

    public static submitForReapproval(websiteId: string): Promise<void> {
        return http.post(`/website/${websiteId}/reapproval`);
    }

    public static getVerificationStatus(websiteId: string): Promise<WebsiteVerificationStatusResponse> {
        return http.get(`/website/${websiteId}/verification-status`);
    }

    public static getWebsiteVerificationMatches(pagination: Pagination): Promise<VerificationMatchesResponse> {
        return http.get(`/verification-matches`, {
            params: {
                page: pagination.current,
            },
        });
    }
}
