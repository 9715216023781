import React, { PropsWithChildren } from 'react';
import { Layout } from 'antd';
import { UserNav } from '@components/nav/user';
import { SiteFooter } from '@components/footer';

const { Content } = Layout;

export const UserLayout: React.FC = ({ children }: PropsWithChildren<unknown>) => {
    return (
        <Layout className="layout">
            <UserNav />

            <Content>
                <div className="site-layout-content">
                    <div style={{ flex: 1 }}>{children}</div>
                    <SiteFooter />
                </div>
            </Content>
        </Layout>
    );
};
