import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { PublisherLayout } from '@components/layouts/publisher';
import { NotificationList } from '@components/notification-list';

export const PublisherNotificationsPage: React.FC = () => {
    useProtectedRoute(ROLES.PUBLISHER);

    return (
        <PublisherLayout>
            <Helmet>
                <title>Notifications | Copyknight</title>
            </Helmet>

            <NotificationList />
        </PublisherLayout>
    );
};
