import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useMutation, useQuery } from 'react-query';
import WebsiteApi, { Website } from '@services/websites';
import { Button, notification, PageHeader, Space, Table, TablePaginationConfig, Tooltip, Typography } from 'antd';
import { DATE } from '@config/date';
import { PAGINATION } from '@config/pagination';
import { Pagination } from 'interfaces/pagination';
import { Link } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { PublisherLayout } from '@components/layouts/publisher';
import { getWebsiteStatus } from '@util/website';
import { ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import { AxiosError } from 'axios';
import { paginationSelect } from '@util/react-query';

export const PublisherWebsitesIndexPage: React.FC = () => {
    useProtectedRoute(ROLES.PUBLISHER);

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data, isLoading, refetch } = useQuery(
        ['my-websites', pagination],
        () => WebsiteApi.getWebsites(pagination),
        { keepPreviousData: true, select: paginationSelect },
    );

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: data?.total,
            pageSize: data?.per_page,
            current: data?.current_page,
        });
    }, [data]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filters: Record<string, (string | number | boolean)[] | null>,
    ) => {
        setPagination(pagination);
    };

    const submitWebsiteForReapproval = useMutation<void, AxiosError, Website>(async (website) => {
        try {
            if (!website) {
                return;
            }
            await WebsiteApi.submitForReapproval(String(website.id));
            await refetch();

            notification.success({
                message: 'Website submitted for reapproval!',
            });
        } catch (error) {
            const errorMessage = error?.response?.data?.message;

            notification.error({
                message: errorMessage ?? 'Error submitting website for reapproval',
            });
        }
    });

    const onReapproval = (website: Website) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to submit this website for reapproval?',
            okText: 'Submit',
            okType: 'danger',
            onOk() {
                submitWebsiteForReapproval.mutate(website);
            },
        });
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: function NameColumn(name: string, website: Website) {
                return (
                    <Space>
                        <Link to={ROUTES.PUBLISHER.WEBSITES.EDIT(website)}>{name}</Link>
                        <Typography.Text ellipsis>
                            (
                            <a href={website.base_url} target="_blank" rel="noreferrer noopener">
                                {website.base_url}
                            </a>
                            )
                        </Typography.Text>
                    </Space>
                );
            },
        },
        {
            title: 'Date Submitted',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date: string) => moment(date).format(DATE.dateFormat),
        },
        {
            title: 'Status',
            render: function WebsiteStatus(website: Website) {
                return (
                    <>
                        {getWebsiteStatus(website)}
                        {website.disapproved && (
                            <Tooltip title="Submit for Reapproval">
                                <Button onClick={() => onReapproval(website)} icon={<ReloadOutlined />} />
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
        {
            title: 'Denial Reason',
            dataIndex: 'disaproval_reason',
            key: 'disaproval_reason',
            width: 320,
            render: function DenialReasonColumn(denialReason: string, website: Website) {
                return <>{website.disapproved && <Space>{website.disaproval_reason}</Space>}</>;
            },
        },
        // {
        //     title: 'Actions',
        //     render: function ActionsColumn(copyright: Copyright) {
        //         const menu = (
        //             <Menu>
        //                 <Menu.Item key="1" icon={<UnorderedListOutlined />}>
        //                     <Link to={ROUTES.USER.COPYRIGHT_ENTRIES(copyright)?.INDEX}>Copyright Entries</Link>
        //                 </Menu.Item>
        //                 <Menu.Item key="2" icon={<AppstoreOutlined />}>
        //                     <Link to={ROUTES.USER.COPYRIGHT_GROUPS(copyright)?.INDEX}>Copyright Groups</Link>
        //                 </Menu.Item>
        //             </Menu>
        //         );
        //         return <Dropdown.Button overlay={menu} />;
        //     },
        // },
    ];

    return (
        <PublisherLayout>
            <Helmet>
                <title>My Websites | Copyknight</title>
            </Helmet>

            <PageHeader
                title="Websites"
                extra={[
                    <Link key="1" to={ROUTES.PUBLISHER.WEBSITES.CREATE}>
                        <Button type="primary">Create</Button>
                    </Link>,
                ]}
            >
                <Table
                    locale={{ emptyText: 'No Websites yet' }}
                    bordered
                    columns={columns}
                    pagination={pagination}
                    rowKey="id11"
                    dataSource={data?.data}
                    loading={isLoading}
                    onChange={handleTableChange}
                />
            </PageHeader>
        </PublisherLayout>
    );
};
