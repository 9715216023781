import React from 'react';
import { Tag } from 'antd';
import { CopyrightEntry } from '@services/copyright-entries';

export function getEntryStatus(entry?: CopyrightEntry): JSX.Element {
    if (!entry) {
        return <Tag>N/a</Tag>;
    }

    if (entry.approved) {
        return <Tag color="green">Accepted</Tag>;
    }

    if (entry.approval_denied) {
        return <Tag color="red">Denied</Tag>;
    }

    return <Tag color="yellow">Pending</Tag>;
}
