import React from 'react';
import { Card, Descriptions, Tag, Image } from 'antd';

interface CopyrightEntryFile {
    id: string;
    name: string;
    path: string;
    status: string;
    thumbnail_path: string;
    type: string;
}

interface Match {
    copyright_entry_file: CopyrightEntryFile;
}

interface AllMatches {
    matches?: Match[];
}

export default function Matches({ matches }: AllMatches): JSX.Element {
    const matchesArray = matches.map((match) => (
        <div style={{ display: 'flex' }} key={match?.copyright_entry_file?.id}>
            <Card style={{ width: '48%' }}>
                <Descriptions
                    bordered
                    column={{
                        xxl: 1,
                        xl: 1,
                        lg: 1,
                        md: 1,
                        sm: 1,
                        xs: 1,
                    }}
                >
                    <Descriptions.Item label="Name">{match?.copyright_entry_file?.name}</Descriptions.Item>
                    <Descriptions.Item label="Type">{match?.copyright_entry_file?.type}</Descriptions.Item>
                    <Descriptions.Item label="Path">
                        <a href={match?.copyright_entry_file?.path} target="_blank" rel="noopener noreferrer">
                            {match?.copyright_entry_file?.type === 'image'
                                ? 'Click to see image'
                                : 'Click to see video'}
                        </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Thumbnail">
                        <a href={match?.copyright_entry_file?.thumbnail_path} target="_blank" rel="noopener noreferrer">
                            <Image width={100} src={match?.website_file?.thumbnail_path} />
                        </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Status">
                        <Tag color={match?.copyright_entry_file?.status === 'DELETED' ? 'warning' : 'success'}>
                            {match?.copyright_entry_file?.status}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Copyright Entry Title">
                        {match?.copyright_entry_file?.copyright_entry?.title}
                        <Tag
                            style={{ marginLeft: '15px' }}
                            color={match?.copyright_entry_file?.status === 'DELETED' ? 'warning' : 'success'}
                        >
                            {match?.copyright_entry_file?.status}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Copyright Entry URL">
                        <a href={match?.copyright_entry_file?.copyright_entry?.url}>
                            {match?.copyright_entry_file?.copyright_entry?.url}
                        </a>
                    </Descriptions.Item>
                    <Descriptions.Item label="Copyright Name">
                        {match?.copyright_entry_file?.copyright_entry?.copyright?.name}
                        <Tag
                            style={{ marginLeft: '15px' }}
                            color={match?.copyright_entry_file?.status === 'DELETED' ? 'warning' : 'success'}
                        >
                            {match?.copyright_entry_file?.status}
                        </Tag>
                    </Descriptions.Item>
                    <Descriptions.Item label="Copyright Owner Name">
                        {match?.copyright_entry_file?.copyright_entry?.copyright?.owner?.name}
                    </Descriptions.Item>
                    <Descriptions.Item label="Copyright Group Name">
                        {match?.copyright_entry_file?.copyright_entry?.copyright_group?.name}
                    </Descriptions.Item>
                </Descriptions>
            </Card>
            <Card style={{ width: '48%' }}>
                {match?.website_file?.status === 'DELETED' ? (
                    <Descriptions
                        bordered
                        column={{
                            xxl: 1,
                            xl: 1,
                            lg: 1,
                            md: 1,
                            sm: 1,
                            xs: 1,
                        }}
                    >
                        <Descriptions.Item label="Status">
                            <Tag color="warning">{match?.website_file?.status}</Tag>
                        </Descriptions.Item>
                        <Descriptions.Item label="Message">{match?.website_file?.message}</Descriptions.Item>
                    </Descriptions>
                ) : (
                    <Descriptions
                        bordered
                        column={{
                            xxl: 1,
                            xl: 1,
                            lg: 1,
                            md: 1,
                            sm: 1,
                            xs: 1,
                        }}
                    >
                        <Descriptions.Item label="Name">{match?.website_file?.name}</Descriptions.Item>
                        <Descriptions.Item label="Remote Content ID">
                            {match?.website_file?.remote_content_id}
                        </Descriptions.Item>
                        <Descriptions.Item label="Type">{match?.website_file?.type}</Descriptions.Item>
                        <Descriptions.Item label="Remote URL">
                            <a href={match?.website_file?.remote_url} target="_blank" rel="noopener noreferrer">
                                {match?.website_file?.remote_url.length > 90
                                    ? 'Click to visit page'
                                    : match?.website_file?.remote_url}
                            </a>
                        </Descriptions.Item>
                        <Descriptions.Item label="Path">
                            <a href={match?.website_file?.path} target="_blank" rel="noopener noreferrer">
                                {match?.website_file?.type === 'image' ? 'Click to see image' : 'Click to see video'}
                            </a>
                        </Descriptions.Item>
                        <Descriptions.Item label="Thumbnail">
                            {match?.website_file?.thumbnail_path !== '' && (
                                <a href={match?.website_file?.thumbnail_path} target="_blank" rel="noopener noreferrer">
                                    <Image width={100} src={match?.website_file?.thumbnail_path} />
                                </a>
                            )}
                        </Descriptions.Item>
                        <Descriptions.Item label="Status">
                            <Tag color="success">{match?.website_file?.status}</Tag>
                        </Descriptions.Item>
                    </Descriptions>
                )}
            </Card>
        </div>
    ));

    return <>{matchesArray}</>;
}
