import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useMutation, useQuery } from 'react-query';
import CopyrightsApi, { Copyright } from '@services/copyrights';
import { Button, Card, notification, Space, Table, TablePaginationConfig, Tag } from 'antd';
import { DATE } from '@config/date';
import { PAGINATION } from '@config/pagination';
import { Pagination } from 'interfaces/pagination';
import { User } from '@services/admin-users';
import { getCopyrightStatus } from '@util/copyright';
import { AxiosError } from 'axios';
import confirm from 'antd/lib/modal/confirm';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { useAuthUser } from 'react-auth-kit';
import { paginationSelect } from '@util/react-query';

interface Props {
    user?: User;
}

export const UserCopyrightList: React.FC<Props> = ({ user }: Props) => {
    const auth = useAuthUser();
    const userAuth = auth() as { is_admin: boolean; is_mod: boolean };

    const loggedInAsAdmin = userAuth.is_admin;

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data, isLoading, refetch } = useQuery(
        ['admin-user-copyrights', pagination],
        () => CopyrightsApi.getCopyrights(pagination, String(user?.id)),
        {
            keepPreviousData: true,
            enabled: !!user?.id,
            select: paginationSelect,
        },
    );

    const markCopyrightAsTrusted = useMutation<void, void, { copyright: Copyright; trusted: boolean }>(
        async ({ copyright, trusted }) => {
            try {
                await CopyrightsApi.edit(String(copyright?.id), { trusted });
                await refetch();
            } catch (error) {
                notification.error({
                    message: 'Error updating Copyright',
                });
            }
        },
    );

    const deleteCopyright = useMutation<void, AxiosError, Copyright>(async (copyright) => {
        try {
            if (!copyright) {
                return;
            }
            await CopyrightsApi.delete(String(copyright.id));
            await refetch();

            notification.success({
                message: 'Copyright deleted!',
            });
        } catch (error) {
            const errorMessage = error?.response?.data?.message;

            notification.error({
                message: errorMessage ?? 'Error deleting copyright',
            });
        }
    });

    const onDelete = (copyright: Copyright) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete this copyright?',
            okText: 'Delete',
            okType: 'danger',
            onOk() {
                deleteCopyright.mutate(copyright);
            },
        });
    };

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: data?.total,
            pageSize: data?.per_page,
            current: data?.current_page,
        });
    }, [data]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filters: Record<string, (string | number | boolean)[] | null>,
    ) => {
        setPagination(pagination);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: function NameColumn(name: string, copyright: Copyright) {
                return (
                    <Space>
                        {name}
                        {copyright.being_deleted && <Tag color="red">Being deleted</Tag>}
                    </Space>
                );
            },
        },
        {
            title: 'Date Submitted',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date: string) => moment(date).format(DATE.dateFormat),
        },
        {
            title: 'Status',
            render: (copyright: Copyright) => getCopyrightStatus(copyright),
        },
        {
            title: 'Denial Reason',
            dataIndex: 'approval_denied_reason',
            key: 'approval_denied_reason',
            width: 320,
            render: function DenialReasonColumn(denialReason: string, copyright: Copyright) {
                return <>{copyright.approval_denied && <Space>{denialReason}</Space>}</>;
            },
        },
        {
            title: 'Actions',
            render: function ActionsColumn(copyright: Copyright) {
                return (
                    <Space>
                        {loggedInAsAdmin && (
                            <>
                                <Button
                                    onClick={() =>
                                        markCopyrightAsTrusted.mutate({ copyright, trusted: !copyright.trusted })
                                    }
                                >
                                    {copyright.trusted ? 'Make Not Trusted' : 'Make Trusted'}
                                </Button>
                                <Button danger onClick={() => onDelete(copyright)}>
                                    <DeleteOutlined />
                                </Button>
                            </>
                        )}
                    </Space>
                );
            },
        },
    ];

    if (!user) {
        return null;
    }

    return (
        <Card title="Copyrights">
            <Table
                locale={{ emptyText: 'No Copyrights yet' }}
                bordered
                columns={columns}
                pagination={pagination}
                rowKey="id2"
                dataSource={data?.data}
                loading={isLoading}
                onChange={handleTableChange}
            />
        </Card>
    );
};
