import React, { PropsWithChildren } from 'react';
import { Layout, PageHeader } from 'antd';
import { SiteFooter } from '@components/footer';
import { Logo } from '@components/logo';

const { Content } = Layout;

export const GuestLayout: React.FC = ({ children }: PropsWithChildren<unknown>) => {
    return (
        <Layout className="layout guest">
            <Content>
                <PageHeader>
                    <Logo dark noLink className="page-logo" />
                    <div className="site-layout-content">{children}</div>
                </PageHeader>
                <SiteFooter />
            </Content>
        </Layout>
    );
};
