import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { UserLayout } from '@components/layouts/user';
import { useMutation, useQuery } from 'react-query';
import CopyrightsApi, { Copyright } from '@services/copyrights';
import { Button, notification, PageHeader, Space, Table, TablePaginationConfig, Tag, Tooltip } from 'antd';
import { DATE } from '@config/date';
import { PAGINATION } from '@config/pagination';
import { Pagination } from 'interfaces/pagination';
import { Link } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { DeleteOutlined, ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { getCopyrightStatus } from '@util/copyright';
import confirm from 'antd/lib/modal/confirm';
import { AxiosError } from 'axios';
import { paginationSelect } from '@util/react-query';

export const UserCopyrightIndexPage: React.FC = () => {
    useProtectedRoute(ROLES.USER);

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data, isLoading, refetch } = useQuery(
        ['my-copyrights', pagination],
        () => CopyrightsApi.getCopyrights(pagination),
        {
            keepPreviousData: true,
            select: paginationSelect,
        },
    );

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: data?.total,
            pageSize: data?.per_page,
            current: data?.current_page,
        });
    }, [data]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filters: Record<string, (string | number | boolean)[] | null>,
    ) => {
        setPagination(pagination);
    };

    const deleteCopyright = useMutation<void, AxiosError, Copyright>(async (copyright) => {
        try {
            if (!copyright) {
                return;
            }
            await CopyrightsApi.delete(String(copyright.id));
            await refetch();

            notification.success({
                message: 'Copyright deleted!',
            });
        } catch (error) {
            const errorMessage = error?.response?.data?.message;

            notification.error({
                message: errorMessage ?? 'Error deleting copyright',
            });
        }
    });

    const submitCopyrightForReapproval = useMutation<void, AxiosError, Copyright>(async (copyright) => {
        try {
            if (!copyright) {
                return;
            }
            await CopyrightsApi.submitForReapproval(String(copyright.id));
            await refetch();

            notification.success({
                message: 'Copyright submitted for reapproval!',
            });
        } catch (error) {
            const errorMessage = error?.response?.data?.message;

            notification.error({
                message: errorMessage ?? 'Error submitting copyright for reapproval',
            });
        }
    });

    const onDelete = (copyright: Copyright) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete this copyright?',
            okText: 'Delete',
            okType: 'danger',
            onOk() {
                deleteCopyright.mutate(copyright);
            },
        });
    };

    const onReapproval = (copyright: Copyright) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to submit this copyright for reapproval?',
            okText: 'Submit',
            okType: 'danger',
            onOk() {
                submitCopyrightForReapproval.mutate(copyright);
            },
        });
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: function NameColumn(name: string, copyright: Copyright) {
                return (
                    <Space>
                        <Link to={ROUTES.USER.COPYRIGHT_ENTRIES(copyright)?.INDEX}>{name}</Link>
                        {copyright.being_deleted && <Tag color="red">Being deleted</Tag>}
                    </Space>
                );
            },
        },
        {
            title: 'Date Submitted',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date: string) => moment(date).format(DATE.dateFormat),
        },
        {
            title: 'Status',
            render: function StatusColumn(copyright: Copyright) {
                return (
                    <>
                        {getCopyrightStatus(copyright)}
                        {copyright.approval_denied && (
                            <Tooltip title="Submit for Reapproval">
                                <Button onClick={() => onReapproval(copyright)} icon={<ReloadOutlined />} />
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
        {
            title: 'Denial Reason',
            dataIndex: 'approval_denied_reason',
            key: 'approval_denied_reason',
            width: 320,
            render: function DenialReasonColumn(denialReason: string, copyright: Copyright) {
                return <>{copyright.approval_denied && <Space>{denialReason}</Space>}</>;
            },
        },
        {
            width: 30,
            title: '',
            render: function Actions(copyright: Copyright) {
                return (
                    <Space>
                        <Button danger onClick={() => onDelete(copyright)} icon={<DeleteOutlined />} />
                    </Space>
                );
            },
        },
    ];

    return (
        <UserLayout>
            <Helmet>
                <title>My Copyrights | Copyknight</title>
            </Helmet>

            <PageHeader
                title="Copyrights"
                extra={[
                    <Link key="1" to={ROUTES.USER.COPYRIGHTS.CREATE}>
                        <Button type="primary">Create</Button>
                    </Link>,
                ]}
            >
                <Table
                    locale={{ emptyText: 'No Copyrights yet' }}
                    bordered
                    columns={columns}
                    pagination={pagination}
                    rowKey="id15"
                    dataSource={data?.data}
                    loading={isLoading}
                    onChange={handleTableChange}
                />
            </PageHeader>
        </UserLayout>
    );
};
