import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useQuery } from 'react-query';
import AdminApi from '@services/admin';
import { AdminLayout } from '@components/layouts/admin';
import { Card, Col, Row, Statistic } from 'antd';
import { ROUTES } from '@config/routes';
import { Link } from 'react-router-dom';
import { deepSelect } from '@util/react-query';

export const AdminDashboardPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);

    const { isLoading, data } = useQuery(['admin', 'stats'], AdminApi.getStats, {
        select: deepSelect,
    });

    return (
        <AdminLayout>
            <Helmet>
                <title>Admin Dashboard | Copyknight Admin</title>
            </Helmet>

            <h1>Admin Dash</h1>
            <Row gutter={12}>
                <Col>
                    <Link to={ROUTES.ADMIN.COPYRIGHTS.PENDING}>
                        <Card>
                            <Statistic
                                loading={isLoading}
                                title="Total Pending Copyrights"
                                value={data?.totalPendingCopyrights}
                                precision={0}
                            />
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to={ROUTES.ADMIN.COPYRIGHT_ENTRIES.PENDING}>
                        <Card>
                            <Statistic
                                loading={isLoading}
                                title="Total Pending Copyright Entries"
                                value={data?.totalPendingCopyrightEntries}
                                precision={0}
                            />
                        </Card>
                    </Link>
                </Col>
                <Col>
                    <Link to={ROUTES.ADMIN.WEBSITES.PENDING}>
                        <Card>
                            <Statistic
                                loading={isLoading}
                                title="Total Pending Websites"
                                value={data?.totalPendingWebsites}
                                precision={0}
                            />
                        </Card>
                    </Link>
                </Col>
            </Row>
        </AdminLayout>
    );
};
