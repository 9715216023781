import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { PublisherLayout } from '@components/layouts/publisher';
import { ROUTES } from '@config/routes';
import { useHistory } from 'react-router';

export const PublisherDashboardPage: React.FC = () => {
    useProtectedRoute(ROLES.PUBLISHER);

    const { push } = useHistory();

    useEffect(() => {
        push(ROUTES.PUBLISHER.WEBSITES.INDEX);
    });

    return (
        <PublisherLayout>
            <Helmet>
                <title>Dashboard | Copyknight</title>
            </Helmet>

            <h1>Publisher Dash</h1>
        </PublisherLayout>
    );
};
