import React from 'react';
import { Layout, Menu, Button, Dropdown } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { useAuthUser, useSignOut } from 'react-auth-kit';
import { ROUTES } from '@config/routes';
import { isMobile } from 'react-device-detect';
import { MenuOutlined } from '@ant-design/icons';
import { Logo } from '@components/logo';
import { useQuery } from 'react-query';
import AccountApi from '@services/account';
import { deepSelect } from '@util/react-query';

// import { browserReload, TBrowserReload } from '@util/browser-reload.ts';

const { Header } = Layout;

export const AdminNav: React.FC = () => {
    const match = useRouteMatch();
    const signOut = useSignOut();
    const auth = useAuthUser();
    const user = auth() as { id: number };

    const { isLoading, data } = useQuery(['account', user?.id], AccountApi.me, {
        staleTime: 500,
        select: deepSelect,
    });

    const menu = (
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[match.path]}>
            {!isLoading && (
                <Menu.Item style={{ color: 'white', padding: '0 10px' }} key="email">
                    {data?.user?.email}
                </Menu.Item>
            )}
            <Menu.Item key={ROUTES.ADMIN.CHANGE_PASSWORD}>
                <Link to={ROUTES.ADMIN.CHANGE_PASSWORD}>Change password</Link>
            </Menu.Item>
            <Menu.Item key="logout" onClick={signOut}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Logo />
            {!isMobile && menu}

            {isMobile && (
                <Dropdown overlayClassName="main-navigation" overlay={menu} trigger={['click']} placement="bottomRight">
                    <Button type="default">
                        <MenuOutlined />
                    </Button>
                </Dropdown>
            )}
        </Header>
    );
};
