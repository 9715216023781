import React from 'react';
import logo from './images/logo-negative.png';
import logoDark from './images/logo.png';

interface Props {
    dark?: boolean;
    noLink?: boolean;
    className?: string;
}

export function Logo({ dark, noLink, className = 'logo' }: Props): JSX.Element {
    const Wrapper = ({ children }: React.PropsWithChildren<unknown>) =>
        noLink ? (
            <div className={className}>{children}</div>
        ) : (
            <a href="/" className={className}>
                {children}
            </a>
        );
    return (
        <Wrapper>
            <img src={dark ? logoDark : logo} />
        </Wrapper>
    );
}
