import React from 'react';
import { Website } from '@services/websites';
import { Tag } from 'antd';

export function getWebsiteStatus(website?: Website): JSX.Element {
    if (!website) {
        return <Tag>N/a</Tag>;
    }

    if (website.approved) {
        return <Tag color="green">Accepted</Tag>;
    }

    if (website.disapproved) {
        return <Tag color="red">Denied</Tag>;
    }

    return <Tag color="yellow">Pending</Tag>;
}
