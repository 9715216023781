import { File } from '@interfaces/file';
import { UploadFile } from 'antd/lib/upload/interface';
import { Pagination, ServerPagination } from 'interfaces/pagination';
import http from './http';

export interface CopyrightBanner {
    id: number;
    banner_slot: number;
    copyright_banner_collection_id: number;
    url: string;
    thumbnail_url: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export interface BannerCollection {
    id: number;
    name: string;
    copyright_id: number;
    copyright_banners?: CopyrightBanner[];
    created_at: string;
    updated_at: string;
}

export interface Banner extends File {
    id: number;
    banner_slot: number;
    copyright_banner_collection_id: number;
    url: string;
    thumbnail_url: string;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export type BannerSlotsResponse = ServerPagination<BannerCollection[]>;

export interface CreateBannerCollectionRequest {
    name: string;
}

export interface CreateBannerCollectionResponse {
    data: BannerCollection;
}

export interface CreateBannerRequest {
    banner_slot: number;
    file: UploadFile;
}

export interface GetBannerCollectionResponse {
    data: BannerCollection & {
        copyright_banners: Banner[];
    };
}

export default class BannerCollectionsApi {
    public static getAll(
        copyrightId: string,
        pagination: Pagination,
    ): Promise<BannerSlotsResponse & { data?: BannerSlotsResponse }> {
        return http.get(`/copyright/${copyrightId}/banner-collections`, {
            params: {
                page: pagination.current,
            },
        });
    }

    public static create(
        copyrightId: string,
        data: CreateBannerCollectionRequest,
    ): Promise<CreateBannerCollectionResponse> {
        return http.post(`/copyright/${copyrightId}/banner-collections`, data);
    }

    public static update(
        copyrightId: string,
        bannerCollectionId: string,
        data: CreateBannerCollectionRequest,
    ): Promise<CreateBannerCollectionResponse> {
        return http.put(`/copyright/${copyrightId}/banner-collections/${bannerCollectionId}`, data);
    }

    public static saveBanner(
        copyrightId: string,
        bannerCollectionId: string,
        data: CreateBannerRequest,
    ): Promise<void> {
        const formData = new FormData();
        formData.append('banner_slot', String(data.banner_slot));
        formData.append('file', data?.file?.originFileObj as Blob);
        return http.post(`/copyright/${copyrightId}/banner-collections/${bannerCollectionId}/banners`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }

    public static delete(copyrightId: string, bannerCollectionId: string): Promise<void> {
        return http.delete(`/copyright/${copyrightId}/banner-collections/${bannerCollectionId}`);
    }

    public static get(
        copyrightId: string,
        bannerCollectionId: string,
    ): Promise<GetBannerCollectionResponse & { data?: GetBannerCollectionResponse }> {
        return http.get(`/copyright/${copyrightId}/banner-collections/${bannerCollectionId}`);
    }
}
