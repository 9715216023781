import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useQuery } from 'react-query';
import { AdminLayout } from '@components/layouts/admin';
import { PageHeader, Table, TablePaginationConfig, Button, Space, Tag } from 'antd';
import VerificationMatchesApi, { VerificationMatch } from '@services/verification-matches';
import { Pagination } from '@interfaces/pagination';
import { PAGINATION } from '@config/pagination';
import moment from 'moment';
import { DATE } from '@config/date';
import http from '../../services/http';
import { paginationSelect } from '@util/react-query';

export const AdminVerificationMatchesPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { isLoading, data } = useQuery(
        ['admin', 'verification-matches', pagination],
        () => VerificationMatchesApi.get(pagination),
        { keepPreviousData: true, select: paginationSelect },
    );

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: data?.total,
            pageSize: data?.per_page,
            current: data?.current_page,
        });
    }, [data]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filters: Record<string, (string | number | boolean)[] | null>,
    ) => {
        setPagination(pagination);
    };

    function getCopyrightEntryFileImage(match: VerificationMatch) {
        if (match.copyright_entry_file?.type === 'image') {
            return match.copyright_entry_file.thumbnail_path;
        }

        if (match.copyright_entry_file?.type === 'video') {
            return match.copyright_entry_file_video_frame?.path;
        }
    }

    function getWebsiteFileImage(match: VerificationMatch) {
        if (match.website_file?.type === 'image') {
            return match.website_file?.thumbnail_path;
        }

        if (match.website_file?.type === 'video') {
            return match.website_video_frame?.path;
        }
    }

    const columns = [
        {
            title: 'Publisher',
            render: function PublisherNameColumn(verificationMatch: VerificationMatch) {
                return <Space>{verificationMatch.website_owner.name}</Space>;
            },
        },
        {
            title: 'Website',
            render: function WebsiteNameColumn(verificationMatch: VerificationMatch) {
                return <Space>{verificationMatch.website_file?.website?.name}</Space>;
            },
        },
        {
            title: 'Original Website File',
            width: '175px',
            render: function WebsiteFileColumn(verificationMatch: VerificationMatch) {
                if (verificationMatch.website_file === null) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            <Space>
                                <Tag color="red">DELETED</Tag>
                            </Space>
                        </div>
                    );
                }

                return (
                    <div style={{ textAlign: 'center' }}>
                        <Button
                            type="link"
                            href={verificationMatch.website_file?.remote_url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Open
                        </Button>
                        <Button
                            type="link"
                            danger
                            onClick={() => {
                                http.delete(
                                    `${process.env.REACT_APP_API_URL}/admin/website_files/${verificationMatch.website_file?.id}/delete`,
                                ).then((response) => {
                                    // console.log(response);
                                });
                            }}
                        >
                            Delete
                        </Button>
                    </div>
                );
            },
        },
        {
            title: 'Website File Image',
            render: function WebsiteFileImageColumn(verificationMatch: VerificationMatch) {
                const image = getWebsiteFileImage(verificationMatch);

                if (!image) {
                    return 'N/a';
                }

                return (
                    <a href={image} target="_blank" rel="noopener noreferrer">
                        Open
                    </a>
                );
            },
        },
        {
            title: 'Copyright Entry File Image',
            width: '175px',
            render: function CopyrightEntryImageColumn(verificationMatch: VerificationMatch) {
                if (verificationMatch.copyright_entry_file === null) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            <Space>
                                <Tag color="red">DELETED</Tag>
                            </Space>
                        </div>
                    );
                }

                const image = getCopyrightEntryFileImage(verificationMatch);
                if (image === undefined) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            <Space>
                                <Tag color="volcano">NOT FOUND</Tag>
                            </Space>
                        </div>
                    );
                }

                return (
                    <a href={image} target="_blank" rel="noopener noreferrer">
                        Open
                    </a>
                );
            },
        },
        {
            title: 'Distance',
            render: function DistanceColumn(verificationMatch: VerificationMatch) {
                return <Space>{verificationMatch.distance}</Space>;
            },
        },
        {
            title: 'Date Detected',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date: string) => moment(date).format(DATE.dateFormat),
        },
    ];

    return (
        <AdminLayout>
            <Helmet>
                <title>Admin Verification Matches | Copyknight Admin</title>
            </Helmet>

            <PageHeader title="Admin Verification Matches">
                {!isLoading && (
                    <Table
                        locale={{ emptyText: 'No verification matches yet' }}
                        bordered
                        columns={columns}
                        pagination={pagination}
                        rowKey="id3"
                        dataSource={data?.data}
                        loading={isLoading}
                        onChange={handleTableChange}
                    />
                )}
            </PageHeader>
        </AdminLayout>
    );
};
