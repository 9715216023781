import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useQuery } from 'react-query';
import { Badge, Card, List, Space, Typography } from 'antd';
import { DATE } from '@config/date';
import { PAGINATION } from '@config/pagination';
import { Pagination } from 'interfaces/pagination';
import { useAuthUser } from 'react-auth-kit';
import NotificationsApi, { Notification } from '@services/notifications';
import {
    AlertTwoTone,
    CheckCircleTwoTone,
    InfoCircleTwoTone,
    NotificationTwoTone,
    WarningTwoTone,
} from '@ant-design/icons';
import { useNotificationsContext } from '@context/NotificationsContext';
import { paginationSelect } from '@util/react-query';

export const NotificationList: React.FC = () => {
    const auth = useAuthUser();
    const user = auth() as { id: number; is_admin: boolean; is_mod: boolean };
    const { markAsRead } = useNotificationsContext();

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data, isLoading, refetch } = useQuery(
        ['notifications', user?.id, pagination],
        () => NotificationsApi.getNotifications(pagination),
        {
            enabled: !!user,
            keepPreviousData: true,
            select: paginationSelect,
        },
    );

    const getNotificationIcon = (notification: Notification) => {
        switch (notification?.data?.type) {
            case 'info':
                return <InfoCircleTwoTone twoToneColor="#3b91f6" />;
            case 'success':
                return <CheckCircleTwoTone twoToneColor="#11cc33" />;
            case 'warning':
                return <WarningTwoTone twoToneColor="#FFCC00" />;
            case 'error':
                return <AlertTwoTone twoToneColor="#FF0000" />;
            default:
                return <NotificationTwoTone />;
        }
    };

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: data?.total,
            pageSize: data?.per_page,
            current: data?.current_page,
        });
    }, [data]);

    useEffect(() => {
        const timeout = setTimeout(() => {
            markAsRead.mutateAsync().then(() => refetch());
        }, 2000);

        return () => clearTimeout(timeout);
    }, []);

    if (!user) {
        return null;
    }

    return (
        <Card title="Notifications">
            <List
                locale={{ emptyText: 'No notifications' }}
                loading={isLoading}
                itemLayout="horizontal"
                dataSource={data?.data}
                pagination={{
                    onChange: (page) => {
                        setPagination({
                            ...pagination,
                            current: page,
                        });
                    },
                    ...pagination,
                }}
                renderItem={(item) => (
                    <List.Item>
                        <List.Item.Meta
                            avatar={getNotificationIcon(item)}
                            title={item.data.title}
                            description={
                                <Space align="baseline" style={{ width: '100%', justifyContent: 'space-between' }}>
                                    <Space align="baseline" direction="vertical">
                                        {item.data.items &&
                                            item.data.items.map((message, i) => (
                                                <Typography.Text key={i}>{message}</Typography.Text>
                                            ))}
                                        {!item.data.items && <Typography.Text>{item.data.message}</Typography.Text>}
                                        <Typography.Text type="secondary">
                                            {moment(item.created_at).format(DATE.dateTimeFormat)}
                                        </Typography.Text>
                                    </Space>
                                    <Badge dot count={!item.read_at ? 1 : 0} />
                                </Space>
                            }
                        />
                    </List.Item>
                )}
            />
        </Card>
    );
};
