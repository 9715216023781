import React from 'react';

import { useHistory } from 'react-router';
import { useIsAuthenticated } from 'react-auth-kit';
import { useDefaultRoute } from '@hooks/default-route';
import { ROUTES } from '@config/routes';

export const IndexPage: React.FC = () => {
    const { push } = useHistory();
    const isAuthenticated = useIsAuthenticated();
    const defaultRoute = useDefaultRoute();

    if (isAuthenticated()) {
        push(defaultRoute);
        return null;
    } else {
        push(ROUTES.LOGIN);
    }

    return null;
};
