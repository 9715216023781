import React from 'react';
import { Alert } from 'antd';
import { AxiosError } from 'axios';

interface ApiError {
    errors?: { [key: string]: string[] | string };
    message?: string;
}

interface ApiErrorProps {
    error: AxiosError<ApiError> | null;
}

export const ApiError: React.FC<ApiErrorProps> = ({ error }: ApiErrorProps) => {
    const errorData = error?.response?.data;

    let errorMessage = 'There was an error. Please try again.';

    if (errorData?.errors) {
        const firstErrorKey = Object.keys(errorData.errors)?.[0];
        if (errorData.errors.validation_error) {
            errorMessage = errorData.errors.validation_error as string;
        } else if (firstErrorKey) {
            if (errorData.errors[firstErrorKey].length) {
                errorMessage = errorData.errors[firstErrorKey][0];
            } else {
                errorMessage = errorData.errors[firstErrorKey] as string;
            }
        }
    } else if (errorData?.message) {
        errorMessage = errorData.message;
    }

    return <Alert type="error" showIcon={true} message={errorMessage} />;
};
