import { User } from './admin-users';
import http from './http';

export interface Conversation {
    id: number;
    title: string;
    recipient_id: number;
    seen_by_admin: boolean;
    seen_by_user: boolean;
    deleted_by_admin: boolean;
    deleted_by_user: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    recipients: {
        name: string;
        email: string;
    };
}

export interface Message {
    conversation_id: number;
    created_at: string;
    deleted_at: string | null;
    detail: string;
    id: number;
    type: string;
    updated_at: string;
    user_id: number;
    user?: User;
}

export interface ConversationsIndexResponse {
    conversations: Conversation[];
}

export interface MessagesIndexResponse {
    conversation?: Conversation;
    messages: Message[];
}

export interface CreateConversationRequest {
    detail: string;
    recipient_id?: number;
}

export interface CreateConversationResponse {
    id: number;
    messages: Message[];
}

export interface CreateMessageRequest {
    detail: string;
    recipient_id?: number;
}

export default class MessagesApi {
    public static getConversations(): Promise<ConversationsIndexResponse & { data: ConversationsIndexResponse }> {
        return http.get('/conversations');
    }

    public static createConversation(data: CreateConversationRequest): Promise<CreateConversationResponse> {
        return http.post('/conversations', {
            ...data,
            title: 'Support',
        });
    }

    public static getMessages(conversationId: number): Promise<MessagesIndexResponse> {
        return http.get(`/conversations/${conversationId}`);
    }

    public static sendMessage(conversationId: number, data: CreateMessageRequest): Promise<MessagesIndexResponse> {
        return http.post(`/conversations/${conversationId}/messages`, data);
    }
}
