import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { useMutation, useQuery } from 'react-query';
import { Button, Card, notification, Space, Table, TablePaginationConfig } from 'antd';
import { DATE } from '@config/date';
import { PAGINATION } from '@config/pagination';
import { Pagination } from 'interfaces/pagination';
import { User } from '@services/admin-users';
import { AxiosError } from 'axios';
import confirm from 'antd/lib/modal/confirm';
import { DeleteOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import WebsiteApi, { Website } from '@services/websites';
import { getWebsiteStatus } from '@util/website';
import { useAuthUser } from 'react-auth-kit';
import { paginationSelect } from '@util/react-query';

interface Props {
    user?: User;
}

export const PublisherWebsiteList: React.FC<Props> = ({ user }: Props) => {
    const auth = useAuthUser();
    const userAuth = auth() as { is_admin: boolean; is_mod: boolean };

    const loggedInAsAdmin = userAuth.is_admin;

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data, isLoading, refetch } = useQuery(
        ['admin-publisher-websites', pagination],
        () => WebsiteApi.getWebsites(pagination, String(user?.id)),
        {
            keepPreviousData: true,
            enabled: !!user?.id,
            select: paginationSelect,
        },
    );

    const deleteWebsite = useMutation<void, AxiosError, Website>(async (website) => {
        try {
            if (!website) {
                return;
            }
            await WebsiteApi.delete(String(website.id));
            await refetch();

            notification.success({
                message: 'Website deleted!',
            });
        } catch (error) {
            const errorMessage = error?.response?.data?.message;

            notification.error({
                message: errorMessage ?? 'Error deleting website',
            });
        }
    });

    const onDelete = (website: Website) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete this website?',
            okText: 'Delete',
            okType: 'danger',
            onOk() {
                deleteWebsite.mutate(website);
            },
        });
    };

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: data?.total,
            pageSize: data?.per_page,
            current: data?.current_page,
        });
    }, [data]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filters: Record<string, (string | number | boolean)[] | null>,
    ) => {
        setPagination(pagination);
    };

    const columns = [
        {
            title: 'Name',
            render: function NameColumn(website: Website) {
                return <Space>{website.name}</Space>;
            },
        },
        {
            title: 'Date Submitted',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date: string) => moment(date).format(DATE.dateFormat),
        },
        {
            title: 'Status',
            render: (website: Website) => getWebsiteStatus(website),
        },
        {
            title: 'Denial Reason',
            width: 320,
            render: function DenialReasonColumn(website: Website) {
                return <>{website.disapproved && <Space>{website.disaproval_reason}</Space>}</>;
            },
        },
        {
            title: 'Actions',
            render: function ActionsColumn(website: Website) {
                return (
                    <Space>
                        {loggedInAsAdmin && (
                            <Button danger onClick={() => onDelete(website)}>
                                <DeleteOutlined />
                            </Button>
                        )}
                    </Space>
                );
            },
        },
    ];

    if (!user) {
        return null;
    }

    return (
        <Card title="Websites">
            <Table
                locale={{ emptyText: 'No Websites yet' }}
                bordered
                columns={columns}
                pagination={pagination}
                rowKey="id1"
                dataSource={data?.data}
                loading={isLoading}
                onChange={handleTableChange}
            />
        </Card>
    );
};
