import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useQuery } from 'react-query';
import WebsiteApi, { VerificationMatch } from '@services/websites';
import { Image, PageHeader, Space, Table, TablePaginationConfig, Typography } from 'antd';
import { DATE } from '@config/date';
import { PAGINATION } from '@config/pagination';
import { Pagination } from 'interfaces/pagination';
import { PublisherLayout } from '@components/layouts/publisher';
import { getApiBannerUrl } from '@util/api-url';
import { paginationSelect } from '@util/react-query';

export const PublisherVerificationMatchesIndexPage: React.FC = () => {
    useProtectedRoute(ROLES.PUBLISHER);

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data, isLoading } = useQuery(
        ['my-website-verification-matches', pagination],
        () => WebsiteApi.getWebsiteVerificationMatches(pagination),
        { keepPreviousData: true, select: paginationSelect },
    );

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: data?.total,
            pageSize: data?.per_page,
            current: data?.current_page,
        });
    }, [data]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filters: Record<string, (string | number | boolean)[] | null>,
    ) => {
        setPagination(pagination);
    };

    const columns = [
        {
            title: 'Content ID',
            render: function WebsiteContentIdColumn(verificationMatch: VerificationMatch) {
                return verificationMatch.website_file?.remote_content_id;
            },
        },
        {
            title: 'Website',
            render: function WebsiteNameColumn(verificationMatch: VerificationMatch) {
                return <Space>{verificationMatch.website_file?.website?.name}</Space>;
            },
        },
        {
            title: 'Website File',
            render: function WebsiteFileColumn(verificationMatch: VerificationMatch) {
                return (
                    <a href={verificationMatch.website_file?.remote_url} target="_blank" rel="noopener noreferrer">
                        {verificationMatch.website_file?.remote_url}
                    </a>
                );
            },
        },
        {
            title: 'Required Action',
            render: function RequiredActionColumn(verificationMatch: VerificationMatch) {
                return (
                    <Typography.Text style={{ textTransform: 'capitalize' }}>
                        {verificationMatch?.copyright_entry_file?.copyright_entries?.copyright_groups?.action}
                    </Typography.Text>
                );
            },
        },
        {
            title: 'Date Detected',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date: string) => moment(date).format(DATE.dateFormat),
        },
    ];

    const expandableOptions = {
        expandedRowRender: function RenderBanners(verificationMatch: VerificationMatch) {
            return (
                <Space direction="vertical">
                    <Typography.Text strong>Banners</Typography.Text>
                    <Typography.Text>Use these banners to attribute artist artwork.</Typography.Text>
                    <div>
                        {verificationMatch.copyright_entry_file?.copyright_entries?.copyright_groups?.copyright_banner_collections?.copyright_banners?.map(
                            (copyrightBanner) => (
                                <Image key={copyrightBanner.id} src={getApiBannerUrl(copyrightBanner)} />
                            ),
                        )}
                    </div>
                </Space>
            );
        },
        rowExpandable: (verificationMatch: VerificationMatch) =>
            verificationMatch.copyright_entry_file?.copyright_entries?.copyright_groups?.action === 'banner',
    };

    return (
        <PublisherLayout>
            <Helmet>
                <title>My Verification Matches | Copyknight</title>
            </Helmet>

            <PageHeader title="Verification Matches">
                <Table
                    locale={{ emptyText: 'No Websites yet' }}
                    bordered
                    columns={columns}
                    pagination={pagination}
                    rowKey="id10"
                    dataSource={data?.data}
                    loading={isLoading}
                    onChange={handleTableChange}
                    expandable={expandableOptions}
                />
            </PageHeader>
        </PublisherLayout>
    );
};
