import React, { useRef, useState } from 'react';

import { ExclamationCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { UserLayout } from '@components/layouts/user';
import { useMutation, useQuery } from 'react-query';
import {
    Button,
    Card,
    Col,
    Input,
    PageHeader,
    Row,
    Spin,
    Form,
    notification,
    Typography,
    Space,
    Select,
    Upload,
    Image,
    Progress,
    Alert,
    Tooltip,
} from 'antd';
import { PlaySquareTwoTone, DeleteTwoTone, CheckCircleTwoTone, WarningTwoTone, CopyTwoTone } from '@ant-design/icons';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';
import { ApiError } from '@components/api-error';
import CopyrightEntriesApi, { CopyrightEntryFile, CreateCopyrightEntryRequest } from '@services/copyright-entries';
import CopyrightsApi from '@services/copyrights';
import { UploadFile } from 'antd/lib/upload/interface';
import Modal from 'antd/lib/modal/Modal';
import { FieldInfo } from '@components/field-info';
import CopyrightGroupsApi from '@services/copyright-groups';
import { getApiFileUrl, getApiThumbnailUrl } from '@util/api-url';
import { fallbackImageUrl } from '@util/fallback-image';
import confirm from 'antd/lib/modal/confirm';
import Resumable from 'resumablejs';
import { useAppAuthContext } from '@context/AppAuthContext';
import { getEntryStatus } from '@util/entry';
import { deepOptions } from '@util/react-query';

interface UrlParams {
    copyrightId: string;
    entryId: string;
}

interface FormUploadFile {
    image: string | ArrayBuffer | null;
    visible: boolean;
    title: string | undefined;
}

function getBase64(file: Blob | File | undefined): Promise<string | ArrayBuffer | null> {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file as Blob);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });
}

export const UserCopyrightEntryEditPage: React.FC = () => {
    useProtectedRoute(ROLES.USER);
    const { copyrightId: urlCopyrightId, entryId } = useParams<UrlParams>();

    const [copyrightId, setCopyrightId] = useState(urlCopyrightId);

    if (copyrightId !== ':copyrightId') {
        localStorage.setItem('copyrightId', copyrightId);
    } else {
        setCopyrightId(localStorage.getItem('copyrightId') as string);
    }

    const { push } = useHistory();
    const [form] = useForm();
    const { getToken } = useAppAuthContext();
    const [fileList, setFileList] = useState<UploadFile<Record<string, unknown>>[]>([]);
    const [preview, setPreview] = useState<FormUploadFile>({
        image: '',
        visible: false,
        title: '',
    });
    const resumable = useRef(
        new Resumable({
            chunkSize: 0.4 * 1024 * 1024,
            simultaneousUploads: 3,
            testChunks: false,
            target: CopyrightEntriesApi.getUploadFileUrl(copyrightId, entryId),
            headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
        }),
    );
    const upload = () =>
        new Promise((resolve, reject) => {
            resumable.current.on('complete', resolve);
            resumable.current.on('error', reject);
            resumable.current.on('fileProgress', (file) => {
                const progress = file.progress(false);
                const fileName = file.fileName;
                const fileFromList = fileList.find((f) => f.name === fileName);
                if (fileFromList) {
                    fileFromList.percent = progress * 100;
                    setFileList([...fileList]);
                }
            });
            resumable.current.upload();
        });

    const { data: copyright, isLoading: isLoadingCopyright } = useQuery(
        ['copyright', copyrightId],
        () => CopyrightsApi.getCopyright(localStorage.getItem('copyrightId') as string),
        deepOptions,
    );

    const { data: copyrightEntry, isLoading: isLoadingCopyrightEntry, refetch: refetchEntry } = useQuery(
        ['copyright-entry', entryId],
        () => CopyrightEntriesApi.getCopyrightEntry(localStorage.getItem('copyrightId') as string, entryId),
        deepOptions,
    );

    const { data: copyrightEntryFiles, isLoading: isLoadingCopyrightEntryFiles, refetch: refetchFiles } = useQuery(
        ['copyright-entry-files', entryId],
        () =>
            CopyrightEntriesApi.getCopyrightEntryFiles(
                JSON.parse(localStorage.getItem('copyrightId') as string),
                entryId,
            ),
        deepOptions,
    );

    const { data: copyrightGroups, isLoading: isLoadingCopyrightGroups } = useQuery(
        ['copyright-groups', copyrightId],
        () => CopyrightGroupsApi.getGroups(Number(localStorage.getItem('copyrightId') as string), {}),
        deepOptions,
    );

    const isLoading =
        isLoadingCopyright || isLoadingCopyrightEntry || isLoadingCopyrightEntryFiles || isLoadingCopyrightGroups;

    const mutation = useMutation<void, AxiosError, CreateCopyrightEntryRequest>(async (data) => {
        try {
            await CopyrightEntriesApi.edit(copyrightId, entryId, data);
            await upload();
            await Promise.all([refetchEntry(), refetchFiles()]);
            setFileList([]);

            notification.success({
                message: 'Copyright entry updated!',
            });
        } catch (error) {
            notification.error({
                message: 'Error updating copyright entry',
            });
        }
    });

    const deleteFile = useMutation<void, AxiosError, CopyrightEntryFile>(async (file) => {
        try {
            if (!file) {
                return;
            }
            await CopyrightEntriesApi.deleteFile(copyrightId, String(file.copyright_entry_id), String(file?.id));
            await refetchFiles();

            notification.success({
                message: 'File deleted!',
            });
        } catch (error) {
            notification.error({
                message: 'Error deleting file',
            });
        }
    });

    const onFileDelete = (file: CopyrightEntryFile) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete this file?',
            okText: 'Delete',
            okType: 'danger',
            onOk() {
                deleteFile.mutate(file);
            },
        });
    };

    const checkUniqueness = useMutation<void, AxiosError, CopyrightEntryFile>(async (file) => {
        try {
            if (!file) {
                return;
            }

            await CopyrightEntriesApi.uniquenessCheck(copyrightId, String(file.copyright_entry_id), String(file.id));

            notification.success({
                message: 'Unique check initiated!',
            });
        } catch (error) {
            notification.error({
                message: 'Error initiating uniqueness check!',
            });
        }
    });

    const uniquenessCheck = (file: CopyrightEntryFile) => {
        checkUniqueness.mutate(file);
    };

    const deleteEntry = useMutation<void, AxiosError, void>(async () => {
        try {
            if (copyrightEntryFiles) {
                await Promise.all(
                    copyrightEntryFiles.files.map((file) =>
                        CopyrightEntriesApi.deleteFile(copyrightId, String(file.copyright_entry_id), String(file?.id)),
                    ),
                );
            }
            await CopyrightEntriesApi.deleteEntry(copyrightId, String(entryId));

            notification.success({
                message: 'Entry deleted!',
            });

            push(ROUTES.USER.COPYRIGHT_ENTRIES(copyright).INDEX);
        } catch (error) {
            notification.error({
                message: 'Error deleting copyright entry',
            });
        }
    });

    const onDeleteEntry = () => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete this copyright entry?',
            okText: 'Delete',
            okType: 'danger',
            onOk() {
                deleteEntry.mutate();
            },
        });
    };

    const onPreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await (getBase64(file.originFileObj) as Promise<string>);
        }

        setPreview({
            image: (file.url || file.preview) as string,
            visible: true,
            title: file.name || file.url?.substring(file.url?.lastIndexOf('/') + 1),
        });
    };

    const isTrusted = copyrightEntry?.is_trusted;

    if (copyright && !copyright.approved) {
        push(ROUTES.USER.COPYRIGHT_ENTRIES(copyright).INDEX);
        return null;
    }

    return (
        <UserLayout>
            <Helmet>
                <title>Edit Copyright Entry | Copyknight</title>
            </Helmet>

            <PageHeader onBack={() => push(ROUTES.USER.COPYRIGHT_ENTRIES(copyright).INDEX)} title={copyright?.name}>
                <Card
                    title={
                        <Space>
                            Edit Copyright Entry
                            {getEntryStatus(copyrightEntry)}
                        </Space>
                    }
                    extra={
                        <Button type="ghost" danger onClick={onDeleteEntry} loading={deleteEntry.isLoading}>
                            Delete
                        </Button>
                    }
                >
                    <Row justify="center" gutter={15}>
                        <Col span={24} md={12} lg={12} xl={12}>
                            {isLoading ? (
                                <Spin size="large" spinning={true} />
                            ) : (
                                <Form
                                    layout="vertical"
                                    name="edit-copyright"
                                    form={form}
                                    onFinish={mutation.mutate}
                                    initialValues={copyrightEntry}
                                >
                                    <Form.Item
                                        label="Name"
                                        name="title"
                                        rules={[{ required: true, message: 'Please enter the entry title!' }]}
                                    >
                                        <Input disabled={mutation.isLoading} placeholder="Name of Request" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Backurl"
                                        name="url"
                                        rules={[
                                            { required: true, type: 'url', message: 'Please enter a valid entry url!' },
                                        ]}
                                    >
                                        <Space direction="vertical" size="middle">
                                            <Input
                                                defaultValue={copyrightEntry?.url}
                                                placeholder="https://homepage-url.com"
                                                disabled={mutation.isLoading}
                                            />

                                            <FieldInfo>
                                                Please provide publicly available links to the finished work(s) or
                                                work(s) in progress so we can confirm you are the copyright holder and
                                                submit any applicable images
                                            </FieldInfo>
                                        </Space>
                                    </Form.Item>

                                    <Form.Item
                                        label="Copyright Group"
                                        name="copyright_group_id"
                                        rules={[{ required: true, message: 'Please select the copyright group!' }]}
                                    >
                                        <Select placeholder="Copyright Group" disabled={mutation.isLoading}>
                                            {copyrightGroups?.map((group) => (
                                                <Select.Option key={group.id} value={group.id}>
                                                    {group.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item>
                                        <Upload
                                            disabled={mutation.isLoading}
                                            listType="picture-card"
                                            fileList={fileList}
                                            onPreview={onPreview}
                                            beforeUpload={() => false}
                                            onChange={({ file, fileList }) => {
                                                resumable.current.addFile((file as unknown) as File);
                                                setFileList(fileList);
                                            }}
                                            itemRender={(originNode, file) => (
                                                <Space direction="vertical" size="small" style={{ width: '100%' }}>
                                                    <div style={{ height: 83 }}>{originNode}</div>
                                                    <Progress percent={file.percent} showInfo={false} />
                                                </Space>
                                            )}
                                        >
                                            {fileList.length >= 8 ? null : (
                                                <div>
                                                    <PlusOutlined />
                                                    <div style={{ marginTop: 8 }}>Select</div>
                                                </div>
                                            )}
                                        </Upload>

                                        <Modal
                                            visible={preview.visible}
                                            title={preview.title}
                                            footer={null}
                                            onCancel={() => setPreview({ ...preview, visible: false })}
                                        >
                                            <img
                                                alt={preview.title}
                                                style={{ width: '100%' }}
                                                src={preview.image as string}
                                            />
                                        </Modal>
                                    </Form.Item>

                                    {mutation.isError && (
                                        <Form.Item>
                                            <ApiError error={mutation.error} />
                                        </Form.Item>
                                    )}

                                    <Form.Item>
                                        {!!copyrightEntry?.approved && !isTrusted && (
                                            <div style={{ marginBottom: 10 }}>
                                                <Alert
                                                    message="Warning"
                                                    description="Editing an accepted entry will change its status to Pending."
                                                    type="warning"
                                                    showIcon
                                                    closable
                                                />
                                            </div>
                                        )}
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={mutation.isLoading}
                                            style={{ width: '100%' }}
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </Col>

                        <Col span={24} md={12} lg={12} xl={12}>
                            {!!copyrightEntryFiles?.files?.length && (
                                <>
                                    <Typography.Text strong>Files</Typography.Text>

                                    <Row gutter={10}>
                                        <Image.PreviewGroup>
                                            {copyrightEntryFiles?.files.map((file) => (
                                                <Col
                                                    span={24}
                                                    sm={12}
                                                    md={8}
                                                    key={file.id}
                                                    style={{ marginBottom: 10 }}
                                                >
                                                    <Card
                                                        bodyStyle={{ padding: 0 }}
                                                        cover={
                                                            <Image
                                                                src={getApiThumbnailUrl(file)}
                                                                preview={
                                                                    file.type === 'image'
                                                                        ? {
                                                                              src: getApiFileUrl(file),
                                                                          }
                                                                        : false
                                                                }
                                                                fallback={fallbackImageUrl}
                                                            />
                                                        }
                                                        actions={[
                                                            file.type === 'video' && (
                                                                <Tooltip title="Play video">
                                                                    <Button
                                                                        key="1"
                                                                        type="link"
                                                                        onClick={() => {
                                                                            window.open(file.path);
                                                                        }}
                                                                        loading={deleteFile.isLoading}
                                                                    >
                                                                        <PlaySquareTwoTone
                                                                            style={{ fontSize: '170%' }}
                                                                        />
                                                                        {/*Open Video*/}
                                                                    </Button>
                                                                </Tooltip>
                                                            ),
                                                            <Tooltip key="2" title="Delete file">
                                                                <Button
                                                                    key="2"
                                                                    type="link"
                                                                    danger
                                                                    onClick={() => onFileDelete(file)}
                                                                    loading={deleteFile.isLoading}
                                                                >
                                                                    <DeleteTwoTone style={{ fontSize: '170%' }} />
                                                                    {/*Delete*/}
                                                                </Button>
                                                            </Tooltip>,
                                                            Boolean(file.uniqueness_checked && file.is_unique) && (
                                                                <Tooltip title="File is checked and is unique">
                                                                    <Button key="3" type="text">
                                                                        <CheckCircleTwoTone
                                                                            style={{
                                                                                fontSize: '170%',
                                                                                cursor: 'context-menu',
                                                                            }}
                                                                        />
                                                                    </Button>
                                                                </Tooltip>
                                                            ),
                                                            Boolean(file.uniqueness_checked && !file.is_unique) && (
                                                                <Tooltip title="This file is not unique in our system">
                                                                    <Button key="4" type="text">
                                                                        <WarningTwoTone
                                                                            style={{
                                                                                fontSize: '170%',
                                                                                cursor: 'context-menu',
                                                                            }}
                                                                        />
                                                                    </Button>
                                                                </Tooltip>
                                                            ),
                                                            !file.uniqueness_checked && (
                                                                <Tooltip title="Please confirm uniqueness of your file">
                                                                    <Button
                                                                        key="5"
                                                                        type="link"
                                                                        onClick={() => uniquenessCheck(file)}
                                                                    >
                                                                        <CopyTwoTone style={{ fontSize: '170%' }} />
                                                                    </Button>
                                                                </Tooltip>
                                                            ),
                                                        ]}
                                                    />
                                                </Col>
                                            ))}
                                        </Image.PreviewGroup>
                                    </Row>
                                </>
                            )}
                        </Col>
                    </Row>
                </Card>
            </PageHeader>
        </UserLayout>
    );
};
