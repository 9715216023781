import React, { useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { UserLayout } from '@components/layouts/user';
import { useHistory } from 'react-router';
import { ROUTES } from '@config/routes';

export const UserDashboardPage: React.FC = () => {
    useProtectedRoute(ROLES.USER);

    const { push } = useHistory();

    useEffect(() => {
        push(ROUTES.USER.COPYRIGHTS.INDEX);
    }, []);

    return (
        <UserLayout>
            <Helmet>
                <title>Dashboard | Copyknight</title>
            </Helmet>

            <h1>User Dash</h1>
        </UserLayout>
    );
};
