import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { UserLayout } from '@components/layouts/user';
import { useMutation, useQuery } from 'react-query';
import CopyrightsApi from '@services/copyrights';
import {
    Button,
    Card,
    Menu,
    notification,
    PageHeader,
    Result,
    Space,
    Table,
    TablePaginationConfig,
    Tag,
    Tooltip,
    Typography,
} from 'antd';
import { DATE } from '@config/date';
import { PAGINATION } from '@config/pagination';
import { Pagination } from 'interfaces/pagination';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import CopyrightEntriesApi, { CopyrightEntry } from '@services/copyright-entries';
import { ArrowLeftOutlined, ExclamationCircleOutlined, ReloadOutlined } from '@ant-design/icons';
import { getEntryStatus } from '@util/entry';
import { AxiosError } from 'axios';
import confirm from 'antd/lib/modal/confirm';
import { deepOptions, paginationSelect } from '@util/react-query';

interface UrlParams {
    copyrightId: string;
}

export const UserCopyrightEntriesIndexPage: React.FC = () => {
    useProtectedRoute(ROLES.USER);

    const { push } = useHistory();
    const { copyrightId: urlCopyrightId } = useParams<UrlParams>();

    const [copyrightId, setCopyrightId] = useState(urlCopyrightId);

    if (copyrightId !== ':copyrightId') {
        localStorage.setItem('copyrightId', copyrightId);
    } else {
        setCopyrightId(localStorage.getItem('copyrightId') as string);
    }

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data: copyright, isLoading: copyrightLoading } = useQuery(
        ['copyright', copyrightId],
        () => CopyrightsApi.getCopyright(localStorage.getItem('copyrightId') as string),
        deepOptions,
    );

    const { data, isLoading, refetch } = useQuery(
        ['my-copyright-entries', copyrightId, pagination],
        () => CopyrightEntriesApi.getEntries(localStorage.getItem('copyrightId') as string, pagination),
        {
            keepPreviousData: true,
            select: paginationSelect,
        },
    );

    const submitCopyrightEntryForReapproval = useMutation<void, AxiosError, CopyrightEntry>(async (copyrightEntry) => {
        try {
            if (!copyrightEntry) {
                return;
            }
            await CopyrightEntriesApi.submitForReapproval(
                String(copyrightEntry.copyright_id),
                String(copyrightEntry.id),
            );
            await refetch();

            notification.success({
                message: 'Copyright entry submitted for reapproval!',
            });
        } catch (error) {
            const errorMessage = error?.response?.data?.message;

            notification.error({
                message: errorMessage ?? 'Error submitting copyright entry for reapproval',
            });
        }
    });

    const onReapproval = (copyrightEntry: CopyrightEntry) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to submit this copyright entry for reapproval?',
            okText: 'Submit',
            okType: 'danger',
            onOk() {
                submitCopyrightEntryForReapproval.mutate(copyrightEntry);
            },
        });
    };

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: data?.total,
            pageSize: data?.per_page,
            current: data?.current_page,
        });
    }, [data]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filters: Record<string, (string | number | boolean)[] | null>,
    ) => {
        setPagination(pagination);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'title',
            key: 'title',
            render: function NameColumn(name: string, copyrightEntry: CopyrightEntry) {
                return (
                    <Space>
                        <Link to={ROUTES.USER.COPYRIGHT_ENTRIES(copyright)?.DETAILS(copyrightEntry)}>{name}</Link>
                        {!!copyright?.being_deleted && <Tag color="red">Being deleted</Tag>}
                    </Space>
                );
            },
        },
        {
            title: 'Copyright Group',
            key: 'copyrightGroups',
            render: (copyrightEntry: CopyrightEntry) => copyrightEntry?.copyright_groups?.name,
        },
        {
            title: 'Date Submitted',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date: string) => moment(date).format(DATE.dateFormat),
        },
        {
            title: 'Status',
            render: function StatusColumn(copyrightEntry: CopyrightEntry) {
                return (
                    <>
                        {getEntryStatus(copyrightEntry)}
                        {copyrightEntry.approval_denied && (
                            <Tooltip title="Submit for Reapproval">
                                <Button onClick={() => onReapproval(copyrightEntry)} icon={<ReloadOutlined />} />
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
        {
            title: 'Ownership Proof',
            dataIndex: 'ownership_proof',
            key: 'ownership_proof',
            render: function OwnershipProofColumn(ownershipProof: string, copyrightEntry: CopyrightEntry) {
                return (
                    <Space>
                        <a
                            href={copyrightEntry?.copyrights?.ownership_proof_url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Open File
                        </a>
                    </Space>
                );
            },
        },
        {
            title: 'Denial Reason',
            dataIndex: 'approval_denied_reason',
            key: 'approval_denied_reason',
            width: 320,
            render: function DenialReasonColumn(denialReason: string, copyrightEntry: CopyrightEntry) {
                return <>{copyrightEntry.approval_denied && <Space>{denialReason}</Space>}</>;
            },
        },
    ];

    if (!copyrightId) {
        push(ROUTES.USER.DASHBOARD);
        return null;
    }

    return (
        <UserLayout>
            <Helmet>
                <title>My Copyright Entries | Copyknight</title>
            </Helmet>

            <PageHeader
                title={
                    <Space direction="vertical">
                        <Link to={ROUTES.USER.COPYRIGHTS.INDEX}>
                            <Button type="link" icon={<ArrowLeftOutlined />} style={{ padding: 0 }}>
                                Copyrights
                            </Button>
                        </Link>
                        <Typography.Title level={4}>{copyright?.name}</Typography.Title>
                    </Space>
                }
            >
                <Menu mode="horizontal" selectedKeys={['entries']}>
                    <Menu.Item key="entries">
                        <Link to={ROUTES.USER.COPYRIGHT_ENTRIES(copyright).INDEX}>Copyright Entries</Link>
                    </Menu.Item>
                    <Menu.Item key="groups">
                        <Link to={ROUTES.USER.COPYRIGHT_GROUPS(copyright).INDEX}>Copyright Groups</Link>
                    </Menu.Item>
                    <Menu.Item key="collections">
                        <Link to={ROUTES.USER.BANNER_COLLECTIONS(copyright).INDEX}>Banner Collections</Link>
                    </Menu.Item>
                </Menu>

                <Card>
                    <PageHeader
                        title={
                            <Space direction="vertical">
                                <Typography.Title level={4}>Copyright Entries</Typography.Title>
                            </Space>
                        }
                        extra={[
                            <Link key="1" to={ROUTES.USER.COPYRIGHT_ENTRIES(copyright).CREATE}>
                                <Button type="primary" disabled={!copyright?.approved}>
                                    Create
                                </Button>
                            </Link>,
                        ]}
                    >
                        {!copyrightLoading && !copyright?.approved && (
                            <Result
                                status="warning"
                                title="Your Copyright is not accepted yet"
                                subTitle="Once accepted, you'll be able to add copyright entries, groups and banner collections."
                            />
                        )}
                        <Table
                            locale={{ emptyText: 'No Copyright entries yet' }}
                            bordered
                            columns={columns}
                            pagination={pagination}
                            rowKey="id13"
                            dataSource={data?.data}
                            loading={isLoading}
                            onChange={handleTableChange}
                        />
                    </PageHeader>
                </Card>
            </PageHeader>
        </UserLayout>
    );
};
