import { Pagination, ServerPagination } from 'interfaces/pagination';
import http from './http';

export interface BannerSlot {
    id: number;
    description: string;
    resolution: string;
    width: number;
    height: number;
    created_at: string;
    updated_at: string;
}

export type BannerSlotsResponse = ServerPagination<BannerSlot[]>;

export interface CreateBannerSlotRequest {
    description: string;
    resolution: string;
    width: number;
    height: number;
}

export interface BannerSlotDetailsResponse {
    data: BannerSlot;
}

export default class BannerSlotsApi {
    public static getAll(pagination: Pagination): Promise<BannerSlotsResponse & { data?: BannerSlotsResponse }> {
        return http.get('/banner-slots', {
            params: {
                page: pagination.current,
            },
        });
    }

    public static get(bannerSlotId: string): Promise<BannerSlotDetailsResponse> {
        return http.get(`/banner-slots/${bannerSlotId}`);
    }

    public static create(data: CreateBannerSlotRequest): Promise<void> {
        if (data.width && data.height) {
            data.resolution = `${data.width}x${data.height}`;
        }
        return http.post('/banner-slots', data);
    }

    public static edit(bannerSlotId: string, data: CreateBannerSlotRequest): Promise<void> {
        if (data.width && data.height) {
            data.resolution = `${data.width}x${data.height}`;
        }
        return http.put(`/banner-slots/${bannerSlotId}`, data);
    }

    public static delete(id: string): Promise<void> {
        return http.delete(`/banner-slots/${id}`);
    }
}
