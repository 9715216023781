import React, { useCallback, useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { UserLayout } from '@components/layouts/user';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, Col, Input, PageHeader, Row, Spin, Form, notification, Space, Typography, Upload } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';
import CopyrightsApi from '@services/copyrights';
import { ApiError } from '@components/api-error';
import { CreateCopyrightGroupRequest } from '@services/copyright-groups';
import BannerCollectionsApi from '@services/banner-collections';
import { Pagination } from 'interfaces/pagination';
import { PAGINATION } from '@config/pagination';
import BannerSlotsApi, { BannerSlot } from '@services/banner-slots';
import Modal from 'antd/lib/modal/Modal';
import { UploadFile } from 'antd/lib/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import { getBase64 } from '@util/base64';
import { deepSelect, paginationSelect } from '@util/react-query';

interface UrlParams {
    copyrightId: string;
}

interface FormUploadFile {
    image: string | ArrayBuffer | null;
    visible: boolean;
    title: string | undefined;
}

interface BannerUploadFile {
    file: UploadFile;
    bannerSlot: BannerSlot;
}

export const UserBannerCollectionCreatePage: React.FC = () => {
    useProtectedRoute(ROLES.USER);
    const { push } = useHistory();
    const [form] = useForm();

    const { copyrightId: urlCopyrightId } = useParams<UrlParams>();

    const [copyrightId, setCopyrightId] = useState(urlCopyrightId);

    if (copyrightId !== ':copyrightId') {
        localStorage.setItem('copyrightId', copyrightId);
    } else {
        setCopyrightId(localStorage.getItem('copyrightId') as string);
    }

    const [bannerList, setBannerList] = useState<Record<string, BannerUploadFile>>({});
    const [preview, setPreview] = useState<FormUploadFile>({
        image: '',
        visible: false,
        title: '',
    });

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data: copyright, isLoading } = useQuery(
        ['copyright', copyrightId],
        () => CopyrightsApi.getCopyright(localStorage.getItem('copyrightId') as string),
        {
            select: deepSelect,
        },
    );

    const { data: bannerSlots } = useQuery(['banner-slots'], () => BannerSlotsApi.getAll(pagination), {
        keepPreviousData: true,
        select: paginationSelect,
    });

    const mutation = useMutation<void, AxiosError, CreateCopyrightGroupRequest>(async (data) => {
        try {
            if (Object.values(bannerList).filter(Boolean).length !== bannerSlots?.total) {
                notification.error({
                    message: 'Please select all banner slots',
                });
                return;
            }

            const response = await BannerCollectionsApi.create(localStorage.getItem('copyrightId') as string, data);
            const bannerCollectionId = response.data.id;

            if (!bannerSlots || !bannerCollectionId) {
                return;
            }

            await Promise.all(
                bannerSlots.data.map((bannerSlot: BannerSlot) =>
                    BannerCollectionsApi.saveBanner(
                        localStorage.getItem('copyrightId') as string,
                        String(bannerCollectionId),
                        {
                            banner_slot: bannerSlot.id,
                            file: bannerList[bannerSlot.id].file,
                        },
                    ),
                ),
            );
            notification.success({
                message: 'Banner collection created!',
            });
            push(ROUTES.USER.BANNER_COLLECTIONS(copyright).INDEX);
        } catch (error) {
            console.log({ error });

            notification.error({
                message: 'Error creating banner collection',
            });
        }
    });

    const loadImage = useCallback((path): Promise<HTMLImageElement> => {
        return new Promise((resolve) => {
            const img = new Image();
            img.crossOrigin = 'Anonymous'; // to avoid CORS if used with Canvas
            img.src = path;
            img.onload = () => {
                resolve(img);
            };
        });
    }, []);

    const beforeUpload = useCallback(async (file, bannerSlot: BannerSlot) => {
        const acceptedImageTypes = ['image/gif', 'image/jpeg', 'image/png'];
        const isImage = file && acceptedImageTypes.includes(file['type']);
        if (!isImage) {
            notification.error({
                message: 'Please provide a real image',
            });
            return Upload.LIST_IGNORE;
        } else {
            const img = await loadImage(window.URL.createObjectURL(file));
            const [width, height] = bannerSlot.resolution.split('x');
            if (!(img.naturalWidth === parseInt(width) && img.naturalHeight === parseInt(height))) {
                notification.error({
                    message: `Please provide a image with required resolution, got ${img.naturalWidth}x${img.naturalHeight}, expected ${bannerSlot.resolution}`,
                });
                return Upload.LIST_IGNORE;
            }
        }

        return false;
    }, []);

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: bannerSlots?.total,
            pageSize: bannerSlots?.per_page,
            current: bannerSlots?.current_page,
        });
    }, [bannerSlots]);

    const onPreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await (getBase64(file.originFileObj) as Promise<string>);
        }

        setPreview({
            image: (file.url || file.preview) as string,
            visible: true,
            title: file.name || file.url?.substring(file.url?.lastIndexOf('/') + 1),
        });
    };

    if (copyright && !copyright.approved) {
        push(ROUTES.USER.COPYRIGHT_ENTRIES(copyright).INDEX);
        return null;
    }

    return (
        <UserLayout>
            <Helmet>
                <title>Create a Banner Collection | Copyknight</title>
            </Helmet>

            <PageHeader
                onBack={() => push(ROUTES.USER.BANNER_COLLECTIONS(copyright).INDEX)}
                title={
                    <Space direction="vertical">
                        <Typography.Text>Banner Collections</Typography.Text>
                    </Space>
                }
            >
                <Typography.Title level={3}>{copyright?.name}</Typography.Title>
                <Row justify="center">
                    <Col span={24} md={12} lg={12} xl={12}>
                        <Card title="Create a Banner Collection">
                            {isLoading ? (
                                <Spin size="large" spinning={true} />
                            ) : (
                                <Form
                                    layout="vertical"
                                    name="create-banner-collection"
                                    form={form}
                                    onFinish={mutation.mutate}
                                >
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please enter the collection name!' }]}
                                    >
                                        <Input placeholder="Name" />
                                    </Form.Item>

                                    <Form.Item>
                                        <Typography.Title level={5}>Add banners</Typography.Title>
                                    </Form.Item>

                                    {bannerSlots?.data.map((bannerSlot: BannerSlot) => (
                                        <Form.Item key={bannerSlot.id}>
                                            <Upload
                                                listType="picture"
                                                fileList={
                                                    bannerList[bannerSlot.id]?.file
                                                        ? [bannerList[bannerSlot.id].file]
                                                        : undefined
                                                }
                                                multiple={false}
                                                maxCount={1}
                                                onPreview={onPreview}
                                                beforeUpload={(file) => beforeUpload.apply(this, [file, bannerSlot])}
                                                onChange={({ fileList }) =>
                                                    setBannerList({
                                                        ...bannerList,
                                                        [bannerSlot.id]: { bannerSlot, file: fileList[0] },
                                                    })
                                                }
                                            >
                                                {!!bannerList.length ? null : (
                                                    <Button>
                                                        <PlusOutlined />
                                                        Select size {bannerSlot.resolution}
                                                    </Button>
                                                )}
                                            </Upload>
                                        </Form.Item>
                                    ))}

                                    {mutation.isError && (
                                        <Form.Item>
                                            <ApiError error={mutation.error} />
                                        </Form.Item>
                                    )}

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={mutation.isLoading}
                                            style={{ width: '100%' }}
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </Card>

                        <Modal
                            visible={preview.visible}
                            title={preview.title}
                            footer={null}
                            onCancel={() => setPreview({ ...preview, visible: false })}
                        >
                            <img alt={preview.title} style={{ width: '100%' }} src={preview.image as string} />
                        </Modal>
                    </Col>
                </Row>
            </PageHeader>
        </UserLayout>
    );
};
