import React, { useState, useEffect } from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useMutation } from 'react-query';
import { Button, Card, Col, Input, PageHeader, Row, Spin, Form, notification, Checkbox } from 'antd';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';
import WebsiteApi, { CreateWebsiteRequest } from '@services/websites';
import { ApiError } from '@components/api-error';
import { PublisherLayout } from '@components/layouts/publisher';

export const PublisherWebsiteCreatePage: React.FC = () => {
    useProtectedRoute(ROLES.PUBLISHER);
    const { push } = useHistory();
    const [form] = useForm();
    const [isReverse, setIsReverse] = useState(false);
    const [isVerification, setIsVerification] = useState(false);

    const isLoading = false;

    const mutation = useMutation<void, AxiosError, CreateWebsiteRequest>(async (data) => {
        try {
            await WebsiteApi.create(data);
            notification.success({
                message: 'Website created!',
            });
            push(ROUTES.USER.COPYRIGHTS.INDEX);
        } catch (error) {
            notification.error({ message: 'Error creating webiste' });
        }
    });
    useEffect(() => {
        form.validateFields();
    });

    return (
        <PublisherLayout>
            <Helmet>
                <title>Create a Website | Copyknight</title>
            </Helmet>

            <PageHeader onBack={() => push(ROUTES.PUBLISHER.WEBSITES.INDEX)} title="Create a Website">
                <Row justify="center">
                    <Col span={24} md={12} lg={12} xl={12}>
                        <Card>
                            {isLoading ? (
                                <Spin size="large" spinning={true} />
                            ) : (
                                <Form layout="vertical" name="create-website" form={form} onFinish={mutation.mutate}>
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please enter the website name!' }]}
                                    >
                                        <Input placeholder="Website name" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Base URL"
                                        name="base_url"
                                        rules={[
                                            {
                                                type: 'url',
                                                required: true,
                                                message: 'Please enter a valid website url!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Website url - https://..." />
                                    </Form.Item>
                                    <Form.Item
                                        label="Webhook URL"
                                        name="webhook_url"
                                        rules={[
                                            {
                                                type: 'url',
                                                required: isReverse || isVerification,
                                                message: 'Please enter a valid webhook url!',
                                            },
                                        ]}
                                    >
                                        <Input placeholder="Webhook url - https://..." />
                                    </Form.Item>
                                    <Form.Item name="notify_after_reverse_content_check">
                                        <Checkbox checked={isReverse} onChange={(e) => setIsReverse(e.target.checked)}>
                                            Notify after reverse content check
                                        </Checkbox>
                                    </Form.Item>
                                    <Form.Item name="notify_after_content_verification_check">
                                        <Checkbox
                                            checked={isVerification}
                                            onChange={(e) => setIsVerification(e.target.checked)}
                                        >
                                            Notify after content verification check
                                        </Checkbox>
                                    </Form.Item>
                                    {mutation.isError && (
                                        <Form.Item>
                                            <ApiError error={mutation.error} />
                                        </Form.Item>
                                    )}

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={mutation.isLoading}
                                            style={{ width: '100%' }}
                                            // onClick={submitForm}
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </Card>
                    </Col>
                </Row>
            </PageHeader>
        </PublisherLayout>
    );
};
