import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useMutation, useQuery } from 'react-query';
import {
    Button,
    Form,
    Input,
    Modal,
    notification,
    PageHeader,
    Radio,
    Space,
    Table,
    TablePaginationConfig,
    Tag,
} from 'antd';
import { DATE } from '@config/date';
import { PAGINATION } from '@config/pagination';
import { Pagination } from 'interfaces/pagination';
import { Link } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { AdminLayout } from '@components/layouts/admin';
import { AxiosError } from 'axios';
import { useForm } from 'antd/lib/form/Form';
import { ApiError } from '@components/api-error';
import CopyrightEntriesApi, { ChangeCopyrightEntryStatusRequest, CopyrightEntry } from '@services/copyright-entries';
import { paginationSelect } from '@util/react-query';

export const AdminCopyrightEntriesPendingPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);

    const [form] = useForm();
    const [changeStatusModal, setChangeStatusModal] = useState<{ visible: boolean; copyrightEntry?: CopyrightEntry }>({
        visible: false,
        copyrightEntry: undefined,
    });

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data, isLoading, refetch: refetchPendingCopyrightEntries } = useQuery(
        ['admin-copyright-entries-pending', pagination],
        () => CopyrightEntriesApi.getPendingCopyrightEntriesAdmin(pagination),
        { keepPreviousData: true, select: paginationSelect },
    );

    const changeStatusMutation = useMutation<
        void,
        AxiosError,
        { status: 'accept' | 'deny'; approval_denied_reason?: string }
    >(async (data) => {
        try {
            if (!changeStatusModal.copyrightEntry) {
                return;
            }

            const payload: ChangeCopyrightEntryStatusRequest = {
                approved: data.status === 'accept',
                approval_denied: data.status === 'deny',
                approval_denied_reason: data.approval_denied_reason || '',
            };

            await CopyrightEntriesApi.changeStatus(
                String(changeStatusModal.copyrightEntry?.copyright_id),
                String(changeStatusModal.copyrightEntry?.id),
                payload,
            );
            await refetchPendingCopyrightEntries();

            form.resetFields();

            notification.success({
                message: 'Copyright entry status changed!',
            });

            closeChangeStatusModal();
        } catch (error) {
            notification.error({
                message: 'Error changing Copyright entry status',
            });
        }
    });

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: data?.total,
            pageSize: data?.per_page,
            current: data?.current_page,
        });
    }, [data]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filters: Record<string, (string | number | boolean)[] | null>,
    ) => {
        setPagination(pagination);
    };

    const openChangeStatusModal = (copyrightEntry: CopyrightEntry) => {
        setChangeStatusModal({ ...changeStatusModal, copyrightEntry, visible: true });
    };

    const closeChangeStatusModal = () => {
        setChangeStatusModal({ ...changeStatusModal, copyrightEntry: undefined, visible: false });
    };

    const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            render: function NameColumn(title: string, copyrightEntry: CopyrightEntry) {
                return (
                    <Space>
                        {title}
                        {!!copyrightEntry.copyrights?.being_deleted && <Tag color="red">Being deleted</Tag>}
                    </Space>
                );
            },
        },
        {
            title: 'Copyright',
            render: (copyrightEntry: CopyrightEntry) => copyrightEntry.copyrights?.name,
        },
        {
            title: 'Ownership Proof',
            dataIndex: 'ownership_proof',
            key: 'ownership_proof',
            render: function OwnershipProofColumn(ownershipProof: string, copyrightEntry: CopyrightEntry) {
                return (
                    <Space>
                        <a
                            href={copyrightEntry?.copyrights?.ownership_proof_url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Open File
                        </a>
                    </Space>
                );
            },
        },
        {
            title: 'Date Submitted',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date: string) => moment(date).format(DATE.dateFormat),
        },
        {
            title: 'Actions',
            render: function ActionsColumn(copyrightEntry: CopyrightEntry) {
                return <Button onClick={() => openChangeStatusModal(copyrightEntry)}>Change status</Button>;
            },
        },
    ];

    return (
        <AdminLayout>
            <Helmet>
                <title>Pending Copyright Entries | Copyknight Admin</title>
            </Helmet>

            <PageHeader
                title="Pending Copyright Entries"
                extra={
                    <Link to={ROUTES.ADMIN.COPYRIGHT_ENTRIES.FULLSCREEN}>
                        <Button type="primary">Full Screen Mode</Button>
                    </Link>
                }
            >
                <Table
                    locale={{ emptyText: 'No Pending Copyright Entries' }}
                    bordered
                    columns={columns}
                    pagination={pagination}
                    rowKey="id5"
                    dataSource={data?.data}
                    loading={isLoading}
                    onChange={handleTableChange}
                />
            </PageHeader>

            <Modal
                visible={changeStatusModal.visible}
                title="Change Copyright Entry Status"
                onCancel={closeChangeStatusModal}
                footer={
                    <>
                        <Button
                            type="primary"
                            form="change-copyright-entry-status"
                            htmlType="submit"
                            loading={changeStatusMutation.isLoading}
                        >
                            Save
                        </Button>
                    </>
                }
            >
                <Form
                    layout="vertical"
                    name="change-copyright-entry-status"
                    form={form}
                    onFinish={changeStatusMutation.mutate}
                    initialValues={{ status: 'accept' }}
                >
                    <Form.Item label="Approval Status" name="status" rules={[{ required: true }]}>
                        <Radio.Group buttonStyle="solid">
                            <Radio.Button value="accept">Accept</Radio.Button>
                            <Radio.Button value="deny">Deny</Radio.Button>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item shouldUpdate>
                        {({ getFieldValue }) =>
                            getFieldValue('status') === 'deny' ? (
                                <Form.Item label="Approval denied reason" name="approval_denied_reason">
                                    <Input.TextArea placeholder="Reason for denial" />
                                </Form.Item>
                            ) : null
                        }
                    </Form.Item>

                    {changeStatusMutation.isError && (
                        <Form.Item>
                            <ApiError error={changeStatusMutation.error} />
                        </Form.Item>
                    )}
                </Form>
            </Modal>
        </AdminLayout>
    );
};
