import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { UserLayout } from '@components/layouts/user';
import { MessagesListWithForm } from '@components/messages';

export const UserMessagesPage: React.FC = () => {
    useProtectedRoute(ROLES.USER);

    return (
        <UserLayout>
            <Helmet>
                <title>Messages | Copyknight</title>
            </Helmet>

            <h1>Messages</h1>

            <MessagesListWithForm />
        </UserLayout>
    );
};
