import { Pagination, ServerPagination } from 'interfaces/pagination';
import { AccountResponse } from './account';
import http from './http';

export enum UserType {
    ALL = 'all',
    ADMIN = 'admins',
    USER = 'users',
    PUBLISHER = 'publishers',
}
export interface User {
    address: string | null;
    company: string | null;
    created_at: string;
    deleted_at: string | null;
    email: string;
    email_verified_at: string | null;
    homepage: string | null;
    id: number;
    is_admin: boolean;
    is_mod: boolean;
    is_publisher: boolean;
    is_trusted: boolean;
    is_verified: boolean;
    is_disabled: boolean;
    name: string;
    telephone: string | null;
    updated_at: string;
}

export type AdminUsersResponse = ServerPagination<User[]>;

export enum Role {
    USER = 'user',
    PUBLISHER = 'publisher',
    MODERATOR = 'moderator',
    ADMIN = 'admin',
}
export interface CreateUserRequest extends User {
    role: Role;
}

export interface UserDetailResponse {
    user: User;
}
export interface UserDetailResponse {
    data?: UserDetailResponse;
}

export type LoginAsResponse = AccountResponse;

export interface AccountData {
    is_disabled?: boolean;
    is_verified?: boolean;
}

export default class AdminUsersApi {
    public static getUsers(
        type = UserType.USER,
        search = '',
        pagination: Pagination,
    ): Promise<AdminUsersResponse & { data: AdminUsersResponse }> {
        return http.get(`/admin/users/${type}`, {
            params: {
                page: pagination.current,
                per_page: pagination.pageSize,
                email: search,
            },
        });
    }

    public static createUser(data: CreateUserRequest): Promise<void> {
        return http.post(`/admin/users`, data);
    }

    public static getUser(userId: string): Promise<UserDetailResponse> {
        return http.get(`/admin/user/${userId}`);
    }

    public static loginAsUser(userId: string): Promise<LoginAsResponse> {
        return http.post(`/admin/account/login-as`, { user_id: userId });
    }

    public static markTrusted(userId: string, trusted: boolean): Promise<void> {
        return http.put(`/admin/users/${userId}/${trusted ? 'trust' : 'untrust'}`);
    }

    public static deleteUser(userId: string): Promise<void> {
        return http.delete(`/admin/user/${userId}`);
    }

    public static edit(id: string, data: AccountData): Promise<void> {
        return http.put(`/account/${id}`, data);
    }
}
