export const deepSelect = (result: any) => {
    return result?.data?.data || result?.data || result;
};

export const paginationSelect = (result: any) => {
    if (result?.data?.current_page) {
        return result.data;
    } else if (result?.current_page) {
        return result;
    } else if (result?.data?.data?.current_page) {
        return result?.data?.data;
    } else {
        console.error('@ERROR, no pagination fields');
        return result;
    }
};

export const deepOptions = {
    select: deepSelect,
};
