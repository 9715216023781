import React, { useCallback } from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import http from 'react-syntax-highlighter/dist/esm/languages/hljs/http';
import { obsidian as syntaxStyles } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useQuery } from 'react-query';
import { Button, Card, Col, PageHeader, Row, Space, Spin, Typography } from 'antd';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import WebsiteApi from '@services/websites';
import { PublisherLayout } from '@components/layouts/publisher';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { deepSelect } from '@util/react-query';

SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('http', http);
interface UrlParams {
    websiteId: string;
}

type apiCard = {
    response: any;
    params: paramType[];
    requests: requestType[];
    title: string;
    description?: string;
};

type paramType = {
    name: string;
    required: boolean;
    description: string;
};

type requestType = {
    description?: string;
    http: string;
};

const params = {
    apiKey: {
        name: 'apiKey',
        required: true,
        description: 'System-generated key for access to Website API(already loaded on page).',
    },
    url: {
        name: 'url',
        required: true,
        description: 'Publicly accessible URL address where your file is stored.',
    },
    content_id: {
        name: 'content_id',
        required: true,
        description:
            'User-generated identifer for future references by user. In case a file gets recognized as a duplicate by our engine, you will be able to identify it via this value. Also, in DELETE call, this value has to be provided to server for successful deletion.',
    },
    ext: {
        name: 'ext',
        required: true,
        description: 'File extension.',
    },
    type: {
        name: 'type',
        description: 'Type of uploaded file. Accepted values are: "video", "image".',
        required: true,
    },
    website_file_id: {
        name: 'website_file_id',
        description:
            'Unique identifier of your file assigned by the system. It is sent by the server after successful "Post content for pre-check", under "id" key, or can be retrieved  in response after "Get all checked(not added to engine) files" call. This value is used for referencing your file that is about to be uploaded to engine. or deleted from the server.',
        required: true,
    },
    id_or_signature: {
        name: 'id_or_signature',
        required: true,
        description:
            'Identifier of a webhook request. Can be retrieved in "Fetch all failed webhook notifications" call. Keys "id" or "signature" can be used here.',
    },
};
export const PublisherWebsiteAPIPage: React.FC = () => {
    useProtectedRoute(ROLES.PUBLISHER);
    const { websiteId } = useParams<UrlParams>();
    const { data: website, isLoading } = useQuery(['website', websiteId], () => WebsiteApi.getWebsite(websiteId), {
        select: deepSelect,
    });

    const httpContent = useCallback(
        (method, data, endpoint) => {
            return `${method} ${
                process.env.REACT_APP_API_URL
            }/${endpoint}\nContent-Type: application/json\n${JSON.stringify(data, null, 2)}`;
        },
        [isLoading, website],
    );

    const cards: apiCard[] = [
        {
            title: 'Post content',
            description: 'To add content to the Copyknight system, you can send a POST request with the following data',
            response: {
                success: true,
                message: 'File submitted.',
            },
            params: [params.apiKey, params.url, params.content_id, params.ext, params.type],
            requests: [
                {
                    description: 'For videos:',
                    http: httpContent(
                        'POST',
                        {
                            apiKey: `${website?.api_key}`,
                            url: `${website?.base_url}/my-awesome-photo.png`,
                            content_id: '<post id in your system>',
                            ext: 'png',
                            type: 'image',
                        },
                        'publisher/content',
                    ),
                },
                {
                    description: 'For images:',
                    http: httpContent(
                        'POST',
                        {
                            apiKey: `${website?.api_key}`,
                            url: `${website?.base_url}/my-awesome-photo.png`,
                            content_id: '<post id in your system>',
                            ext: 'png',
                            type: 'image',
                        },
                        'publisher/content',
                    ),
                },
            ],
        },
        {
            title: 'Post content for pre-check',
            description:
                'To perform a content pre-check with content uploaded to Copyknight system, you can send a POST request with the following data',
            requests: [
                {
                    description: 'For videos:',
                    http: httpContent(
                        'POST',
                        {
                            apiKey: `${website?.api_key}`,
                            url: `${website?.base_url}/my-awesome-file.mp4`,
                            ext: 'mp4',
                            type: 'video',
                        },
                        'publisher/content-check',
                    ),
                },
                {
                    description: 'For images:',
                    http: httpContent(
                        'POST',
                        {
                            apiKey: `${website?.api_key}`,
                            url: `${website?.base_url}/my-awesome-photo.png`,
                            ext: 'png',
                            type: 'images',
                        },
                        'publisher/content-check',
                    ),
                },
            ],
            params: [params.apiKey, params.url, params.ext, params.type],
            response: {
                success: true,
                message: 'File submitted for check.',
                id: 2206739,
                assigned_content_id: '0dp2KjKmmhdq86eCrLFk5zALsdBXL0lC',
            },
        },
        {
            title: 'Add checked content to engine',
            description:
                'To add uploaded and checked content to Copyknight system, you can send a POST request with the following data',
            requests: [
                {
                    http: httpContent(
                        'POST',
                        {
                            apiKey: `${website?.api_key}`,
                            website_file_id: '<post id of provided file>',
                            content_id: '<post id in your system>',
                        },
                        'publisher/content-add-to-engine',
                    ),
                },
            ],
            params: [params.apiKey, params.website_file_id, params.content_id],
            response: {
                success: true,
                message: 'File successfully added to engine.',
            },
        },
        {
            title: 'Remove content',
            description:
                'To remove content from the Copyknight system, you can send a DELETE request with the following data',
            requests: [
                {
                    http: httpContent(
                        'DELETE',
                        {
                            apiKey: `${website?.api_key}`,
                            content_id: '<post id in your system>',
                        },
                        'publisher/content',
                    ),
                },
            ],
            params: [params.apiKey, params.content_id],
            response: {
                success: true,
                message: 'File deleted.',
            },
        },
        {
            title: 'Get all checked(not added to engine) files',
            description:
                'To fetch data about all checked files that are not added to Copyknight system yet, you can send a GET request with the following data',
            requests: [
                {
                    http: httpContent(
                        'GET',
                        {
                            apiKey: `${website?.api_key}`,
                        },
                        'publisher/content-get-checked',
                    ),
                },
            ],
            params: [params.apiKey],
            response: {
                success: true,
                message: 'Entries successfully retrieved.',
                entities: [
                    {
                        id: 205,
                        website_id: 94,
                        remote_url: 'https://tor-bla.com/my-awesome-file.mp4',
                        name: '18b1ef56ba1d208b7d4b38551b77a417cfefc3a9c8dcf4ac9ddf636dda6df221',
                        ext: 'mp4',
                        type: 'video',
                        url: 'publisher-videos/0/0/81/0/0/94',
                        thumbnail_url: null,
                        added_to_engine: 0,
                        imagine_engine_id: null,
                        status: 'pending',
                        created_at: '2023-01-13T17:22:58.000000Z',
                        updated_at: '2023-01-31T17:22:58.000000Z',
                        remote_content_id: '0dp2KjKmmhdq86eCrLFk5zALsdBXL0lC',
                        path:
                            'https://dev.copyknight.media/publisher-videos/0/0/81/0/0/94/18b1ef56ba1d208b7d4b38551b77a417cfefc3a9c8dcf4ac9ddf636dda6df221.mp4?e=1675277398&s=73af2eb6b7b9b211237a11eab4052b78',
                        thumbnail_path: '',
                    },
                ],
            },
        },
        {
            title: 'Delete checked(not added to engine) file',
            description:
                'To delete checked file that is not added to Copyknight system, you can send a DELETE request with the following data',
            requests: [
                {
                    http: httpContent(
                        'DELETE',
                        {
                            apiKey: `${website?.api_key}`,
                            website_file_id: `<post id of provided file>`,
                        },
                        'publisher/content-delete-temp-file',
                    ),
                },
            ],
            params: [params.apiKey, params.website_file_id],
            response: {
                success: true,
                message: 'File deleted.',
            },
        },
        {
            title: 'Request content verification',
            description:
                'To request content verification for this website, you can send a POST request with the following data',
            requests: [
                {
                    http: httpContent(
                        'POST',
                        {
                            apiKey: `${website?.api_key}`,
                        },
                        'publisher/verification',
                    ),
                },
            ],
            params: [params.apiKey],
            response: {
                success: true,
                message: 'Verification requested.',
            },
        },
        {
            title: 'Fetch all failed webhook notifications',
            description:
                'To fetch all failed webhook requests for this website, you can send a POST request with the following data',
            requests: [
                {
                    http: httpContent(
                        'POST',
                        {
                            apiKey: `${website?.api_key}`,
                        },
                        'publisher/get-failed-webhook-notifications',
                    ),
                },
            ],
            params: [params.apiKey],
            response: {
                success: true,
                message: 'Failed webhook requests successfully fetched!',
                entities: [
                    {
                        id: 26,
                        signature: 'fe8dc60f85aeb159ed646851c8fdc4494f2b57fd4280f43224e286f941c6b173',
                        call_status: 'FINAL_ATTEMPT_FAILED',
                        attempt: 5,
                        created_at: 'Apr 29, 2022, 16:59:35',
                        updated_at: 'Apr 29, 2022, 17:02:13',
                        payload: '{...}',
                        error_messages: [
                            {
                                content:
                                    '\nServer error: `POST https://eorx08bnwp7mge6.m.pipedream.net` resulted in a `500 Internal Server Error` response:\nhello world!',
                                created_at: 'Jun 15, 2022, 10:44:42',
                            },
                            {
                                content:
                                    '\nServer error: `POST https://eorx08bnwp7mge6.m.pipedream.net` resulted in a `500 Internal Server Error` response:\nhello world!',
                                created_at: 'Jun 15, 2022, 10:44:42',
                            },
                            {
                                content:
                                    '\nServer error: `POST https://eorx08bnwp7mge6.m.pipedream.net` resulted in a `500 Internal Server Error` response:\nhello world!',
                                created_at: 'Jun 15, 2022, 10:44:42',
                            },
                            {
                                content:
                                    'Server error: `POST https://eorx08bnwp7mge6.m.pipedream.net` resulted in a `500 Internal Server Error` response:\nhello world!',
                                created_at: 'Jun 15, 2022, 10:44:42',
                            },
                            {
                                content:
                                    '\nServer error: `POST https://eorx08bnwp7mge6.m.pipedream.net` resulted in a `500 	Internal Server Error` response:\nhello world!',
                                created_at: 'Jun 15, 2022, 10:44:42',
                            },
                        ],
                    },
                ],
            },
        },
        {
            title: 'Fetch single failed webhook notification',
            description:
                'To fetch single failed webhook request for this website, you can send a POST request with the following data',
            requests: [
                {
                    http: httpContent(
                        'POST',
                        {
                            apiKey: `${website?.api_key}`,
                        },
                        'publisher/get-failed-webhook-notifications/{id_or_signature}',
                    ),
                },
            ],
            params: [params.apiKey, params.id_or_signature],
            response: {
                success: true,
                message: 'Webhook request entry successfully retrieved!',
                entities: {
                    id: 27,
                    signature: '6ad5b81531ca26b184c221813308de485a65c03da785e98d2ccd93d871c2af4d',
                    call_status: 'FINAL_ATTEMPT_FAILED',
                    attempt: 5,
                    created_at: 'Apr 29, 2022, 17:03:22',
                    updated_at: 'Apr 29, 2022, 17:06:01',
                    payload: '{...}',
                    error_messages: [
                        {
                            content:
                                'Client error: `POST https://eorx08bnwp7mge6.m.pipedream.net` resulted in a `401 Unauthorized` response:\nhello world!',
                            created_at: 'Jun 15, 2022, 10:44:42',
                        },
                        {
                            content:
                                '\nClient error: `POST https://eorx08bnwp7mge6.m.pipedream.net` resulted in a `401 Unauthorized` response:\nhello world!',
                            created_at: 'Jun 15, 2022, 10:44:42',
                        },
                        {
                            content:
                                '\nClient error: `POST https://eorx08bnwp7mge6.m.pipedream.net` resulted in a `401 Unauthorized` response:\nhello world!',
                            created_at: 'Jun 15, 2022, 10:44:42',
                        },
                        {
                            content:
                                '\nClient error: `POST https://eorx08bnwp7mge6.m.pipedream.net` resulted in a `401 Unauthorized` response:\nhello world!',
                            created_at: 'Jun 15, 2022, 10:44:42',
                        },
                        {
                            content:
                                '\nClient error: `POST https://eorx08bnwp7mge6.m.pipedream.net` resulted in a `401 Unauthorized` response:\nhello world!',
                            created_at: 'Jun 15, 2022, 10:44:42',
                        },
                    ],
                },
            },
        },
    ];

    const createCard = useCallback(
        ({ title, description, requests, response, params }) => {
            return (
                <Card
                    style={{ margin: '20px 0' }}
                    key={title}
                    title={
                        <Typography.Text ellipsis strong>
                            {title}
                        </Typography.Text>
                    }
                >
                    <p>{description}</p>
                    <Row justify="start">
                        <Col style={{ paddingRight: '8px' }} span={24} md={12} lg={12} xl={12}>
                            {requests.map((request: requestType, index: number) => (
                                <React.Fragment key={title + index}>
                                    <Typography.Text strong>{request.description || 'Request:'}</Typography.Text>
                                    <p />
                                    <SyntaxHighlighter language="json" style={syntaxStyles}>
                                        {request.http}
                                    </SyntaxHighlighter>
                                </React.Fragment>
                            ))}
                        </Col>
                        <Col span={24} md={12} lg={12} xl={12}>
                            {params && (
                                <>
                                    <Typography.Text strong>Params:</Typography.Text>
                                    <p />
                                    {params.map((param: paramType) => (
                                        <div key={param.name}>
                                            <Typography.Text code>{param.name}</Typography.Text>{' '}
                                            <Typography.Text mark={param.required}>
                                                ({param.required ? 'required' : 'optional'})
                                            </Typography.Text>{' '}
                                            - <Typography.Text italic>{param.description}</Typography.Text>
                                        </div>
                                    ))}
                                    <br />
                                </>
                            )}
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            {response && (
                                <>
                                    <Typography.Text strong>Response:</Typography.Text>
                                    <p />
                                    <SyntaxHighlighter language="json" style={syntaxStyles}>
                                        {JSON.stringify(response, null, 1)}
                                    </SyntaxHighlighter>
                                </>
                            )}
                        </Col>
                    </Row>
                </Card>
            );
        },
        [isLoading, cards],
    );

    return (
        <PublisherLayout>
            <Helmet>
                <title>{`API ${website?.name ?? ''} | Copyknight`}</title>
            </Helmet>

            <PageHeader
                title={
                    <>
                        <Row>
                            <Col>
                                <Link to={ROUTES.PUBLISHER.WEBSITES.INDEX}>
                                    <Button type="link" icon={<ArrowLeftOutlined />} style={{ padding: 0 }}>
                                        Websites
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Space align="end">
                                    <Typography.Title level={4} style={{ margin: 0 }}>
                                        {website?.name}
                                    </Typography.Title>
                                </Space>
                            </Col>
                        </Row>
                    </>
                }
            >
                {isLoading ? <Spin size="large" spinning={true} /> : cards.map(createCard)}
            </PageHeader>
        </PublisherLayout>
    );
};
