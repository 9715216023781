import React from 'react';

import { GuestLayout } from '@components/layouts/guest';
import { useMutation } from 'react-query';
import AccountApi from '@services/account';
import { AxiosError } from 'axios';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import { useIsAuthenticated } from 'react-auth-kit';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDefaultRoute } from '@hooks/default-route';
import { Helmet } from 'react-helmet-async';
import { ROUTES } from '@config/routes';
import { ApiError } from '@components/api-error';

interface UrlParams {
    token: string;
}

interface ResetPasswordFormData {
    email: string;
    password: string;
    password_confirmation: string;
}

export const ResetPasswordPage: React.FC = () => {
    const { push } = useHistory();
    const isAuthenticated = useIsAuthenticated();
    const defaultRoute = useDefaultRoute();
    const { token } = useParams<UrlParams>();

    const mutation = useMutation<void, AxiosError, ResetPasswordFormData>(
        async ({ email, password, password_confirmation }) => {
            try {
                await AccountApi.resetPassword({ email, token, password, password_confirmation });
                push(ROUTES.LOGIN);
            } catch (ex) {
                throw Error('Failed to reset password');
            }
        },
    );

    if (isAuthenticated()) {
        push(defaultRoute);
        return null;
    }

    const onFinish = (data: ResetPasswordFormData) => {
        mutation.mutate(data);
    };

    return (
        <GuestLayout>
            <Helmet>
                <title>Reset your password | Copyknight</title>
            </Helmet>

            <Row justify="center">
                <Col span={24} md={12} lg={10} xl={8}>
                    <Card title="Reset your password">
                        <Form layout="vertical" name="reset-password" onFinish={onFinish}>
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{ required: true, message: 'Please enter your email!' }]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>

                            <Form.Item
                                label="New Password"
                                name="password"
                                rules={[{ required: true, message: 'Please enter your new password!' }]}
                            >
                                <Input.Password placeholder="New Password" />
                            </Form.Item>

                            <Form.Item
                                label="Confirm New Password"
                                name="password_confirmation"
                                rules={[{ required: true, message: 'The passwords do not match!' }]}
                            >
                                <Input.Password placeholder="Confirm New Password" />
                            </Form.Item>

                            {mutation.isError && (
                                <Form.Item>
                                    <ApiError error={mutation.error} />
                                </Form.Item>
                            )}

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={mutation.isLoading}
                                    style={{ width: '100%' }}
                                >
                                    Reset password
                                </Button>
                            </Form.Item>

                            <Form.Item>
                                <Row justify="space-between">
                                    <Col>
                                        Need account? <Link to={ROUTES.REGISTER}>Register here</Link>
                                    </Col>
                                    <Col>
                                        <Link to={ROUTES.FORGOT_PASSWORD}>Forgot password</Link>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </GuestLayout>
    );
};
