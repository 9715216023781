import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, Col, Input, PageHeader, Row, Spin, Form, notification, InputNumber } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';
import { ApiError } from '@components/api-error';
import { AdminLayout } from '@components/layouts/admin';
import BannerSlotsApi, { CreateBannerSlotRequest } from '@services/banner-slots';
import { deepOptions } from '@util/react-query';

interface UrlParams {
    bannerSlotId: string;
}

export const AdminBannerSlotEditPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);
    const { push } = useHistory();
    const { bannerSlotId } = useParams<UrlParams>();
    const [form] = useForm();

    const { data: bannerSlot, isLoading } = useQuery(
        ['bannerSlot', bannerSlotId],
        () => BannerSlotsApi.get(bannerSlotId),
        deepOptions,
    );

    const mutation = useMutation<void, AxiosError, CreateBannerSlotRequest>(async (data) => {
        try {
            await BannerSlotsApi.edit(bannerSlotId, data);
            notification.success({
                message: 'Banner slot updated!',
            });
            push(ROUTES.ADMIN.BANNER_SLOTS.INDEX);
        } catch (error) {
            notification.error({ message: 'Error updating banner slot' });
        }
    });

    return (
        <AdminLayout>
            <Helmet>
                <title>Edit Banner Slot | Copyknight</title>
            </Helmet>

            <PageHeader onBack={() => push(ROUTES.ADMIN.BANNER_SLOTS.INDEX)} title="Edit Banner Slot">
                <Row justify="center">
                    <Col span={24} md={12} lg={12} xl={12}>
                        <Card>
                            {isLoading ? (
                                <Spin size="large" spinning={true} />
                            ) : (
                                <Form
                                    layout="vertical"
                                    name="edit-banner-slot"
                                    form={form}
                                    onFinish={mutation.mutate}
                                    initialValues={bannerSlot}
                                >
                                    <Form.Item
                                        label="Description"
                                        name="description"
                                        rules={[
                                            { required: true, message: 'Please enter the banner slot description!' },
                                        ]}
                                    >
                                        <Input placeholder="Description" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Width"
                                        name="width"
                                        rules={[
                                            {
                                                type: 'integer',
                                                required: true,
                                                message: 'Please enter a valid width!',
                                            },
                                        ]}
                                    >
                                        <InputNumber type="number" placeholder="Width in px" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Height"
                                        name="height"
                                        rules={[
                                            {
                                                type: 'integer',
                                                required: true,
                                                message: 'Please enter a valid height!',
                                            },
                                        ]}
                                    >
                                        <InputNumber type="number" placeholder="Height in px" />
                                    </Form.Item>

                                    <Form.Item label="Resolution" shouldUpdate>
                                        {({ getFieldValue }) => (
                                            <Input
                                                readOnly
                                                value={
                                                    getFieldValue('width') && getFieldValue('height')
                                                        ? `${getFieldValue('width')}x${getFieldValue('height')}`
                                                        : ''
                                                }
                                            />
                                        )}
                                    </Form.Item>

                                    {mutation.isError && (
                                        <Form.Item>
                                            <ApiError error={mutation.error} />
                                        </Form.Item>
                                    )}

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={mutation.isLoading}
                                            style={{ width: '100%' }}
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </Card>
                    </Col>
                </Row>
            </PageHeader>
        </AdminLayout>
    );
};
