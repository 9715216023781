import React from 'react';

import { AdminLayout } from '@components/layouts/admin';
import { Helmet } from 'react-helmet-async';
import { ROUTES } from '@config/routes';
import { ChangePasswordForm } from '@components/forms/change-password-form';

export const AdminChangePasswordPage: React.FC = () => {
    return (
        <AdminLayout>
            <Helmet>
                <title>Change Password | Copyknight</title>
            </Helmet>
            <ChangePasswordForm callbackRoute={ROUTES.ADMIN.DASHBOARD} />
        </AdminLayout>
    );
};
