import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useQuery } from 'react-query';
import { Button, Input, Menu, PageHeader, Space, Table, TablePaginationConfig, Typography } from 'antd';
import { DATE } from '@config/date';
import { PAGINATION } from '@config/pagination';
import { Pagination } from 'interfaces/pagination';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { AdminLayout } from '@components/layouts/admin';
import AdminUsersApi, { User, UserType } from '@services/admin-users';
import useDebounce from '@hooks/use-debounce';
import { SearchOutlined } from '@ant-design/icons';
import useLoginAs from '@hooks/use-login-as';
import { useAuthUser } from 'react-auth-kit';
import { paginationSelect } from '@util/react-query';

interface Params {
    type?: UserType;
}

export const AdminUsersPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);
    const auth = useAuthUser();
    const user = auth() as { is_admin: boolean; is_mod: boolean };

    const isAdmin = user.is_admin;

    const { type } = useParams<Params>();

    const [search, setSearch] = useState('');
    const debouncedSearch = useDebounce(search, 500);

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data, isLoading } = useQuery(
        ['admin-users', type, pagination, debouncedSearch],
        () => AdminUsersApi.getUsers(type, debouncedSearch, pagination),
        {
            keepPreviousData: true,
            select: paginationSelect,
        },
    );

    const loginAsMutation = useLoginAs();

    useEffect(() => {
        setPagination({
            current: 1,
            pageSize: PAGINATION.pageSize,
        });
    }, [debouncedSearch]);

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: data?.total,
            pageSize: data?.per_page,
            current: data?.current_page,
        });
    }, [data]);

    const resetPagination = useCallback(() => {
        setPagination({
            current: 1,
            pageSize: PAGINATION.pageSize,
        });
    }, [setPagination]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filters: Record<string, (string | number | boolean)[] | null>,
    ) => {
        setPagination(pagination);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: function NameColumn(name: string, user: User) {
                return <Link to={ROUTES.ADMIN.USER_DETAILS(user).INDEX}>{name}</Link>;
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: 'Company',
            dataIndex: 'company',
            key: 'company',
            render: function CompanyColumn(company: string) {
                return company ?? 'N/a';
            },
        },
        {
            title: 'Date Registered',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date: string) => moment(date).format(DATE.dateFormat),
        },
        {
            title: 'Actions',
            render: function ActionsColumn(user: User) {
                const isMod = user.is_mod;
                const isAdmin = user.is_admin;
                return (
                    <Space>
                        {!isMod && !isAdmin && (
                            <Button type="default" onClick={() => loginAsMutation.mutate(String(user.id))}>
                                Login As
                            </Button>
                        )}
                    </Space>
                );
            },
        },
    ];

    return (
        <AdminLayout>
            <Helmet>
                <title>Users | Copyknight Admin</title>
            </Helmet>

            <PageHeader
                title={
                    <Typography.Title style={{ margin: 0 }} level={4}>
                        Users
                    </Typography.Title>
                }
                extra={
                    <Space>
                        <Input
                            onChange={(e) => setSearch(e.target.value)}
                            placeholder="Search by email"
                            suffix={<SearchOutlined />}
                        />
                        {isAdmin && (
                            <Link to={ROUTES.ADMIN.USERS.CREATE}>
                                <Button type="primary">Create User</Button>
                            </Link>
                        )}
                    </Space>
                }
            >
                <Menu mode="horizontal" selectedKeys={[type ?? 'users']}>
                    <Menu.Item key="users">
                        <Link onClick={resetPagination} to={ROUTES.ADMIN.USERS.INDEX}>
                            Users
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="publishers">
                        <Link onClick={resetPagination} to={ROUTES.ADMIN.USERS.PUBLISHERS}>
                            Publishers
                        </Link>
                    </Menu.Item>
                    <Menu.Item key="admins">
                        <Link onClick={resetPagination} to={ROUTES.ADMIN.USERS.ADMINS}>
                            Admins
                        </Link>
                    </Menu.Item>
                </Menu>

                <Table
                    locale={{ emptyText: 'No Users yet' }}
                    bordered
                    columns={columns}
                    pagination={pagination}
                    rowKey="id7"
                    dataSource={Array.isArray(data?.data) ? data?.data : undefined}
                    loading={isLoading}
                    onChange={handleTableChange}
                />
            </PageHeader>
        </AdminLayout>
    );
};
