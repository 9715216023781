import React, { useState } from 'react';
// eslint-disable-next-line @typescript-eslint/no-var-requires
const useKeypress = require('react-use-keypress').default;
import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useMutation, useQuery } from 'react-query';
import { Button, Col, Empty, Form, Image, Input, notification, PageHeader, Radio, Row, Tag, Typography } from 'antd';
import { Link } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { AdminLayout } from '@components/layouts/admin';
import { AxiosError } from 'axios';
import { useForm } from 'antd/lib/form/Form';
import { ApiError } from '@components/api-error';
import CopyrightEntriesApi, {
    ChangeCopyrightEntryStatusRequest,
    CopyrightEntryFile,
} from '@services/copyright-entries';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { getApiFileUrl, getApiThumbnailUrl } from '@util/api-url';
import { fallbackImageUrl } from '@util/fallback-image';
import 'video-react/dist/video-react.css';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import * as VideoReact from 'video-react';
import { deepSelect } from '@util/react-query';
const Player = VideoReact.Player;

export const AdminCopyrightEntriesFullscreenPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);

    const [form] = useForm();
    const [selectedFile, setSelectedFile] = useState<CopyrightEntryFile | undefined>();

    useKeypress(['ArrowUp', 'ArrowDown'], (event: KeyboardEvent) => {
        if (!copyrightEntryFiles) {
            return;
        }

        const numFiles = copyrightEntryFiles.files.length || 0;
        let currentIndex =
            copyrightEntryFiles.files.findIndex((file: CopyrightEntryFile) => file.id === selectedFile?.id) || 0;

        if (event.key === 'ArrowUp') {
            currentIndex = currentIndex === 0 ? numFiles - 1 : currentIndex - 1;
        } else {
            currentIndex = currentIndex === numFiles - 1 ? 0 : currentIndex + 1;
        }

        const newFile = copyrightEntryFiles.files[currentIndex];
        setSelectedFile(newFile);

        const $fileList = document.querySelector('.js-file-list');
        const $newFile = document.querySelector(`.js-file-list [data-id="${newFile.id}"]`);

        if ($fileList && $newFile) {
            $newFile.scrollIntoView({ block: 'center' });
        }
    });

    const { data, isLoading, refetch: refetchPendingCopyrightEntries } = useQuery(
        ['admin-copyright-entries-pending-fullscreen'],
        () =>
            CopyrightEntriesApi.getPendingCopyrightEntriesAdmin({
                current: 1,
                pageSize: 1,
            }),
        {
            refetchOnWindowFocus: false,
            keepPreviousData: true,
            select: deepSelect,
        },
    );

    const { data: copyrightEntryFiles } = useQuery(
        ['copyright-entry-files', data && data[0]?.id],
        () =>
            CopyrightEntriesApi.getCopyrightEntryFiles(
                String(data && data[0]?.copyright_id),
                String(data && data[0]?.id),
            ),
        {
            enabled: !!data?.length,
            refetchOnWindowFocus: false,
            onSuccess(data) {
                setSelectedFile(data.files[0]);
            },
            select: deepSelect,
        },
    );

    const copyrightEntry = data && data[0];

    const changeStatusMutation = useMutation<
        void,
        AxiosError,
        { status: 'accept' | 'deny'; approval_denied_reason?: string }
    >(async (data) => {
        try {
            if (!copyrightEntry || isLoading) {
                return;
            }

            const payload: ChangeCopyrightEntryStatusRequest = {
                approved: data.status === 'accept',
                approval_denied: data.status === 'deny',
                approval_denied_reason: data.approval_denied_reason || '',
            };

            await CopyrightEntriesApi.changeStatus(
                String(copyrightEntry?.copyright_id),
                String(copyrightEntry?.id),
                payload,
            );

            form.resetFields();
            setSelectedFile(undefined);

            await refetchPendingCopyrightEntries();
        } catch (error) {
            notification.error({
                message: 'Error changing Copyright entry status',
            });
        }
    });

    return (
        <AdminLayout showSidebar={false}>
            <Helmet>
                <title>Pending Copyright Entries | Copyknight Admin</title>
            </Helmet>

            <PageHeader
                className="admin-fullscreen-page"
                style={{ flex: 1, overflow: 'hidden', height: '100%' }}
                title={
                    <div>
                        <div>
                            <Link to={ROUTES.ADMIN.COPYRIGHT_ENTRIES.PENDING}>
                                <Button type="link" icon={<ArrowLeftOutlined />} style={{ padding: 0 }}>
                                    Pending Copyright Entries
                                </Button>
                            </Link>
                        </div>
                        <div>
                            {!!copyrightEntry && (
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <Typography.Title level={4}>
                                        <Tag>Copyright</Tag>
                                        {copyrightEntry?.copyrights?.name}
                                    </Typography.Title>
                                    <Typography.Title level={4}>
                                        <Tag>Copyright Entry</Tag>
                                        {copyrightEntry?.title}
                                    </Typography.Title>
                                    <Typography.Title level={4}>
                                        <Tag>Ownership Proof</Tag>
                                        <a
                                            href={copyrightEntry?.copyrights?.ownership_proof_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                            style={{ fontSize: '20px' }}
                                        >
                                            Open File
                                        </a>
                                    </Typography.Title>
                                </div>
                            )}
                        </div>
                    </div>
                }
                extra={
                    !!copyrightEntry ? (
                        <Row>
                            <Col>
                                <Form
                                    layout="inline"
                                    name="change-copyright-entry-status"
                                    form={form}
                                    onFinish={changeStatusMutation.mutate}
                                    initialValues={{ status: 'accept' }}
                                >
                                    <Form.Item label="Approval Status" name="status" rules={[{ required: true }]}>
                                        <Radio.Group buttonStyle="solid">
                                            <Radio.Button value="accept">Accept</Radio.Button>
                                            <Radio.Button value="deny">Deny</Radio.Button>
                                        </Radio.Group>
                                    </Form.Item>

                                    <Form.Item shouldUpdate>
                                        {({ getFieldValue }) =>
                                            getFieldValue('status') === 'deny' ? (
                                                <Form.Item label="Approval denied reason" name="approval_denied_reason">
                                                    <Input.TextArea placeholder="Reason for denial" />
                                                </Form.Item>
                                            ) : null
                                        }
                                    </Form.Item>

                                    {changeStatusMutation.isError && (
                                        <Form.Item>
                                            <ApiError error={changeStatusMutation.error} />
                                        </Form.Item>
                                    )}

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            form="change-copyright-entry-status"
                                            htmlType="submit"
                                            loading={changeStatusMutation.isLoading}
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            </Col>
                        </Row>
                    ) : null
                }
            >
                {!isLoading && !copyrightEntry && (
                    <Row justify="center">
                        <Col>
                            <Empty description="No more pending copyright entries!" />
                        </Col>
                    </Row>
                )}

                <Row gutter={20} style={{ flex: 1, overflow: 'hidden', height: '100%' }}>
                    <Col
                        span={24}
                        md={4}
                        className="file-list-container"
                        style={{
                            overflow: 'hidden',
                        }}
                    >
                        <div style={{ overflow: 'auto' }} className="js-file-list file-list">
                            {copyrightEntryFiles?.files.map((file: CopyrightEntryFile) => (
                                <Button
                                    type="text"
                                    key={file.id}
                                    data-id={file.id}
                                    onClick={() => setSelectedFile(file)}
                                >
                                    <Image
                                        style={
                                            file.id === selectedFile?.id
                                                ? {
                                                      boxShadow: '0 0 0 4px #0190fe',
                                                  }
                                                : {}
                                        }
                                        preview={false}
                                        fallback={fallbackImageUrl}
                                        src={getApiThumbnailUrl(file)}
                                    />
                                </Button>
                            ))}
                        </div>
                    </Col>
                    <Col
                        span={24}
                        md={20}
                        style={{
                            overflow: 'auto',
                            height: '100%',
                        }}
                    >
                        {selectedFile && (
                            <>
                                {selectedFile.type === 'image' && (
                                    <Image
                                        key={selectedFile.id}
                                        fallback={fallbackImageUrl}
                                        src={getApiFileUrl(selectedFile)}
                                        preview={{
                                            src: getApiFileUrl(selectedFile),
                                        }}
                                    />
                                )}

                                {selectedFile.type === 'video' && (
                                    <Player key={selectedFile.id}>
                                        <source src={selectedFile.path} />
                                    </Player>
                                )}
                            </>
                        )}
                    </Col>
                </Row>
            </PageHeader>
        </AdminLayout>
    );
};
