import NotificationsApi from '@services/notifications';
import React, { PropsWithChildren, useContext } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useMutation, UseMutationResult, useQuery } from 'react-query';
import { deepSelect } from '@util/react-query';

interface Context {
    hasUnreadNotifications: boolean;
    markAsRead: UseMutationResult<void, void, void, unknown>;
}

const NotificationsContext = React.createContext<Context | undefined>(undefined);

export const NotificationsProvider: React.FC<PropsWithChildren<unknown>> = ({
    children,
}: PropsWithChildren<unknown>) => {
    const auth = useAuthUser();
    const user = auth() as { id: number; is_admin: boolean; is_mod: boolean };

    const { data, refetch } = useQuery(['notifications-stats', user?.id], NotificationsApi.getNotificationsStats, {
        refetchInterval: 30 * 1000,
        enabled: !!user,
        select: deepSelect,
    });

    const markAsRead = useMutation<void, void, void>(async () => {
        await NotificationsApi.markAsRead();
        await refetch();
    });

    const value = {
        hasUnreadNotifications: !!data?.has_unread,
        markAsRead,
    };

    return <NotificationsContext.Provider value={value}>{children}</NotificationsContext.Provider>;
};

export const useNotificationsContext = (): Context => {
    const context = useContext(NotificationsContext);
    if (context === undefined) {
        throw new Error('useNotificationsContext must be used within NotificationsProvider');
    }
    return context;
};
