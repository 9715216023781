import React from 'react';

import { GuestLayout } from '@components/layouts/guest';
import { Alert, Card, Col, Row } from 'antd';
import { useIsAuthenticated } from 'react-auth-kit';
import { useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useDefaultRoute } from '@hooks/default-route';

export const ForgotPasswordSuccessPage: React.FC = () => {
    const { push } = useHistory();
    const isAuthenticated = useIsAuthenticated();
    const defaultRoute = useDefaultRoute();

    if (isAuthenticated()) {
        push(defaultRoute);
        return null;
    }

    return (
        <GuestLayout>
            <Helmet>
                <title>Reset your password | Copyknight</title>
            </Helmet>

            <Row justify="center">
                <Col span={24} md={12} lg={10} xl={8}>
                    <Card title="Reset your password">
                        <Alert
                            type="info"
                            message="Please check your email. We sent you a message containing a link to reset your password."
                        />
                    </Card>
                </Col>
            </Row>
        </GuestLayout>
    );
};
