import React from 'react';
import { PrivateRoute } from 'react-auth-kit';
import { BrowserRouter as Router, Switch, Route, RouteProps } from 'react-router-dom';

import { LoginPage } from '@pages/login';
import { NotFoundPage } from '@pages/not-found';
import { RegisterPage } from '@pages/register';
import { UserDashboardPage } from '@pages/user/dashboard';
import { AdminDashboardPage } from '@pages/admin/dashboard';
import { RegisterSuccessPage } from '@pages/register-success';
import { UserProfilePage } from '@pages/user/profile';
import { UserMessagesPage } from '@pages/user/messages';
import { UserChangePasswordPage } from '@pages/user/change-password';
import { ROUTES } from '@config/routes';
import { AdminMessagesPage } from '@pages/admin/messages';
import { AdminWebhooksPage } from '@pages/admin/webhooks';
import { UserCopyrightIndexPage } from '@pages/user/copyrights';
import { UserCopyrightCreatePage } from '@pages/user/copyrights/create';
import { UserCopyrightEntriesIndexPage } from '@pages/user/copyright-entries';
import { UserCopyrightEntryCreatePage } from '@pages/user/copyright-entries/create';
import { UserCopyrightGroupsIndexPage } from '@pages/user/copyright-groups';
import { UserCopyrightGroupsCreatePage } from '@pages/user/copyright-groups/create';
import { AdminCopyrightsPendingPage } from '@pages/admin/copyrights/pending';
import { AdminCopyrightEntriesPendingPage } from '@pages/admin/copyright-entries/pending';
import { AdminCopyrightEntriesFullscreenPage } from '@pages/admin/copyright-entries/fullscreen';
import { AdminChangePasswordPage } from '@pages/admin/change-password';
import { AdminUsersPage } from '@pages/admin/users';
import { AdminWebsitesPendingPage } from '@pages/admin/websites/pending';
import { AdminWebsitesIndexPage } from '@pages/admin/websites';
import { AdminUserCreatePage } from '@pages/admin/users/create';
import { AdminUserResetPasswordPage } from '@pages/admin/users/reset-password';

import { PublisherDashboardPage } from '@pages/publisher/dashboard';
import { PublisherWebsitesIndexPage } from '@pages/publisher/websites';
import { PublisherMessagesPage } from '@pages/publisher/messages';
import { PublisherProfilePage } from '@pages/publisher/profile';
import { PublisherWebsiteCreatePage } from '@pages/publisher/websites/create';
import { PublisherWebsiteEditPage } from '@pages/publisher/websites/edit';

import { UserCopyrightEntryEditPage } from '@pages/user/copyright-entries/edit';
import { AdminBannerSlotsIndexPage } from '@pages/admin/banner-slots';
import { AdminBannerSlotCreatePage } from '@pages/admin/banner-slots/create';
import { AdminBannerSlotEditPage } from '@pages/admin/banner-slots/edit';
import { AdminUserDetailsPage } from '@pages/admin/users/details';
import { AdminWebhookDetailsPage } from '@pages/admin/webhooks/details';
import { UserBannerCollectionsIndexPage } from '@pages/user/banner-collections';
import { UserBannerCollectionCreatePage } from '@pages/user/banner-collections/create';
import { UserBannerCollectionEditPage } from '@pages/user/banner-collections/edit';
import { UserCopyrightGroupsEditPage } from '@pages/user/copyright-groups/edit';
import { PrivacyPolicyPage } from '@pages/support/privacy-policy';
import { TOSPage } from '@pages/support/tos';
import { IndexPage } from '@pages/index';
import { ForgotPasswordPage } from '@pages/forgot-password';
import { ForgotPasswordSuccessPage } from '@pages/forgot-password-success';
import { ResetPasswordPage } from '@pages/reset-password';
import { PublisherNotificationsPage } from '@pages/publisher/notifications';

import { UserNotificationsPage } from '@pages/user/notifications';
import { PublisherVerificationMatchesIndexPage } from '@pages/publisher/verification-matches';
import { AdminSettingsPage } from '@pages/admin/settings';
import { AdminVerificationMatchesPage } from '@pages/admin/verification-matches';
import { AdminUserDuplicatesPage } from '@pages/admin/user-duplicates';
import {PublisherWebsiteAPIPage} from "@pages/publisher/websites/api";

const ProtectedRoute = ({ component, path }: { component: RouteProps['component']; path: string }) => (
    <PrivateRoute component={component} path={path} loginPath={'/login'} exact />
);

export const Routes: React.FC = () => {
    return (
        <Router>
            <Switch>
                {/* -------------------------------------------------------------------------- */
                /*                                Guest Routes                                */
                /* -------------------------------------------------------------------------- */}

                <Route exact path={'/'}>
                    <IndexPage />
                </Route>
                <Route path={ROUTES.LOGIN}>
                    <LoginPage />
                </Route>
                <Route path={ROUTES.ACCOUNT_CREATED}>
                    <RegisterSuccessPage />
                </Route>
                <Route path={ROUTES.REGISTER}>
                    <RegisterPage />
                </Route>
                <Route path={ROUTES.FORGOT_PASSWORD_REQUESTED}>
                    <ForgotPasswordSuccessPage />
                </Route>
                <Route path={ROUTES.FORGOT_PASSWORD}>
                    <ForgotPasswordPage />
                </Route>
                <Route path={ROUTES.RESET_PASSWORD}>
                    <ResetPasswordPage />
                </Route>
                <Route path={ROUTES.SUPPORT.PRIVACY_POLICY}>
                    <PrivacyPolicyPage />
                </Route>
                <Route path={ROUTES.SUPPORT.TOS}>
                    <TOSPage />
                </Route>

                {/* -------------------------------------------------------------------------- */
                /*                                 User Routes                                */
                /* -------------------------------------------------------------------------- */}

                <ProtectedRoute component={UserDashboardPage} path={ROUTES.USER.DASHBOARD} />
                <ProtectedRoute component={UserProfilePage} path={ROUTES.USER.ACCOUNT} />
                <ProtectedRoute component={UserMessagesPage} path={ROUTES.USER.MESSAGES} />
                <ProtectedRoute component={UserChangePasswordPage} path={ROUTES.USER.CHANGE_PASSWORD} />

                <ProtectedRoute component={UserNotificationsPage} path={ROUTES.USER.NOTIFICATIONS} />

                {/* -------------------------- Copyright Entry Pages ------------------------- */}
                <ProtectedRoute
                    component={UserCopyrightEntryCreatePage}
                    path={ROUTES.USER.COPYRIGHT_ENTRIES().CREATE}
                />
                <ProtectedRoute
                    component={UserCopyrightEntryEditPage}
                    path={ROUTES.USER.COPYRIGHT_ENTRIES().DETAILS()}
                />
                <ProtectedRoute
                    component={UserCopyrightEntriesIndexPage}
                    path={ROUTES.USER.COPYRIGHT_ENTRIES().INDEX}
                />

                {/* ------------------------- Copyright Groups Pages ------------------------- */}
                <ProtectedRoute
                    component={UserCopyrightGroupsCreatePage}
                    path={ROUTES.USER.COPYRIGHT_GROUPS().CREATE}
                />
                <ProtectedRoute
                    component={UserCopyrightGroupsEditPage}
                    path={ROUTES.USER.COPYRIGHT_GROUPS().DETAILS()}
                />
                <ProtectedRoute component={UserCopyrightGroupsIndexPage} path={ROUTES.USER.COPYRIGHT_GROUPS().INDEX} />

                {/* ------------------------- Banner Collection Pages ------------------------ */}
                <ProtectedRoute
                    component={UserBannerCollectionCreatePage}
                    path={ROUTES.USER.BANNER_COLLECTIONS().CREATE}
                />
                <ProtectedRoute
                    component={UserBannerCollectionEditPage}
                    path={ROUTES.USER.BANNER_COLLECTIONS().EDIT()}
                />
                <ProtectedRoute
                    component={UserBannerCollectionsIndexPage}
                    path={ROUTES.USER.BANNER_COLLECTIONS().INDEX}
                />

                {/* ----------------------------- Copyright Pages ---------------------------- */}
                <ProtectedRoute component={UserCopyrightCreatePage} path={ROUTES.USER.COPYRIGHTS.CREATE} />
                <ProtectedRoute component={UserCopyrightIndexPage} path={ROUTES.USER.COPYRIGHTS.INDEX} />

                {/* -------------------------------------------------------------------------- */
                /*                                Admin Routes                                */
                /* -------------------------------------------------------------------------- */}

                <ProtectedRoute component={AdminDashboardPage} path={ROUTES.ADMIN.DASHBOARD} />
                <ProtectedRoute component={AdminMessagesPage} path={ROUTES.ADMIN.MESSAGES} />
                <ProtectedRoute component={AdminWebhooksPage} path={ROUTES.ADMIN.WEBHOOKS} />
                <ProtectedRoute component={AdminWebhookDetailsPage} path={`${ROUTES.ADMIN.WEBHOOK_DETAILS().INDEX}`} />
                <ProtectedRoute component={AdminSettingsPage} path={ROUTES.ADMIN.SETTINGS} />

                <ProtectedRoute component={AdminVerificationMatchesPage} path={ROUTES.ADMIN.VERIFICATION_MATCHES} />
                <ProtectedRoute component={AdminUserDuplicatesPage} path={ROUTES.ADMIN.USER_DUPLICATES} />
                <ProtectedRoute component={AdminChangePasswordPage} path={ROUTES.ADMIN.CHANGE_PASSWORD} />

                {/* ---------------------------- Admin Copyrights ---------------------------- */}
                <ProtectedRoute component={AdminCopyrightsPendingPage} path={ROUTES.ADMIN.COPYRIGHTS.PENDING} />
                <ProtectedRoute
                    component={AdminCopyrightEntriesPendingPage}
                    path={ROUTES.ADMIN.COPYRIGHT_ENTRIES.PENDING}
                />
                <ProtectedRoute
                    component={AdminCopyrightEntriesFullscreenPage}
                    path={ROUTES.ADMIN.COPYRIGHT_ENTRIES.FULLSCREEN}
                />

                {/* ------------------------------- Admin Users ------------------------------ */}
                <ProtectedRoute component={AdminUserCreatePage} path={ROUTES.ADMIN.USERS.CREATE} />
                <ProtectedRoute component={AdminUserResetPasswordPage} path={ROUTES.ADMIN.USERS.RESET_PASSWORD} />
                <ProtectedRoute component={AdminUsersPage} path={`${ROUTES.ADMIN.USERS.INDEX}/:type?`} />

                {/* --------------------------- Admin User Details --------------------------- */}
                <ProtectedRoute component={AdminUserDetailsPage} path={`${ROUTES.ADMIN.USER_DETAILS().INDEX}`} />

                {/* ----------------------------- Admin Websites ----------------------------- */}
                <ProtectedRoute component={AdminWebsitesPendingPage} path={ROUTES.ADMIN.WEBSITES.PENDING} />
                <ProtectedRoute component={AdminWebsitesIndexPage} path={ROUTES.ADMIN.WEBSITES.INDEX} />

                {/* --------------------------- Admin Banner Slots --------------------------- */}
                <ProtectedRoute component={AdminBannerSlotCreatePage} path={ROUTES.ADMIN.BANNER_SLOTS.CREATE} />
                <ProtectedRoute component={AdminBannerSlotEditPage} path={ROUTES.ADMIN.BANNER_SLOTS.EDIT()} />
                <ProtectedRoute component={AdminBannerSlotsIndexPage} path={ROUTES.ADMIN.BANNER_SLOTS.INDEX} />

                {/* -------------------------------------------------------------------------- */
                /*                              Publisher Routes                              */
                /* -------------------------------------------------------------------------- */}

                <ProtectedRoute component={PublisherDashboardPage} path={ROUTES.PUBLISHER.DASHBOARD} />
                <ProtectedRoute component={PublisherProfilePage} path={ROUTES.PUBLISHER.ACCOUNT} />
                <ProtectedRoute component={PublisherMessagesPage} path={ROUTES.PUBLISHER.MESSAGES} />
                <ProtectedRoute component={PublisherNotificationsPage} path={ROUTES.PUBLISHER.NOTIFICATIONS} />
                <ProtectedRoute
                    component={PublisherVerificationMatchesIndexPage}
                    path={ROUTES.PUBLISHER.VERIFICATION_MATCHES}
                />

                <ProtectedRoute component={PublisherWebsiteAPIPage} path={ROUTES.PUBLISHER.API()} />

                <ProtectedRoute component={PublisherWebsiteCreatePage} path={ROUTES.PUBLISHER.WEBSITES.CREATE} />
                <ProtectedRoute component={PublisherWebsiteEditPage} path={ROUTES.PUBLISHER.WEBSITES.EDIT()} />
                <ProtectedRoute component={PublisherWebsitesIndexPage} path={ROUTES.PUBLISHER.WEBSITES.INDEX} />

                {/* -------------------------------------------------------------------------- */
                /*                                Other Routes                                */
                /* -------------------------------------------------------------------------- */}

                <Route path="*">
                    <NotFoundPage />
                </Route>
            </Switch>
        </Router>
    );
};
