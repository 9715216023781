import { getDefaultRoute } from '@hooks/default-route';
import { Alert, Button, Space } from 'antd';
import React, { PropsWithChildren, useRef } from 'react';
import { useSignIn, useSignOut } from 'react-auth-kit';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface Context {}

const LoginAsContext = React.createContext<Context | undefined>(undefined);

export const LoginAsProvider: React.FC<PropsWithChildren<unknown>> = ({ children }: PropsWithChildren<unknown>) => {
    const authLoaded = useRef(false);
    const signIn = useSignIn();
    const signOut = useSignOut();

    if (!authLoaded.current && !!sessionStorage['loginAsData']) {
        authLoaded.current = true;

        const loginAsId = JSON.parse(sessionStorage['loginAsId'] ?? '');
        const loginAsData = JSON.parse(decodeURI(sessionStorage['loginAsData']) ?? '{}');

        if (loginAsData) {
            signIn({
                token: loginAsData?.jwt,
                expiresIn: 1000 * 60,
                tokenType: 'Bearer',
                authState: {
                    ...loginAsData,
                    id: loginAsId,
                },
            });

            const defaultRoute = getDefaultRoute(loginAsData);
            if (location.pathname !== defaultRoute && !sessionStorage['loginAsRedirectedAlready']) {
                sessionStorage['loginAsRedirectedAlready'] = true;
                location.href = defaultRoute;
            }
        }
    }

    const value = {};

    const logout = () => {
        signOut();
        window.opener = self;
        window.close();
    };

    return (
        <LoginAsContext.Provider value={value}>
            {!!sessionStorage['loginAsData'] && (
                <Alert
                    banner
                    message="Browsing as user"
                    type="warning"
                    action={
                        <Space>
                            <Button size="small" type="ghost" onClick={logout}>
                                Logout
                            </Button>
                        </Space>
                    }
                />
            )}
            {children}
        </LoginAsContext.Provider>
    );
};
