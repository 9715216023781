import React, { useEffect, useRef, useState } from 'react';

import { PlusOutlined } from '@ant-design/icons';
import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { UserLayout } from '@components/layouts/user';
import { useMutation, useQuery } from 'react-query';
import {
    Button,
    Card,
    Col,
    Input,
    PageHeader,
    Row,
    Spin,
    Form,
    notification,
    Space,
    Select,
    Upload,
    Modal,
    Progress,
} from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';
import { ApiError } from '@components/api-error';
import CopyrightEntriesApi, { CreateCopyrightEntryRequest } from '@services/copyright-entries';
import CopyrightsApi from '@services/copyrights';
import { UploadFile } from 'antd/lib/upload/interface';
import { FieldInfo } from '@components/field-info';
import CopyrightGroupsApi, { CopyrightGroup } from '@services/copyright-groups';
import { getBase64 } from '@util/base64';
import Resumable from 'resumablejs';
import { useAppAuthContext } from '@context/AppAuthContext';
import { deepOptions } from '@util/react-query';

interface UrlParams {
    copyrightId: string;
}

interface FormUploadFile {
    image: string | ArrayBuffer | null;
    visible: boolean;
    title: string | undefined;
}

export const UserCopyrightEntryCreatePage: React.FC = () => {
    useProtectedRoute(ROLES.USER);

    const { copyrightId: urlCopyrightId } = useParams<UrlParams>();

    const [copyrightId, setCopyrightId] = useState(urlCopyrightId);

    useEffect(() => {
        if (copyrightId !== ':copyrightId') {
            localStorage.setItem('copyrightId', copyrightId);
        } else {
            setCopyrightId(localStorage.getItem('copyrightId') as string);
        }
    }, [setCopyrightId, copyrightId]);

    const { push } = useHistory();
    const [form] = useForm();
    const [fileList, setFileList] = useState<UploadFile<Record<string, unknown>>[]>([]);
    const [preview, setPreview] = useState<FormUploadFile>({
        image: '',
        visible: false,
        title: '',
    });
    const { getToken } = useAppAuthContext();
    const resumable = useRef(
        new Resumable({
            chunkSize: 0.4 * 1024 * 1024,
            simultaneousUploads: 3,
            testChunks: false,
            headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
        }),
    );
    const upload = (entryId: string) =>
        new Promise((resolve, reject) => {
            resumable.current.opts.target = CopyrightEntriesApi.getUploadFileUrl(copyrightId, entryId);
            resumable.current.on('complete', resolve);
            resumable.current.on('error', reject);
            resumable.current.on('fileProgress', (file) => {
                const progress = file.progress(false);
                const fileName = file.fileName;
                const fileFromList = fileList.find((f) => f.name === fileName);
                if (fileFromList) {
                    fileFromList.percent = progress * 100;
                    setFileList([...fileList]);
                }
            });
            resumable.current.upload();
        });

    const { data: copyright, isLoading: isLoadingCopyright } = useQuery(
        ['copyright', copyrightId],
        () => CopyrightsApi.getCopyright(localStorage.getItem('copyrightId') as string),
        deepOptions,
    );
    const { data: copyrightGroups, isLoading: isLoadingCopyrightGroups } = useQuery(
        ['copyright-groups', copyrightId],
        () => CopyrightGroupsApi.getGroups(Number(localStorage.getItem('copyrightId') as string), {}),
        deepOptions,
    );

    const isLoading = isLoadingCopyright || isLoadingCopyrightGroups;

    const mutation = useMutation<void, AxiosError, CreateCopyrightEntryRequest>(async (data) => {
        try {
            if (!fileList.length) {
                Modal.error({
                    title: 'Validation Error',
                    content: 'Please select one or more files!',
                });
                return;
            }

            const copyrightEntry = await CopyrightEntriesApi.create(copyrightId, data);
            await upload(String(copyrightEntry?.data?.id));
            // await Promise.all(
            //     fileList.map((file) =>
            //         CopyrightEntriesApi.uploadFile(copyrightId, String(copyrightEntry.data.id), { file }),
            //     ),
            // );

            notification.success({
                message: 'Copyright entry created!',
            });

            push(ROUTES.USER.COPYRIGHT_ENTRIES(copyright).INDEX);
        } catch (error) {
            notification.error({
                message: 'Error creating copyright entry',
            });
        }
    });

    const onPreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await (getBase64(file.originFileObj) as Promise<string>);
        }

        setPreview({
            image: (file.url || file.preview) as string,
            visible: true,
            title: file.name || file.url?.substring(file.url?.lastIndexOf('/') + 1),
        });
    };

    if (copyright && !copyright?.approved) {
        push(ROUTES.USER.COPYRIGHT_ENTRIES(copyright).INDEX);
        return null;
    }

    return (
        <UserLayout>
            <Helmet>
                <title>Create a Copyright Entry | Copyknight</title>
            </Helmet>

            <PageHeader onBack={() => push(ROUTES.USER.COPYRIGHT_ENTRIES(copyright).INDEX)} title={copyright?.name}>
                <Row justify="center">
                    <Col span={24} md={12} lg={12} xl={12}>
                        <Card title="Create a Copyright Entry">
                            {isLoading ? (
                                <Spin size="large" spinning={true} />
                            ) : (
                                <Form layout="vertical" name="create-copyright" form={form} onFinish={mutation.mutate}>
                                    <Form.Item
                                        label="Name"
                                        name="title"
                                        rules={[{ required: true, message: 'Please enter the entry title!' }]}
                                    >
                                        <Input placeholder="Name of Request" disabled={mutation.isLoading} />
                                    </Form.Item>

                                    <Form.Item
                                        label="Backurl"
                                        name="url"
                                        rules={[
                                            { required: true, type: 'url', message: 'Please enter a valid entry url!' },
                                        ]}
                                    >
                                        <Space direction="vertical" size="middle">
                                            <Input
                                                placeholder="https://homepage-url.com"
                                                disabled={mutation.isLoading}
                                            />

                                            <FieldInfo>
                                                Please provide publicly available links to the finished work(s) or
                                                work(s) in progress so we can confirm you are the copyright holder and
                                                submit any applicable images
                                            </FieldInfo>
                                        </Space>
                                    </Form.Item>

                                    <Form.Item
                                        label="Copyright Group"
                                        name="copyright_group_id"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please select one of the following options',
                                            },
                                        ]}
                                    >
                                        <Select
                                            placeholder="Copyright Group"
                                            disabled={mutation.isLoading}
                                            // defaultValue={-1}
                                        >
                                            <Select.Option key={-1} value={-1}>
                                                Create Default Group
                                            </Select.Option>
                                            {copyrightGroups?.data?.map((group: CopyrightGroup) => (
                                                <Select.Option key={group.id} value={group.id}>
                                                    {group.name}
                                                </Select.Option>
                                            ))}
                                        </Select>
                                    </Form.Item>

                                    <Form.Item>
                                        <Upload
                                            disabled={mutation.isLoading}
                                            listType="picture-card"
                                            fileList={fileList}
                                            onPreview={onPreview}
                                            beforeUpload={() => false}
                                            onChange={({ file, fileList }) => {
                                                resumable.current.addFile((file as unknown) as File);
                                                setFileList(fileList);
                                            }}
                                            itemRender={(originNode, file) => (
                                                <Space direction="vertical" size="small" style={{ width: '100%' }}>
                                                    <div style={{ height: 83 }}>{originNode}</div>
                                                    <Progress percent={file.percent} showInfo={false} />
                                                </Space>
                                            )}
                                        >
                                            {fileList.length >= 8 ? null : (
                                                <div>
                                                    <PlusOutlined />
                                                    <div style={{ marginTop: 8 }}>Select</div>
                                                </div>
                                            )}
                                        </Upload>

                                        <Modal
                                            visible={preview.visible}
                                            title={preview.title}
                                            footer={null}
                                            onCancel={() => setPreview({ ...preview, visible: false })}
                                        >
                                            <img
                                                alt={preview.title}
                                                style={{ width: '100%' }}
                                                src={preview.image as string}
                                            />
                                        </Modal>
                                    </Form.Item>

                                    {mutation.isError && (
                                        <Form.Item>
                                            <ApiError error={mutation.error} />
                                        </Form.Item>
                                    )}

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={mutation.isLoading}
                                            style={{ width: '100%' }}
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </Card>
                    </Col>
                </Row>
            </PageHeader>
        </UserLayout>
    );
};
