import { Pagination, ServerPagination } from 'interfaces/pagination';
import { User } from './admin-users';
import http from './http';

export interface Copyright {
    id: number;
    owner_id: number;
    name: string;
    source_sites: string;
    description: string;
    ownership_proof: string;
    ownership_proof_url: string;
    approved: boolean;
    approval_denied: boolean;
    approval_denied_reason: string | null;
    trusted: boolean;
    being_deleted: boolean;
    default_banner_collection_id: number;
    default_block_mode: number;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
    owner?: User;
}

export type CopyrightListResponse = ServerPagination<Copyright[]>;

export interface CreateCopyrightRequest {
    name: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateCopyrightResponse {}

export interface GetCopyrightResponse {
    data: Copyright & { data?: Copyright };
}

export interface ChangeCopyrightStatusRequest {
    approved: boolean;
    approval_denied: boolean;
    approval_denied_reason: string;
}

export type EditCopyrightRequest = Partial<Copyright>;

export default class CopyrightsApi {
    public static getCopyrights(pagination: Pagination, forUser?: string): Promise<CopyrightListResponse> {
        return http.get('/copyright/list', {
            params: {
                page: pagination.current,
                forUser,
            },
        });
    }

    public static create(data: CreateCopyrightRequest): Promise<CreateCopyrightResponse> {
        return http.post('/copyright', data);
    }

    public static getCopyright(copyrightId: string): Promise<GetCopyrightResponse & { data?: GetCopyrightResponse }> {
        return http.get(`/copyright/${copyrightId}`);
    }

    public static getPendingCopyrightsAdmin(pagination: Pagination): Promise<CopyrightListResponse> {
        return http.get('/admin/copyright/pending', {
            params: {
                page: pagination.current,
            },
        });
    }

    public static changeStatus(copyrightId: string, data: ChangeCopyrightStatusRequest): Promise<void> {
        return http.put(`/copyright/${copyrightId}`, data);
    }

    public static edit(copyrightId: string, data: EditCopyrightRequest): Promise<void> {
        return http.put(`/copyright/${copyrightId}`, data);
    }

    public static delete(copyrightId: string): Promise<void> {
        return http.delete(`/copyright/${copyrightId}`);
    }

    public static submitForReapproval(copyrightId: string): Promise<void> {
        return http.post(`/copyright/${copyrightId}/reapproval`);
    }
}
