import React from 'react';
import moment from 'moment';

import { useMutation, useQuery } from 'react-query';
import MessagesApi, { Message } from '@services/messages';
import { useAuthUser } from 'react-auth-kit';
import { Avatar, Button, Card, Comment, Form, Input, List, Spin, Tooltip } from 'antd';
import { AxiosError } from 'axios';
import { useForm } from 'antd/lib/form/Form';
import AccountApi from '@services/account';
import { getInitials } from '@util/initials';
import { deepOptions, deepSelect } from '@util/react-query';

interface SendMessageForm {
    message: string;
}

export const MessagesListWithForm: React.FC = () => {
    const [form] = useForm();
    const auth = useAuthUser();
    const user = auth() as { id: number; name: string };
    const { data: profile } = useQuery(['account', user?.id], AccountApi.me, deepOptions);
    const { isLoading, data, refetch: refetchConversations } = useQuery(
        ['conversations', user?.id],
        MessagesApi.getConversations,
        deepOptions,
    );
    const hasConversation = data?.conversations?.length !== 0;
    const firstConversation = hasConversation ? data?.conversations[0] : null;
    const { isLoading: isLoadingMessages, data: messagesData, refetch: refetchMessages } = useQuery(
        ['messages', firstConversation?.id],
        () => MessagesApi.getMessages(Number(firstConversation?.id)),
        {
            enabled: !!firstConversation?.id,
            select: deepSelect,
        },
    );

    const mutation = useMutation<void, AxiosError, SendMessageForm>(async ({ message }) => {
        try {
            if (hasConversation) {
                await MessagesApi.sendMessage(Number(firstConversation?.id), { detail: message });
            } else {
                await MessagesApi.createConversation({ detail: message });
                await refetchConversations();
            }
            await refetchMessages();
            form.resetFields();
        } catch (error) {
            await refetchMessages();
        }
    });

    const parseMessages = (messages: Message[] | undefined) => {
        return messages
            ?.map((message) => ({
                isMe: message.user_id === user?.id,
                content: message.detail,
                author: message.type === 'user' ? profile?.user?.name : 'Support',
                avatar: (
                    <Avatar style={{ background: message.type === 'user' ? '#6495ED' : '#4B0082' }}>
                        {getInitials(message.type === 'user' ? profile?.user?.name : 'Support')}
                    </Avatar>
                ),
                datetime: (
                    <Tooltip title={moment(message.created_at).format('YYYY-MM-DD HH:mm:ss')}>
                        <span>{moment(message.created_at).fromNow()}</span>
                    </Tooltip>
                ),
            }))
            .reverse();
    };

    return (
        <>
            {(isLoading || isLoadingMessages) && <Spin size="large" spinning />}

            {!(isLoading || isLoadingMessages) && (
                <>
                    <List
                        locale={{ emptyText: 'No messages yet' }}
                        className="message-list"
                        style={{ flex: 1, overflow: 'auto' }}
                        dataSource={parseMessages(messagesData?.messages)}
                        itemLayout="horizontal"
                        renderItem={(props) => (
                            <Card
                                className="message-item"
                                size="small"
                                style={{
                                    width: '60%',
                                    flex: 1,
                                    // eslint-disable-next-line react/prop-types
                                    alignSelf: props.isMe ? 'flex-end' : 'flex-start',
                                }}
                            >
                                <Comment {...props} />
                            </Card>
                        )}
                    />

                    <Comment
                        avatar={<Avatar style={{ background: '#6495ED' }}>{getInitials(profile?.user?.name)}</Avatar>}
                        content={
                            <Form form={form} onFinish={mutation.mutate}>
                                <Form.Item name="message">
                                    <Input.TextArea rows={4} placeholder="Type your message here..." />
                                </Form.Item>
                                <Form.Item>
                                    <Button htmlType="submit" loading={mutation.isLoading} type="primary">
                                        Send
                                    </Button>
                                </Form.Item>
                            </Form>
                        }
                    />
                </>
            )}
        </>
    );
};
