import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { UserLayout } from '@components/layouts/user';
import { EditProfileForm } from '@components/profile';

export const UserProfilePage: React.FC = () => {
    useProtectedRoute(ROLES.USER);

    return (
        <UserLayout>
            <Helmet>
                <title>Update profile | Copyknight</title>
            </Helmet>

            <EditProfileForm />
        </UserLayout>
    );
};
