import http from './http';

export interface AdminStats {
    totalApprovalRequests: number;
    totalDeniedCopyrightEntries: number;
    totalPendingCopyrightEntries: number;
    totalPendingCopyrights: number;
    totalPendingWebsites: number;
    data: AdminStats;
}

export interface AdminStatsResponse {
    data: AdminStats;
}

export default class AdminApi {
    public static getStats(): Promise<AdminStatsResponse> {
        return http.get('/admin/stats');
    }
}
