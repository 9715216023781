import React from 'react';

import { GuestLayout } from '@components/layouts/guest';

export const NotFoundPage: React.FC = () => {
    return (
        <GuestLayout>
            <h1>Not found</h1>
        </GuestLayout>
    );
};
