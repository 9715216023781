import React from 'react';
import { Light as SyntaxHighlighter } from 'react-syntax-highlighter';
import json from 'react-syntax-highlighter/dist/esm/languages/hljs/json';
import http from 'react-syntax-highlighter/dist/esm/languages/hljs/http';
import { obsidian as syntaxStyles } from 'react-syntax-highlighter/dist/esm/styles/hljs';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useMutation, useQuery } from 'react-query';
import {
    Button,
    Card,
    Col,
    PageHeader,
    Row,
    Spin,
    Form,
    notification,
    Space,
    Typography,
    Alert,
    Collapse,
    Tag,
} from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';
import WebsiteApi from '@services/websites';
import { ApiError } from '@components/api-error';
import { PublisherLayout } from '@components/layouts/publisher';
import { ArrowLeftOutlined, CheckCircleTwoTone, ExclamationCircleOutlined, LinkOutlined } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import { getWebsiteStatus } from '@util/website';
import { deepSelect } from '@util/react-query';

SyntaxHighlighter.registerLanguage('json', json);
SyntaxHighlighter.registerLanguage('http', http);
interface UrlParams {
    websiteId: string;
}

export const PublisherWebsiteEditPage: React.FC = () => {
    useProtectedRoute(ROLES.PUBLISHER);
    const { push } = useHistory();
    const { websiteId } = useParams<UrlParams>();
    const [form] = useForm();

    const { data: website, isLoading, refetch: refetchWebsite } = useQuery(
        ['website', websiteId],
        () => WebsiteApi.getWebsite(websiteId),
        {
            select: deepSelect,
        },
    );

    const { data: verificationStatus, refetch: getVerificationStatus } = useQuery(
        ['website-verification-status', websiteId],
        () => WebsiteApi.getVerificationStatus(websiteId),
        { refetchInterval: 3000 },
    );

    const deleteMutation = useMutation<void, AxiosError, void>(async () => {
        try {
            await WebsiteApi.delete(websiteId);
            notification.success({
                message: 'Website deleted!',
            });
            push(ROUTES.USER.COPYRIGHTS.INDEX);
        } catch (error) {
            notification.error({ message: 'Error deleting website' });
        }
    });

    const deleteWebsite = () => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete this website?',
            okType: 'danger',
            onOk() {
                deleteMutation.mutate();
            },
        });
    };

    const confirmResetApiKey = () => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to reset your API Key?',
            okText: 'Reset',
            okType: 'danger',
            onOk() {
                apiKeyMutation.mutate();
            },
        });
    };

    const confirmRequestVerification = () => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to request content verification for this website?',
            okText: 'Request',
            okType: 'danger',
            async onOk() {
                const status = await getVerificationStatus();

                if (!status.data?.running) {
                    requestMutation.mutate();
                } else {
                    notification.error({
                        message: 'Cannot start the verification process because it is already running.',
                    });
                }
            },
        });
    };

    const requestMutation = useMutation<void, AxiosError, void>(async () => {
        try {
            await WebsiteApi.requestVerification(websiteId);
            confirm({
                type: 'success',
                icon: <CheckCircleTwoTone />,
                title: 'Content verification successfully started',
                content:
                    "You will get a notification with the check results once this process is done. If there are any matches for copyrighted material, you'll be able to see them in the Verification Matches section.",
                okText: 'OK',
                okCancel: false,
            });
        } catch (error) {
            notification.error({ message: 'Error requesting website content verification' });
        }
    });

    const apiKeyMutation = useMutation<void, AxiosError, void>(async () => {
        try {
            await WebsiteApi.resetApiKey(websiteId);
            await refetchWebsite();
            notification.success({
                message: 'API key reset!',
            });
        } catch (error) {
            notification.error({ message: 'Error resetting API key' });
        }
    });

    console.log('website: ', website);

    const isWebsitePending = !website?.approved && !website?.disapproved;

    return (
        <PublisherLayout>
            <Helmet>
                <title>{`Website ${website?.name ?? ''} | Copyknight`}</title>
            </Helmet>

            <PageHeader
                title={
                    <>
                        <Row>
                            <Col>
                                <Link to={ROUTES.PUBLISHER.WEBSITES.INDEX}>
                                    <Button type="link" icon={<ArrowLeftOutlined />} style={{ padding: 0 }}>
                                        Websites
                                    </Button>
                                </Link>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Space align="end">
                                    <Typography.Title level={4} style={{ margin: 0 }}>
                                        {website?.name}
                                    </Typography.Title>
                                    {getWebsiteStatus(website)}
                                    {verificationStatus?.running && <Tag color="yellow">Verification in progress</Tag>}
                                </Space>
                            </Col>
                        </Row>
                    </>
                }
                extra={
                    <>
                        <Button type="ghost" danger onClick={deleteWebsite}>
                            Delete
                        </Button>
                    </>
                }
            >
                {isWebsitePending && (
                    <Alert
                        banner={true}
                        message="Website pending"
                        description="Your website is currently pending. Our team is working to add it to the system as soon as possible."
                        type="info"
                        showIcon
                    />
                )}

                <Row justify="start" style={{ margin: '20px 0' }}>
                    <Col span={24} md={16} lg={14} xl={12}>
                        <Row justify="start" style={{ marginBottom: 20 }}>
                            <Col span={24}>
                                <Card>
                                    <Space direction="vertical" size="small">
                                        <Typography.Text strong>Base URL</Typography.Text>
                                        <a href={website?.base_url} target="_blank" rel="noopener noreferrer">
                                            {website?.base_url}
                                        </a>
                                    </Space>
                                </Card>
                            </Col>
                        </Row>

                        <Row justify="start" style={{ marginBottom: 20 }}>
                            <Col span={24}>
                                <Card>
                                    {isLoading ? (
                                        <Spin size="large" spinning={true} />
                                    ) : (
                                        <Form
                                            layout="vertical"
                                            name="website-request-content-verification"
                                            form={form}
                                            initialValues={website}
                                        >
                                            <Form.Item>
                                                <Typography.Text strong>
                                                    Request Content Verification for this Website
                                                </Typography.Text>
                                            </Form.Item>

                                            <Form.Item>
                                                <Alert
                                                    banner={true}
                                                    message="Content Verification"
                                                    description="When you request content verification, we'll check all media you added for this website against our copyrighted content databases."
                                                    type="info"
                                                    showIcon
                                                />
                                            </Form.Item>

                                            <Form.Item style={{ textAlign: 'right' }}>
                                                <Button
                                                    disabled={isWebsitePending}
                                                    type="primary"
                                                    htmlType="button"
                                                    onClick={confirmRequestVerification}
                                                    loading={requestMutation.isLoading}
                                                >
                                                    Request
                                                </Button>
                                            </Form.Item>

                                            {requestMutation.isError && (
                                                <Form.Item>
                                                    <ApiError error={requestMutation.error} />
                                                </Form.Item>
                                            )}
                                        </Form>
                                    )}
                                </Card>
                            </Col>
                        </Row>

                        <Row align="top">
                            <Col span={24}>
                                <Card>
                                    {isLoading ? (
                                        <Spin size="large" spinning={true} />
                                    ) : (
                                        <>
                                            <Form
                                                layout="horizontal"
                                                name="website-api-key"
                                                form={form}
                                                onFinish={confirmResetApiKey}
                                                initialValues={website}
                                            >
                                                <Form.Item label="API Key" noStyle>
                                                    <Space align="baseline">
                                                        <Form.Item label="API Key" name="api_key">
                                                            <Typography.Text copyable strong>
                                                                {isWebsitePending ? 'Pending' : website?.api_key}
                                                            </Typography.Text>
                                                        </Form.Item>

                                                        <Button
                                                            disabled={isWebsitePending}
                                                            type="primary"
                                                            htmlType="submit"
                                                            size="middle"
                                                            loading={apiKeyMutation.isLoading}
                                                        >
                                                            Reset
                                                        </Button>
                                                    </Space>
                                                </Form.Item>

                                                <Form.Item>
                                                    <Alert
                                                        banner={true}
                                                        message="API Key"
                                                        description="You can use your API key to request verification for this website."
                                                        type="info"
                                                        showIcon
                                                    />
                                                </Form.Item>

                                                {apiKeyMutation.isError && (
                                                    <Form.Item>
                                                        <ApiError error={apiKeyMutation.error} />
                                                    </Form.Item>
                                                )}
                                            </Form>
                                        </>
                                    )}
                                </Card>
                            </Col>
                        </Row>
                    </Col>
                    <Col span={24} md={8} lg={10} xl={12}>
                        <Card
                            title={
                                <Link to={ROUTES.PUBLISHER.API(website)}>
                                    API Documentation <LinkOutlined />
                                </Link>
                            }
                        >
                            <Collapse defaultActiveKey={['1']}>
                                <Collapse.Panel header="Post content" key="1">
                                    <p>
                                        To add content to the Copyknight system, you can send a POST request with the
                                        following data
                                    </p>
                                    <Typography.Text strong>For videos:</Typography.Text>
                                    <SyntaxHighlighter language="json" style={syntaxStyles}>
                                        {`POST ${process.env.REACT_APP_API_URL}/publisher/content
Content-Type: application/json

${JSON.stringify(
    {
        apiKey: `${isWebsitePending ? 'Pending' : website?.api_key}`,
        url: `${website?.base_url}/my-awesome-file.mp4`,
        content_id: '<post id in your system>',
        ext: 'mp4',
        type: 'video',
    },
    null,
    2,
)}`}
                                    </SyntaxHighlighter>

                                    <Typography.Text strong>For images:</Typography.Text>
                                    <SyntaxHighlighter language="json" style={syntaxStyles}>
                                        {`POST ${process.env.REACT_APP_API_URL}/publisher/content
Content-Type: application/json

${JSON.stringify(
    {
        apiKey: `${isWebsitePending ? 'Pending' : website?.api_key}`,
        url: `${website?.base_url}/my-awesome-photo.png`,
        content_id: '<post id in your system>',
        ext: 'png',
        type: 'image',
    },
    null,
    2,
)}`}
                                    </SyntaxHighlighter>
                                </Collapse.Panel>
                                <Collapse.Panel header="Post content for pre-check" key="2">
                                    <p>
                                        To perform a content pre-check with content uploaded to Copyknight system, you
                                        can send a POST request with the following data
                                    </p>
                                    <Typography.Text strong>For videos:</Typography.Text>
                                    <SyntaxHighlighter language="json" style={syntaxStyles}>
                                        {`POST ${process.env.REACT_APP_API_URL}/publisher/content-check
Content-Type: application/json

${JSON.stringify(
    {
        apiKey: `${isWebsitePending ? 'Pending' : website?.api_key}`,
        url: `${website?.base_url}/my-awesome-file.mp4`,
        ext: 'mp4',
        type: 'video',
    },
    null,
    2,
)}
`}
                                    </SyntaxHighlighter>
                                    <Typography.Text strong>For images:</Typography.Text>
                                    <SyntaxHighlighter language="json" style={syntaxStyles}>
                                        {`POST ${process.env.REACT_APP_API_URL}/publisher/content-check
Content-Type: application/json

${JSON.stringify(
    {
        apiKey: `${isWebsitePending ? 'Pending' : website?.api_key}`,
        url: `${website?.base_url}/my-awesome-photo.png`,
        ext: 'png',
        type: 'images',
    },
    null,
    2,
)}`}
                                    </SyntaxHighlighter>
                                </Collapse.Panel>
                                <Collapse.Panel header="Add checked content to engine" key="3">
                                    <p>
                                        To add uploaded and checked content to Copyknight system, you can send a POST
                                        request with the following data
                                    </p>
                                    <SyntaxHighlighter language="json" style={syntaxStyles}>
                                        {`POST ${process.env.REACT_APP_API_URL}/publisher/content-add-to-engine
Content-Type: application/json

${JSON.stringify(
    {
        apiKey: `${isWebsitePending ? 'Pending' : website?.api_key}`,
        website_file_id: '<post id of provided file>',
        content_id: '<post id in your system>',
    },
    null,
    2,
)}`}
                                    </SyntaxHighlighter>
                                </Collapse.Panel>
                                <Collapse.Panel header="Remove content" key="4">
                                    <p>
                                        To remove content from the Copyknight system, you can send a DELETE request with
                                        the following data
                                    </p>
                                    <SyntaxHighlighter language="json" style={syntaxStyles}>
                                        {`DELETE ${process.env.REACT_APP_API_URL}/publisher/content
Content-Type: application/json

${JSON.stringify(
    {
        apiKey: `${isWebsitePending ? 'Pending' : website?.api_key}`,
        content_id: '<post id in your system>',
    },
    null,
    2,
)}`}
                                    </SyntaxHighlighter>
                                </Collapse.Panel>
                                <Collapse.Panel header="Get all checked(not added to engine) files" key="5">
                                    <p>
                                        To fetch data about all checked files that are not added to Copyknight system
                                        yet, you can send a GET request with the following data
                                    </p>
                                    <SyntaxHighlighter language="json" style={syntaxStyles}>
                                        {`GET ${process.env.REACT_APP_API_URL}/publisher/content-get-checked
Content-Type: application/json

${JSON.stringify(
    {
        apiKey: `${isWebsitePending ? 'Pending' : website?.api_key}`,
    },
    null,
    2,
)}`}
                                    </SyntaxHighlighter>
                                </Collapse.Panel>
                                <Collapse.Panel header="Delete checked(not added to engine) file" key="6">
                                    <p>
                                        To delete checked file that is not added to Copyknight system, you can send a
                                        DELETE request with the following data
                                    </p>
                                    <SyntaxHighlighter language="json" style={syntaxStyles}>
                                        {`DELETE ${process.env.REACT_APP_API_URL}/publisher/content-delete-temp-file
Content-Type: application/json

${JSON.stringify(
    {
        apiKey: `${isWebsitePending ? 'Pending' : website?.api_key}`,
        website_file_id: `<post id of provided file>`,
    },
    null,
    2,
)}`}
                                    </SyntaxHighlighter>
                                </Collapse.Panel>
                                <Collapse.Panel header="Request content verification" key="7">
                                    <p>
                                        To request content verification for this website, you can send a POST request
                                        with the following data
                                    </p>
                                    <SyntaxHighlighter language="json" style={syntaxStyles}>
                                        {`POST ${process.env.REACT_APP_API_URL}/publisher/verification
Content-Type: application/json

${JSON.stringify(
    {
        apiKey: `${isWebsitePending ? 'Pending' : website?.api_key}`,
    },
    null,
    2,
)}`}
                                    </SyntaxHighlighter>
                                </Collapse.Panel>
                                <Collapse.Panel header="Fetch all failed webhook notifications" key="8">
                                    <p>
                                        To fetch all failed webhook requests for this website, you can send a POST
                                        request with the following data
                                    </p>
                                    <SyntaxHighlighter language="json" style={syntaxStyles}>
                                        {`POST ${
                                            process.env.REACT_APP_API_URL
                                        }/publisher/get-failed-webhook-notifications
Content-Type: application/json

${JSON.stringify(
    {
        apiKey: `${isWebsitePending ? 'Pending' : website?.api_key}`,
    },
    null,
    2,
)}`}
                                    </SyntaxHighlighter>
                                </Collapse.Panel>
                                <Collapse.Panel header="Fetch single failed webhook notification" key="9">
                                    <p>
                                        To fetch single failed webhook request for this website, you can send a POST
                                        request with the following data
                                    </p>
                                    <SyntaxHighlighter language="json" style={syntaxStyles}>
                                        {`POST ${
                                            process.env.REACT_APP_API_URL
                                        }/publisher/get-failed-webhook-notifications/{id_or_signature}
Content-Type: application/json

${JSON.stringify(
    {
        apiKey: `${isWebsitePending ? 'Pending' : website?.api_key}`,
    },
    null,
    2,
)}`}
                                    </SyntaxHighlighter>
                                </Collapse.Panel>
                            </Collapse>
                        </Card>
                    </Col>
                </Row>
            </PageHeader>
        </PublisherLayout>
    );
};
