import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { UserLayout } from '@components/layouts/user';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import {
    Button,
    Card,
    Col,
    Input,
    PageHeader,
    Row,
    Spin,
    Form,
    notification,
    Space,
    Typography,
    Select,
    Result,
} from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';
import CopyrightsApi from '@services/copyrights';
import { ApiError } from '@components/api-error';
import { FieldInfo } from '@components/field-info';
import CopyrightGroupsApi, { CopyrightGroupAction, CreateCopyrightGroupRequest } from '@services/copyright-groups';
import BannerCollectionsApi, { BannerCollection } from '@services/banner-collections';
import { Pagination } from 'interfaces/pagination';
import { PAGINATION } from '@config/pagination';
import { deepOptions, paginationSelect } from '@util/react-query';

interface UrlParams {
    copyrightId: string;
    groupId: string;
}

export const UserCopyrightGroupsEditPage: React.FC = () => {
    useProtectedRoute(ROLES.USER);
    const { push } = useHistory();
    const [form] = useForm();
    const { copyrightId: urlCopyrightId, groupId } = useParams<UrlParams>();

    const [copyrightId, setCopyrightId] = useState(urlCopyrightId);

    if (copyrightId !== ':copyrightId') {
        localStorage.setItem('copyrightId', copyrightId);
    } else {
        setCopyrightId(localStorage.getItem('copyrightId') as string);
    }

    const queryClient = useQueryClient();

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data: copyright } = useQuery(
        ['copyright', copyrightId],
        () => CopyrightsApi.getCopyright(localStorage.getItem('copyrightId') as string),
        deepOptions,
    );

    const { data: copyrightGroup, isLoading, remove } = useQuery(
        ['copyright-group', groupId],
        () => CopyrightGroupsApi.get(localStorage.getItem('copyrightId') as string, groupId),
        deepOptions,
    );

    const { data: bannerCollections } = useQuery(
        ['copyright-banner-collections', copyrightId],
        () => BannerCollectionsApi.getAll(copyrightId, pagination),
        { keepPreviousData: true, select: paginationSelect },
    );

    const mutation = useMutation<void, AxiosError, CreateCopyrightGroupRequest>(async (data) => {
        try {
            let banner_collection_id = undefined;

            if (data.action === CopyrightGroupAction.BANNER) {
                banner_collection_id = data.banner_collection_id || copyrightGroup?.data.copyright_banner_collection_id;
            }

            await CopyrightGroupsApi.update(copyrightId, groupId, {
                ...data,
                banner_collection_id,
            });
            remove();
            queryClient.invalidateQueries(['my-copyright-groups', copyrightId]);
            notification.success({
                message: 'Copyright group updated!',
            });
            push(ROUTES.USER.COPYRIGHT_GROUPS(copyright).INDEX);
        } catch (error) {
            notification.error({
                message: 'Error updating copyright group',
            });
        }
    });

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: bannerCollections?.total,
            pageSize: bannerCollections?.per_page,
            current: bannerCollections?.current_page,
        });
    }, [bannerCollections]);

    if (copyright && !copyright?.approved) {
        push(ROUTES.USER.COPYRIGHT_ENTRIES(copyright).INDEX);
        return null;
    }

    return (
        <UserLayout>
            <Helmet>
                <title>Edit Copyright Group | Copyknight</title>
            </Helmet>

            <PageHeader
                onBack={() => push(ROUTES.USER.COPYRIGHT_GROUPS(copyright).INDEX)}
                title={
                    <Space direction="vertical">
                        <Typography.Text>Copyright Groups</Typography.Text>
                    </Space>
                }
            >
                <Typography.Title level={3}>{copyright?.name}</Typography.Title>
                <Row justify="center">
                    <Col span={24} md={12} lg={12} xl={12}>
                        <Card title="Edit Copyright Group">
                            {isLoading ? (
                                <Spin size="large" spinning={true} />
                            ) : (
                                <Form
                                    layout="vertical"
                                    name="create-copyright"
                                    form={form}
                                    onFinish={mutation.mutate}
                                    initialValues={copyrightGroup}
                                >
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please enter the group name!' }]}
                                    >
                                        <Input placeholder="Name" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Action"
                                        name="action"
                                        rules={[{ required: true, message: 'Please select the action!' }]}
                                    >
                                        <Space direction="vertical" size="middle">
                                            <Select
                                                placeholder="Action"
                                                defaultValue={copyrightGroup?.action}
                                                onChange={(action) => form.setFieldsValue({ action })}
                                            >
                                                <Select.Option value={CopyrightGroupAction.NONE}>None</Select.Option>
                                                <Select.Option value={CopyrightGroupAction.BANNER}>
                                                    Banner
                                                </Select.Option>
                                                <Select.Option value={CopyrightGroupAction.BLOCK}>Block</Select.Option>
                                            </Select>
                                            <FieldInfo>
                                                Action determines what happens when copyrighted work is discovered on a
                                                website.
                                            </FieldInfo>
                                        </Space>
                                    </Form.Item>

                                    <Form.Item name="banner_collection_id" shouldUpdate noStyle></Form.Item>
                                    <Form.Item shouldUpdate noStyle>
                                        {({ getFieldValue }) =>
                                            getFieldValue('action') === CopyrightGroupAction.BANNER ? (
                                                <Form.Item
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please select the banner collection!',
                                                        },
                                                    ]}
                                                    name="banner_collection_id"
                                                    label="Banner Collection"
                                                >
                                                    {!bannerCollections?.data.length && (
                                                        <Space direction="vertical">
                                                            <Result
                                                                status="warning"
                                                                subTitle={
                                                                    <Typography.Text type="secondary">
                                                                        You don&apos;t have any banner collections for
                                                                        this copyright yet.
                                                                    </Typography.Text>
                                                                }
                                                                extra={
                                                                    <Link
                                                                        to={
                                                                            ROUTES.USER.BANNER_COLLECTIONS(copyright)
                                                                                .CREATE
                                                                        }
                                                                    >
                                                                        <Button>Create Banner Collection</Button>
                                                                    </Link>
                                                                }
                                                            />
                                                        </Space>
                                                    )}

                                                    {!!bannerCollections?.data.length && (
                                                        <Select
                                                            placeholder="Banner Collection"
                                                            defaultValue={
                                                                copyrightGroup?.copyright_banner_collection_id
                                                            }
                                                            onChange={(banner_collection_id) =>
                                                                form.setFieldsValue({ banner_collection_id })
                                                            }
                                                        >
                                                            {bannerCollections?.data.map(
                                                                (bannerCollection: BannerCollection) => (
                                                                    <Select.Option
                                                                        value={bannerCollection.id}
                                                                        key={bannerCollection.id}
                                                                    >
                                                                        {bannerCollection.name}
                                                                    </Select.Option>
                                                                ),
                                                            )}
                                                        </Select>
                                                    )}
                                                </Form.Item>
                                            ) : null
                                        }
                                    </Form.Item>

                                    {mutation.isError && (
                                        <Form.Item>
                                            <ApiError error={mutation.error} />
                                        </Form.Item>
                                    )}

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={mutation.isLoading}
                                            style={{ width: '100%' }}
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </Card>
                    </Col>
                </Row>
            </PageHeader>
        </UserLayout>
    );
};
