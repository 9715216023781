import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { UserLayout } from '@components/layouts/user';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { Button, Card, Col, Input, PageHeader, Row, Spin, Form, notification, Space, Typography, Upload } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';
import CopyrightsApi from '@services/copyrights';
import { ApiError } from '@components/api-error';
import { CreateCopyrightGroupRequest } from '@services/copyright-groups';
import BannerCollectionsApi from '@services/banner-collections';
import { Pagination } from 'interfaces/pagination';
import { PAGINATION } from '@config/pagination';
import BannerSlotsApi, { BannerSlot } from '@services/banner-slots';
import Modal from 'antd/lib/modal/Modal';
import { UploadFile } from 'antd/lib/upload/interface';
import { PlusOutlined } from '@ant-design/icons';
import { getBase64 } from '@util/base64';
import { getApiBannerThumbUrl, getApiBannerUrl } from '@util/api-url';
import { deepOptions, paginationSelect } from '@util/react-query';

interface UrlParams {
    copyrightId: string;
    bannerCollectionId: string;
}

interface FormUploadFile {
    image: string | ArrayBuffer | null;
    visible: boolean;
    title: string | undefined;
}

interface BannerUploadFile {
    file: UploadFile;
    bannerSlot: BannerSlot;
}

export const UserBannerCollectionEditPage: React.FC = () => {
    useProtectedRoute(ROLES.USER);
    const queryClient = useQueryClient();
    const { push } = useHistory();
    const [form] = useForm();

    const { copyrightId: urlCopyrightId, bannerCollectionId } = useParams<UrlParams>();

    const [copyrightId, setCopyrightId] = useState(urlCopyrightId);

    if (copyrightId !== ':copyrightId') {
        localStorage.setItem('copyrightId', copyrightId);
    } else {
        setCopyrightId(localStorage.getItem('copyrightId') as string);
    }

    const [bannerList, setBannerList] = useState<Record<string, BannerUploadFile>>({});
    const [preview, setPreview] = useState<FormUploadFile>({
        image: '',
        visible: false,
        title: '',
    });

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data: copyright } = useQuery(
        ['copyright', copyrightId],
        () => CopyrightsApi.getCopyright(copyrightId),
        deepOptions,
    );
    const { data: bannerCollection, isLoading } = useQuery(
        ['bannerCollection', bannerCollectionId],
        () => BannerCollectionsApi.get(localStorage.getItem('copyrightId') as string, bannerCollectionId),
        deepOptions,
    );

    const { data: bannerSlots } = useQuery(['banner-slots'], () => BannerSlotsApi.getAll(pagination), {
        keepPreviousData: true,
        select: paginationSelect,
    });

    const mutation = useMutation<void, AxiosError, CreateCopyrightGroupRequest>(async (data) => {
        try {
            const numberOfBanners = Object.values(bannerList)
                .map((b) => b.file)
                .filter(Boolean).length;

            if (numberOfBanners !== bannerSlots?.total) {
                notification.error({
                    message: 'Please select all banner slots',
                });
                return;
            }

            await BannerCollectionsApi.update(copyrightId, bannerCollectionId, data);

            if (!bannerSlots || !bannerCollectionId) {
                return;
            }

            await Promise.all(
                bannerSlots.data.map((bannerSlot) => {
                    const file = bannerList[bannerSlot.id].file;

                    if (file?.size > 0) {
                        return BannerCollectionsApi.saveBanner(copyrightId, String(bannerCollectionId), {
                            banner_slot: bannerSlot.id,
                            file: bannerList[bannerSlot.id].file,
                        });
                    }
                }),
            );
            queryClient.invalidateQueries(['bannerCollection', bannerCollectionId]);
            notification.success({
                message: 'Banner collection saved!',
            });
            push(ROUTES.USER.BANNER_COLLECTIONS(copyright).INDEX);
        } catch (error) {
            console.log({ error });

            notification.error({
                message: 'Error saving banner collection',
            });
        }
    });

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: bannerSlots?.total,
            pageSize: bannerSlots?.per_page,
            current: bannerSlots?.current_page,
        });
    }, [bannerSlots]);

    useEffect(() => {
        if (!bannerCollection || !bannerSlots?.data) {
            return;
        }

        const banners = bannerCollection?.copyright_banners;
        const bannersWithSlots: Record<string, BannerUploadFile> = {};
        banners.forEach((banner) => {
            const bannerSlot: BannerSlot | undefined = bannerSlots?.data.find(
                (slot) => slot?.id === banner?.banner_slot,
            );
            if (!bannerSlot) {
                return;
            }

            bannersWithSlots[String(bannerSlot?.id)] = {
                file: {
                    uid: String(banner?.id),
                    name: bannerSlot.resolution,
                    status: 'done',
                    size: 0,
                    type: '',
                    url: getApiBannerUrl(banner),
                    thumbUrl: getApiBannerThumbUrl(banner),
                },
                bannerSlot,
            };
        });
        setBannerList(bannersWithSlots);
    }, [bannerCollection, bannerSlots]);

    const onPreview = async (file: UploadFile) => {
        if (!file.url && !file.preview) {
            file.preview = await (getBase64(file.originFileObj) as Promise<string>);
        }

        setPreview({
            image: (file.url || file.preview) as string,
            visible: true,
            title: file.name || file.url?.substring(file.url?.lastIndexOf('/') + 1),
        });
    };

    if (copyright && !copyright?.approved) {
        push(ROUTES.USER.COPYRIGHT_ENTRIES(copyright).INDEX);
        return null;
    }

    return (
        <UserLayout>
            <Helmet>
                <title>Edit Banner Collection | Copyknight</title>
            </Helmet>

            <PageHeader
                onBack={() => push(ROUTES.USER.BANNER_COLLECTIONS(copyright).INDEX)}
                title={
                    <Space direction="vertical">
                        <Typography.Text>Banner Collections</Typography.Text>
                    </Space>
                }
            >
                <Typography.Title level={3}>{copyright?.name}</Typography.Title>
                <Row justify="center" className="banner-edit-page">
                    <Col span={24} md={12} lg={12} xl={12}>
                        <Card title="Edit Banner Collection">
                            {isLoading ? (
                                <Spin size="large" spinning={true} />
                            ) : (
                                <Form
                                    layout="vertical"
                                    name="create-banner-collection"
                                    form={form}
                                    onFinish={mutation.mutate}
                                    initialValues={bannerCollection}
                                >
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please enter the collection name!' }]}
                                    >
                                        <Input placeholder="Name" />
                                    </Form.Item>

                                    <Form.Item>
                                        <Typography.Title level={5}>Add banners</Typography.Title>
                                    </Form.Item>

                                    {bannerSlots?.data.map((bannerSlot) => (
                                        <Form.Item key={bannerSlot.id}>
                                            <Upload
                                                listType="picture"
                                                fileList={
                                                    bannerList[bannerSlot.id]?.file
                                                        ? [bannerList[bannerSlot.id].file]
                                                        : undefined
                                                }
                                                multiple={false}
                                                maxCount={1}
                                                onPreview={onPreview}
                                                beforeUpload={() => false}
                                                onChange={({ fileList }) =>
                                                    setBannerList({
                                                        ...bannerList,
                                                        [bannerSlot.id]: { bannerSlot, file: fileList[0] },
                                                    })
                                                }
                                            >
                                                {!!bannerList.length ? null : (
                                                    <Button>
                                                        <PlusOutlined />
                                                        Select size {bannerSlot.resolution}
                                                    </Button>
                                                )}
                                            </Upload>
                                        </Form.Item>
                                    ))}

                                    {mutation.isError && (
                                        <Form.Item>
                                            <ApiError error={mutation.error} />
                                        </Form.Item>
                                    )}

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={mutation.isLoading}
                                            style={{ width: '100%' }}
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </Card>

                        <Modal
                            visible={preview.visible}
                            title={preview.title}
                            footer={null}
                            onCancel={() => setPreview({ ...preview, visible: false })}
                        >
                            <img alt={preview.title} style={{ width: '100%' }} src={preview.image as string} />
                        </Modal>
                    </Col>
                </Row>
            </PageHeader>
        </UserLayout>
    );
};
