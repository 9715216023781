import React, { PropsWithChildren } from 'react';
import { Space, Typography } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';

export function FieldInfo({ children }: PropsWithChildren<unknown>): JSX.Element {
    return (
        <Space align="start">
            <InfoCircleOutlined />
            <Typography.Text type="secondary">{children}</Typography.Text>
        </Space>
    );
}
