import React, { useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { UserLayout } from '@components/layouts/user';
import { useMutation } from 'react-query';
import { InboxOutlined } from '@ant-design/icons';
import {
    Button,
    Card,
    Col,
    Input,
    PageHeader,
    Row,
    Spin,
    Form,
    notification,
    Upload,
    message,
    UploadProps,
} from 'antd';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';
import CopyrightsApi, { CreateCopyrightRequest } from '@services/copyrights';
import { useAppAuthContext } from '@context/AppAuthContext';
import { ApiError } from '@components/api-error';
import { UploadFile } from 'antd/lib/upload/interface';

export const UserCopyrightCreatePage: React.FC = () => {
    useProtectedRoute(ROLES.USER);
    const { push } = useHistory();
    const [form] = useForm();
    const { getToken } = useAppAuthContext();
    const { TextArea } = Input;
    const isLoading = false;
    const [fileList, setFileList] = useState<UploadFile<Record<string, unknown>>[]>([]);

    const props: UploadProps = {
        name: 'ownership_proof',
        action: `${process.env.REACT_APP_API_URL}/copyright`,
        headers: { Accept: 'application/json', Authorization: `Bearer ${getToken()}` },
        onRemove: () => {
            setFileList([]);
        },
        beforeUpload: (file: UploadFile) => {
            setFileList([file]);
            return false;
        },
        onChange(info) {
            if (info.file.status !== 'uploading') {
                setFileList([info.file]);
            }
            if (info.file.status === 'done') {
                message.success(`${info.file.name} file uploaded successfully`);
            } else if (info.file.status === 'error') {
                message.error(`${info.file.name} file upload failed.`);
            }
        },
    };

    const mutation = useMutation<void, AxiosError, CreateCopyrightRequest>(async (data) => {
        const formData = new FormData();
        formData.append('name', data.name);
        formData.append('source_sites', data['source_sites']);
        if (fileList[0] instanceof File) {
            formData.append('ownership_proof', fileList[0]);
            try {
                await CopyrightsApi.create(formData);
                notification.success({
                    message: 'Copyright created!',
                });
                push(ROUTES.USER.COPYRIGHTS.INDEX);
            } catch (error) {
                notification.error({ message: 'Error creating copyright' });
            }
        } else {
            notification.error({ message: 'File is required' });
        }
    });
    return (
        <UserLayout>
            <Helmet>
                <title>Create a Copyright | Copyknight</title>
            </Helmet>

            <PageHeader onBack={() => push(ROUTES.USER.COPYRIGHTS.INDEX)} title="Create a Copyright">
                <Row justify="center">
                    <Col span={24} md={12} lg={12} xl={12}>
                        <Card>
                            {isLoading ? (
                                <Spin size="large" spinning={true} />
                            ) : (
                                <Form layout="vertical" name="create-copyright" form={form} onFinish={mutation.mutate}>
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        maxLength={255}
                                        rules={[{ required: true, message: 'Please enter the name of request!' }]}
                                    >
                                        <Input placeholder="Name of Request" />
                                    </Form.Item>
                                    <Form.Item
                                        label="Source"
                                        name="source_sites"
                                        rules={[{ required: true, message: 'Please enter the source of request!' }]}
                                    >
                                        <TextArea rows={1} placeholder="Please enter the source of request" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Source"
                                        name="description"
                                        rules={[
                                            {
                                                required: false,
                                                message: 'Please enter the description of request!',
                                            },
                                        ]}
                                    >
                                        <TextArea rows={5} placeholder="Please enter the description of request" />
                                    </Form.Item>
                                    <Form.Item
                                        name="ownership_proof"
                                        rules={[
                                            {
                                                required: true,
                                                message: 'Please enter the proof of request!',
                                            },
                                        ]}
                                    >
                                        <Upload.Dragger maxCount={1} {...props}>
                                            <p className="ant-upload-drag-icon">
                                                <InboxOutlined />
                                            </p>
                                            <p
                                                className="ant-upload-text"
                                                style={{ textAlign: 'center', fontWeight: 'bold' }}
                                            >
                                                Upload file for ownership verification such as screenshot of your logged
                                                into one of the source sites that you specified above.
                                            </p>
                                            <p
                                                className="ant-upload-text"
                                                style={{ textAlign: 'center', fontWeight: 'bold' }}
                                            >
                                                This is needed to verify you are artist/copyright owner.
                                            </p>
                                        </Upload.Dragger>
                                    </Form.Item>
                                    {mutation.isError && (
                                        <Form.Item>
                                            <ApiError error={mutation.error} />
                                        </Form.Item>
                                    )}
                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={mutation.isLoading}
                                            style={{ width: '100%' }}
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </Card>
                    </Col>
                </Row>
            </PageHeader>
        </UserLayout>
    );
};
