import React from 'react';
import { Tag } from 'antd';
import { Copyright } from '@services/copyrights';

export function getCopyrightStatus(copyright?: Copyright): JSX.Element {
    if (!copyright) {
        return <Tag>N/a</Tag>;
    }

    if (copyright.approved) {
        return <Tag color="green">Accepted</Tag>;
    }

    if (copyright.approval_denied) {
        return <Tag color="red">Denied</Tag>;
    }

    return <Tag color="yellow">Pending</Tag>;
}
