import { Pagination, ServerPagination } from 'interfaces/pagination';
import { BannerCollection } from './banner-collections';
import http from './http';

export enum CopyrightGroupAction {
    NONE = 'none',
    BLOCK = 'block',
    BANNER = 'banner',
}

export interface CopyrightGroup {
    id: number;
    name: string;
    action: CopyrightGroupAction;
    copyright_banner_collection_id?: number;
    copyright_banner_collections?: BannerCollection;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export type CopyrightGroupListResponse = ServerPagination<CopyrightGroup[]>;

export interface CreateCopyrightGroupRequest {
    name: string;
    copyright_id: number;
    action: CopyrightGroupAction;
    banner_collection_id?: number;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateCopyrightGroupResponse {}

export interface GetCopyrightGroupResponse {
    data: CopyrightGroup;
}

export default class CopyrightGroupsApi {
    public static getGroups(
        copyrightId: number,
        pagination: Pagination,
    ): Promise<CopyrightGroupListResponse & { data?: CopyrightGroupListResponse }> {
        return http.get(`/copyright/${copyrightId}/groups`, {
            params: {
                page: pagination.current,
            },
        });
    }

    public static create(
        copyrightId: number,
        data: CreateCopyrightGroupRequest,
    ): Promise<CreateCopyrightGroupResponse> {
        return http.post(`/copyright/${copyrightId}/groups`, data);
    }

    public static update(
        copyrightId: string,
        groupId: string,
        data: CreateCopyrightGroupRequest,
    ): Promise<CreateCopyrightGroupResponse> {
        return http.put(`/copyright/${copyrightId}/groups/${groupId}`, data);
    }

    public static delete(copyrightId: string, groupId: string): Promise<void> {
        return http.delete(`/copyright/${copyrightId}/groups/${groupId}`);
    }

    public static get(
        copyrightId: string,
        groupId: string,
    ): Promise<GetCopyrightGroupResponse & { data?: GetCopyrightGroupResponse }> {
        return http.get(`/copyright/${copyrightId}/groups/${groupId}`);
    }
}
