import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { MessagesListWithForm } from '@components/messages';
import { PublisherLayout } from '@components/layouts/publisher';

export const PublisherMessagesPage: React.FC = () => {
    useProtectedRoute(ROLES.PUBLISHER);

    return (
        <PublisherLayout>
            <Helmet>
                <title>Messages | Copyknight</title>
            </Helmet>

            <h1>Messages</h1>

            <MessagesListWithForm />
        </PublisherLayout>
    );
};
