import React, { useEffect, useState } from 'react';
import moment from 'moment';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useMutation, useQuery } from 'react-query';
import { Button, Menu, notification, PageHeader, Table, TablePaginationConfig, Tooltip } from 'antd';
import { DATE } from '@config/date';
import { PAGINATION } from '@config/pagination';
import { Pagination } from 'interfaces/pagination';
import { Link, useRouteMatch } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { AdminLayout } from '@components/layouts/admin';
import WebsiteApi, { Website } from '@services/websites';
import confirm from 'antd/lib/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import {paginationSelect} from "@util/react-query";

export const AdminWebsitesIndexPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);

    const match = useRouteMatch();

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data, isLoading, refetch: refetchWebsiteList } = useQuery(
        ['admin-websites', pagination],
        () => WebsiteApi.getWebsitesAdmin(pagination),
        { keepPreviousData: true, select: paginationSelect },
    );

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: data?.total,
            pageSize: data?.per_page,
            current: data?.current_page,
        });
    }, [data]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filters: Record<string, (string | number | boolean)[] | null>,
    ) => {
        setPagination(pagination);
    };

    const startEngineMutation = useMutation<void, AxiosError, Website>(async (website) => {
        try {
            await WebsiteApi.startEngine(String(website.id));
            setTimeout(() => {
                refetchWebsiteList();
            }, 3000);
            notification.success({
                message: 'Website copyright engine starting...',
            });
        } catch (error) {
            notification.error({
                message: 'Error starting copyright engine',
            });
        }
    });

    const stopEngineMutation = useMutation<void, AxiosError, Website>(async (website) => {
        try {
            await WebsiteApi.stopEngine(String(website.id));
            await refetchWebsiteList();
            notification.success({
                message: 'Website copyright engine stopping...',
            });
        } catch (error) {
            notification.error({
                message: 'Error stopping copyright engine',
            });
        }
    });

    const openStartEngineModal = (website: Website) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to start the copyright engine for this website?',
            okText: 'Start',
            okType: 'danger',
            onOk() {
                startEngineMutation.mutate(website);
            },
        });
    };

    const openStopEngineModal = (website: Website) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to stop the copyright engine for this website?',
            okText: 'Stop',
            okType: 'danger',
            onOk() {
                stopEngineMutation.mutate(website);
            },
        });
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            render: function NameColumn(name: string, website: Website) {
                return (
                    <a target="_blank" rel="noreferrer noopener" href={website.base_url}>
                        {name}
                    </a>
                );
            },
        },
        {
            title: 'Owner',
            render: (website: Website) => website.owner?.name,
        },
        {
            title: 'Approved',
            render: (website: Website) => (website.approved ? 'Yes' : 'No'),
        },
        {
            title: 'Date Submitted',
            dataIndex: 'created_at',
            key: 'created_at',
            render: (date: string) => moment(date).format(DATE.dateFormat),
        },
        {
            title: 'Actions',
            render: function ActionsColumn(website: Website) {
                return (
                    <>
                        {!website.website_engine ? (
                            <Tooltip title="Start a new copyright engine for this website">
                                <Button
                                    loading={
                                        startEngineMutation.isLoading &&
                                        startEngineMutation.variables?.id === website.id
                                    }
                                    onClick={() => openStartEngineModal(website)}
                                >
                                    Start Engine
                                </Button>
                            </Tooltip>
                        ) : (
                            <Tooltip title="Stop the copyright engine for this website">
                                <Button
                                    type="ghost"
                                    danger
                                    loading={
                                        stopEngineMutation.isLoading && stopEngineMutation.variables?.id === website.id
                                    }
                                    onClick={() => openStopEngineModal(website)}
                                >
                                    Stop Engine
                                </Button>
                            </Tooltip>
                        )}
                    </>
                );
            },
        },
    ];

    return (
        <AdminLayout>
            <Helmet>
                <title>Websites | Copyknight Admin</title>
            </Helmet>

            <PageHeader title="Websites">
                <Menu mode="horizontal" selectedKeys={[match.path]}>
                    <Menu.Item key={ROUTES.ADMIN.WEBSITES.PENDING}>
                        <Link to={ROUTES.ADMIN.WEBSITES.PENDING}>Pending</Link>
                    </Menu.Item>
                    <Menu.Item key={ROUTES.ADMIN.WEBSITES.INDEX}>
                        <Link to={ROUTES.ADMIN.WEBSITES.INDEX}>List</Link>
                    </Menu.Item>
                </Menu>
                <Table
                    locale={{ emptyText: 'No Websites' }}
                    bordered
                    columns={columns}
                    pagination={pagination}
                    rowKey="id8"
                    dataSource={data?.data}
                    loading={isLoading}
                    onChange={handleTableChange}
                />
            </PageHeader>
        </AdminLayout>
    );
};
