import React, { useState } from 'react';

import { GuestLayout } from '@components/layouts/guest';
import { useMutation } from 'react-query';
import AccountApi, { AccountData } from '@services/account';
import { AxiosError } from 'axios';
import { Button, Card, Checkbox, Col, Form, Input, Radio, notification, Row } from 'antd';
import { useIsAuthenticated } from 'react-auth-kit';
import { Link, useHistory } from 'react-router-dom';
import { useDefaultRoute } from '@hooks/default-route';
import { Helmet } from 'react-helmet-async';
import { ROUTES } from '@config/routes';
import { ApiError } from '@components/api-error';
import { useForm } from 'antd/lib/form/Form';

interface RegisterFormData {
    name: string;
    email: string;
    password: string;
    password_confirmation: string;
    role: string;
    company: string;
    address: string;
    telephone: string;
    homepage: string;
}

export const RegisterPage: React.FC = () => {
    const { push } = useHistory();
    const isAuthenticated = useIsAuthenticated();
    const defaultRoute = useDefaultRoute();
    const [form] = useForm();
    const [isCompany, setIsCompany] = useState(false);
    const [isPublisher, setIsPublisher] = useState(false);
    const mutation = useMutation<void, AxiosError, AccountData>(
        async ({ name, email, password, password_confirmation, role, company, address, telephone, homepage }) => {
            await AccountApi.register({
                name,
                email,
                password,
                password_confirmation,
                role,
                company,
                address,
                telephone,
                homepage,
            });

            notification.success({
                message: 'Registration successful!',
            });

            push(ROUTES.ACCOUNT_CREATED);
        },
    );

    if (isAuthenticated()) {
        push(defaultRoute);
        return null;
    }

    const onFinish = ({
        name,
        email,
        password,
        password_confirmation,
        role,
        company,
        address,
        telephone,
        homepage,
    }: RegisterFormData) => {
        mutation.mutate({
            name,
            email,
            password,
            password_confirmation,
            role,
            company,
            address,
            telephone,
            homepage,
        });
    };

    return (
        <GuestLayout>
            <Helmet>
                <title>Register | Copyknight</title>
            </Helmet>

            <Row justify="center">
                <Col span={24} md={12} lg={10} xl={8}>
                    <Card title="Create an account">
                        <Form
                            layout="vertical"
                            name="register"
                            form={form}
                            onFinish={onFinish}
                            initialValues={{ role: 'user' }}
                        >
                            <Form.Item name="role" label="Role">
                                <Radio.Group optionType="button" buttonStyle="solid">
                                    <Radio.Button onClick={(e) => setIsPublisher(false)} value="user">
                                        Copyright Holder
                                    </Radio.Button>
                                    <Radio.Button onClick={(e) => setIsPublisher(true)} value="publisher">
                                        Website Publisher
                                    </Radio.Button>
                                </Radio.Group>
                            </Form.Item>
                            {isPublisher ? (
                                <p className="ant-upload-text" style={{ fontWeight: 'bold' }}>
                                    You can hook into our API and block or optionally display banners for all copyright
                                    owners in our database.
                                </p>
                            ) : (
                                <p className="ant-upload-text" style={{ fontWeight: 'bold' }}>
                                    For artists, game companies etc. With this you can start blocking or advertising on
                                    our partners.
                                </p>
                            )}
                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please enter your name!' }]}
                            >
                                <Input placeholder="Name" />
                            </Form.Item>

                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{ required: true, message: 'Please enter your email!' }]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Please enter your password!' }]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>

                            <Form.Item
                                label="Password confirmation"
                                name="password_confirmation"
                                rules={[{ required: true, message: 'Please enter the password confirmation!' }]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>

                            <Form.Item>
                                <Checkbox checked={isCompany} onChange={(e) => setIsCompany(e.target.checked)} /> I am a
                                company
                            </Form.Item>

                            {isCompany && (
                                <>
                                    <Form.Item
                                        label="Company name"
                                        name="company"
                                        rules={[{ required: isCompany, message: 'Please enter the company name!' }]}
                                    >
                                        <Input placeholder="Company name" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Address"
                                        name="address"
                                        rules={[{ required: isCompany, message: 'Please enter the company address!' }]}
                                    >
                                        <Input placeholder="Company address" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Telephone"
                                        name="telephone"
                                        rules={[
                                            { required: isCompany, message: 'Please enter the company telephone!' },
                                        ]}
                                    >
                                        <Input placeholder="Company telephone" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Homepage"
                                        name="homepage"
                                        rules={[{ required: isCompany, message: 'Please enter the company homepage!' }]}
                                    >
                                        <Input placeholder="Company homepage" />
                                    </Form.Item>
                                </>
                            )}

                            {mutation.isError && (
                                <Form.Item>
                                    <ApiError error={mutation.error} />
                                </Form.Item>
                            )}

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={mutation.isLoading}
                                    style={{ width: '100%' }}
                                >
                                    Register
                                </Button>
                            </Form.Item>

                            <Form.Item>
                                <Row justify="space-between">
                                    <Col>
                                        Already have an account? <Link to={ROUTES.LOGIN}>Log in</Link>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </GuestLayout>
    );
};
