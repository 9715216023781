import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useMutation } from 'react-query';
import { Button, Card, Col, Input, PageHeader, Row, Spin, Form, notification, Space, Typography } from 'antd';
import { useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { AxiosError } from 'axios';
import { ApiError } from '@components/api-error';
import AccountApi, { AccountResetPasswordData } from '@services/account';
import { AdminLayout } from '@components/layouts/admin';

export const AdminUserResetPasswordPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);

    const { push } = useHistory();
    // const [form] = useForm();
    let { email } = useParams<{ email: string }>();
    email = email.slice(6); // NOTE: email maybe will be used later

    const isLoading = false;

    const mutation = useMutation<void, AxiosError, AccountResetPasswordData>(async (data) => {
        console.log('data: ', data); // NOTE: remove the line when this page is finished
        try {
            await AccountApi.accountPasswordReset(data);
            notification.success({
                message: 'New password created!',
            });
            push(ROUTES.ADMIN.USERS.INDEX);
        } catch (error) {
            console.log('error: ', error);
            notification.error({
                message: 'Error creating new password',
            });
        }
    });

    const onFinish = (data: AccountResetPasswordData) => {
        mutation.mutate(data);
    };

    return (
        <AdminLayout>
            <Helmet>
                <title>Create a Copyright Group | Copyknight</title>
            </Helmet>

            <PageHeader
                onBack={() => push(ROUTES.ADMIN.USERS.INDEX)}
                title={
                    <Space direction="vertical">
                        <Typography.Text>Reset Password </Typography.Text>
                    </Space>
                }
            >
                <Row justify="center">
                    <Col span={24} md={12} lg={12} xl={12}>
                        <Card title={`New password for email  ${email}`}>
                            {isLoading ? (
                                <Spin size="large" spinning={true} />
                            ) : (
                                <Form layout="vertical" name="reset-password" onFinish={onFinish}>
                                    <Form.Item label="Email" name="email" initialValue={email} hidden>
                                        <Input placeholder={email} />
                                    </Form.Item>
                                    <Form.Item
                                        label="Old_password"
                                        name="old_password"
                                        rules={[{ required: true, message: 'Please enter old password!' }]}
                                    >
                                        <Input.Password placeholder="Old password" />
                                    </Form.Item>
                                    <Form.Item
                                        label="New password"
                                        name="new_password"
                                        rules={[{ required: true, message: 'Please enter new password!' }]}
                                    >
                                        <Input.Password placeholder="Password" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Confirm new password"
                                        name="new_password_confirmation"
                                        rules={[{ required: true, message: 'Please enter your password once more!' }]}
                                    >
                                        <Input.Password placeholder="Enter your password once more" />
                                    </Form.Item>

                                    {mutation.isError && (
                                        <Form.Item>
                                            <ApiError error={mutation.error} />
                                        </Form.Item>
                                    )}

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={mutation.isLoading}
                                            style={{ width: '100%' }}
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </Card>
                    </Col>
                </Row>
            </PageHeader>
        </AdminLayout>
    );
};
