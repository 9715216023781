/* eslint-disable indent */
import { User } from '@services/admin-users';
import { WebhookMatch, WebhookRecord } from '@services/webhooks';
import { BannerCollection } from '@services/banner-collections';
import { BannerSlot } from '@services/banner-slots';
import { CopyrightEntry } from '@services/copyright-entries';
import { CopyrightGroup } from '@services/copyright-groups';
import { Copyright } from '@services/copyrights';
import { Website } from '@services/websites';

export const ROUTES = {
    LOGIN: '/login',
    REGISTER: '/register',
    FORGOT_PASSWORD: '/password/forgot',
    FORGOT_PASSWORD_REQUESTED: '/password/forgot/success',
    RESET_PASSWORD: '/password/reset/:token',
    ACCOUNT_CREATED: '/register/success',
    SUPPORT: {
        PRIVACY_POLICY: '/privacy-policy',
        TOS: '/terms-of-service',
    },
    USER: {
        DASHBOARD: '/dashboard',
        ACCOUNT: '/account',
        MESSAGES: '/messages',
        NOTIFICATIONS: '/notifications',
        CHANGE_PASSWORD: '/change-password',
        COPYRIGHTS: {
            INDEX: '/copyrights',
            CREATE: '/copyrights/new',
        },
        COPYRIGHT_ENTRIES(
            copyright?: Copyright,
        ): {
            INDEX: string;
            CREATE: string;
            DETAILS(entry?: CopyrightEntry): string;
        } {
            const copyrightId = copyright?.id ?? ':copyrightId';
            return {
                INDEX: `/copyrights/${copyrightId}/entries`,
                CREATE: `/copyrights/${copyrightId}/entries/create`,
                DETAILS(entry?: CopyrightEntry): string {
                    const entryId = entry?.id ?? ':entryId';
                    return `/copyrights/${copyrightId}/entries/${entryId}`;
                },
            };
        },
        COPYRIGHT_GROUPS(
            copyright?: Copyright,
        ): {
            INDEX: string;
            CREATE: string;
            DETAILS(group?: CopyrightGroup): string;
        } {
            const copyrightId = copyright?.id ?? ':copyrightId';
            return {
                INDEX: `/copyrights/${copyrightId}/groups`,
                CREATE: `/copyrights/${copyrightId}/groups/create`,
                DETAILS(group?: CopyrightGroup): string {
                    const groupId = group?.id ?? ':groupId';
                    return `/copyrights/${copyrightId}/groups/${groupId}`;
                },
            };
        },
        BANNER_COLLECTIONS(
            copyright?: Copyright,
        ): {
            INDEX: string;
            CREATE: string;
            EDIT(bannerCollection?: BannerCollection): string;
        } {
            const copyrightId = copyright?.id ?? ':copyrightId';
            return {
                INDEX: `/copyrights/${copyrightId}/banner-collections`,
                CREATE: `/copyrights/${copyrightId}/banner-collections/create`,
                EDIT(bannerCollection: BannerCollection): string {
                    const bannerCollectionId = bannerCollection?.id ?? ':bannerCollectionId';
                    return `/copyrights/${copyrightId}/banner-collections/${bannerCollectionId}/edit`;
                },
            };
        },
    },
    PUBLISHER: {
        DASHBOARD: '/publisher/dashboard',
        MESSAGES: '/publisher/messages',
        NOTIFICATIONS: '/publisher/notifications',
        ACCOUNT: '/publisher/account',
        VERIFICATION_MATCHES: '/publisher/verification-matches',
        WEBSITES: {
            INDEX: '/publisher/websites',
            CREATE: '/publisher/websites/new',
            EDIT(website?: Website): string {
                const websiteId = website?.id ?? ':websiteId';
                return `/publisher/websites/${websiteId}`;
            },
        },
        API(website?: Website): string {
            const websiteId = website?.id ?? ':websiteId';
            return `/publisher/websitesAPI/${websiteId}`;
        },
    },
    ADMIN: {
        DASHBOARD: '/admin/dashboard',
        VERIFICATION_MATCHES: '/admin/verification-matches',
        USER_DUPLICATES: '/admin/cross-matches',
        SETTINGS: '/admin/settings',
        MESSAGES: '/admin/messages',
        WEBHOOKS: '/admin/webhooks',
        WEBHOOK_DETAILS(webhook?: WebhookMatch | WebhookRecord): Record<string, string> {
            const webhookId = webhook?.id ?? ':webhookId';
            return {
                INDEX: `/admin/webhook/${webhookId}/details`,
            };
        },

        CHANGE_PASSWORD: '/admin/change-password',
        USERS: {
            INDEX: '/admin/users',
            PUBLISHERS: '/admin/users/publishers',
            ADMINS: '/admin/users/admins',
            CREATE: '/admin/users-create',
            RESET_PASSWORD: '/admin/users-reset-password/:email',
        },
        USER_DETAILS(user?: User): Record<string, string> {
            const userId = user?.id ?? ':userId';
            return {
                INDEX: `/admin/user/${userId}/details`,
            };
        },
        WEBSITES: {
            INDEX: '/admin/websites/list',
            PENDING: '/admin/websites/pending',
        },
        COPYRIGHTS: {
            PENDING: '/admin/copyrights/pending',
        },
        COPYRIGHT_ENTRIES: {
            PENDING: '/admin/copyright-entries/pending',
            FULLSCREEN: '/admin/copyright-entries/fullscreen',
        },
        BANNER_SLOTS: {
            INDEX: '/admin/banner-slots',
            CREATE: '/admin/banner-slots/create',
            EDIT(bannerSlot?: BannerSlot): string {
                const bannerSlotId = bannerSlot?.id ?? ':bannerSlotId';
                return `/admin/banner-slots/${bannerSlotId}`;
            },
        },
    },
};
