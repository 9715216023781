import { ROUTES } from '@config/routes';
import { LoginResponse } from '@services/account';
import { useAuthUser } from 'react-auth-kit';

export const useDefaultRoute = (): string => {
    const auth = useAuthUser();
    const user = auth() as LoginResponse;

    if (user?.is_admin || user?.is_mod) {
        return ROUTES.ADMIN.DASHBOARD;
    } else if (user?.is_publisher) {
        return ROUTES.PUBLISHER.DASHBOARD;
    } else {
        return ROUTES.USER.DASHBOARD;
    }
};

export const getDefaultRoute = (userData?: LoginResponse): string => {
    const user = userData;

    if (user?.is_admin || user?.is_mod) {
        return ROUTES.ADMIN.DASHBOARD;
    } else if (user?.is_publisher) {
        return ROUTES.PUBLISHER.DASHBOARD;
    } else {
        return ROUTES.USER.DASHBOARD;
    }
};
