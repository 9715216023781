import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { NotificationList } from '@components/notification-list';
import { UserLayout } from '@components/layouts/user';

export const UserNotificationsPage: React.FC = () => {
    useProtectedRoute(ROLES.USER);

    return (
        <UserLayout>
            <Helmet>
                <title>Notifications | Copyknight</title>
            </Helmet>

            <NotificationList />
        </UserLayout>
    );
};
