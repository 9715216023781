import { getCookie } from '@util/cookie';
import { getQueryParams } from '@util/query-params';
import React, { MutableRefObject, PropsWithChildren, useContext, useRef } from 'react';
import { AuthProvider } from 'react-auth-kit';

interface Context {
    cookieName: string;
    loginAsId: MutableRefObject<string | undefined>;
    getToken(): string;
}

const AppAuthContext = React.createContext<Context | undefined>(undefined);

export const AppAuthProvider: React.FC<PropsWithChildren<unknown>> = ({ children }: PropsWithChildren<unknown>) => {
    const { loginAs, data } = getQueryParams();
    const loginAsId = useRef<string>();

    const cookiePrefix = useRef(!!loginAs || !!sessionStorage['loginAsId'] ? 'as' : 'reg');
    const cookieName = `_auth_${cookiePrefix.current}_t`;
    const getToken = () => getCookie(cookieName);

    if (!loginAsId.current && loginAs) {
        loginAsId.current = loginAs;
        sessionStorage['loginAsId'] = loginAs;
        sessionStorage['loginAsData'] = data;
    }

    const value = {
        cookieName,
        loginAsId,
        getToken,
    };

    return (
        <AppAuthContext.Provider value={value}>
            <AuthProvider
                authStorageType={'cookie'}
                authStorageName={`_auth_${cookiePrefix.current}_t`}
                authTimeStorageName={`_auth_${cookiePrefix.current}_time`}
                stateStorageName={`_auth_${cookiePrefix.current}_state`}
                cookieDomain={window.location.hostname}
                cookieSecure={window.location.protocol === 'https:'}
                refreshTokenName={`_refresh_${cookiePrefix.current}_t`}
            >
                {children}
            </AuthProvider>
        </AppAuthContext.Provider>
    );
};

export const useAppAuthContext = (): Context => {
    const context = useContext(AppAuthContext);
    if (context === undefined) {
        throw new Error('useAppAuthContext must be used within AppAuthProvider');
    }
    return context;
};
