export function getQueryParams(): Record<string, string> {
    const href = window.location.href;
    const params = href.split('?')[1];

    // Be sure url params exist
    if (params && params !== '') {
        const result = params.split('&').reduce(function (res: Record<string, string>, item) {
            const parts = item.split('=');
            res[parts[0]] = parts[1];
            return res;
        }, {});

        return result;
    }

    return {};
}
