import { File } from '@interfaces/file';
import { CopyrightBanner } from '@services/banner-collections';

export function getApiFileUrl(file: File): string {
    return file.path;
}

export function getApiThumbnailUrl(file: File): string {
    if (file.type === 'video') {
        const firstFrame = file.video_frames?.[0];
        if (!firstFrame) {
            return '';
        }

        return firstFrame.path;
    } else if (file.type === 'image') {
        return file.thumbnail_path;
    } else {
        return '';
    }
}

export function getApiBannerUrl(banner: CopyrightBanner): string {
    return `${process.env.REACT_APP_STORAGE_URL}${banner.url}`;
}

export function getApiBannerThumbUrl(banner: CopyrightBanner): string {
    return `${process.env.REACT_APP_STORAGE_URL}${banner.thumbnail_url}`;
}
