import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useMutation, useQuery } from 'react-query';
import { Button, notification, PageHeader, Space, Table, TablePaginationConfig } from 'antd';
import { PAGINATION } from '@config/pagination';
import { Pagination } from 'interfaces/pagination';
import { Link } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { AdminLayout } from '@components/layouts/admin';
import BannerSlotsApi, { BannerSlot } from '@services/banner-slots';
import confirm from 'antd/lib/modal/confirm';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { AxiosError } from 'axios';
import { paginationSelect } from '@util/react-query';

export const AdminBannerSlotsIndexPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data, isLoading, refetch } = useQuery(
        ['banner-slots', pagination],
        () => BannerSlotsApi.getAll(pagination),
        {
            keepPreviousData: true,
            select: paginationSelect,
        },
    );

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: data?.total,
            pageSize: data?.per_page,
            current: data?.current_page,
        });
    }, [data]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filters: Record<string, (string | number | boolean)[] | null>,
    ) => {
        setPagination(pagination);
    };

    const deleteMutation = useMutation<void, AxiosError, BannerSlot>(async (bannerSlot) => {
        try {
            await BannerSlotsApi.delete(String(bannerSlot?.id));
            notification.success({
                message: 'Banner slot deleted!',
            });
            refetch();
        } catch (error) {
            notification.error({ message: 'Error deleting banner slot' });
        }
    });

    const onDeleteBannerSlot = (bannerSlot: BannerSlot) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete this banner slot?',
            okText: 'Delete',
            okType: 'danger',
            onOk() {
                deleteMutation.mutate(bannerSlot);
            },
        });
    };

    const columns = [
        {
            title: 'Description',
            dataIndex: 'description',
            key: 'description',
            render: function DescriptionColumn(description: string, bannerSlot: BannerSlot) {
                return <Link to={ROUTES.ADMIN.BANNER_SLOTS.EDIT(bannerSlot)}>{description}</Link>;
            },
        },
        {
            title: 'Resolution',
            dataIndex: 'resolution',
            key: 'resolution',
        },
        {
            title: 'Width',
            dataIndex: 'width',
            key: 'width',
        },
        {
            title: 'Height',
            dataIndex: 'height',
            key: 'height',
        },
        {
            title: 'Actions',
            render: function ActionsColumn(bannerSlot: BannerSlot) {
                return (
                    <Space>
                        <Link to={ROUTES.ADMIN.BANNER_SLOTS.EDIT(bannerSlot)}>
                            <Button>Edit</Button>
                        </Link>
                        <Button danger onClick={() => onDeleteBannerSlot(bannerSlot)}>
                            Delete
                        </Button>
                    </Space>
                );
            },
        },
    ];

    return (
        <AdminLayout>
            <Helmet>
                <title>Banner Slots | Copyknight</title>
            </Helmet>

            <PageHeader
                title="Banner Slots"
                extra={[
                    <Link key="1" to={ROUTES.ADMIN.BANNER_SLOTS.CREATE}>
                        <Button type="primary">Create</Button>
                    </Link>,
                ]}
            >
                <Table
                    locale={{ emptyText: 'No Banner Slots yet' }}
                    bordered
                    columns={columns}
                    pagination={pagination}
                    rowKey="id4"
                    dataSource={data?.data}
                    loading={isLoading}
                    onChange={handleTableChange}
                />
            </PageHeader>
        </AdminLayout>
    );
};
