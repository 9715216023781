import MessagesApi from '@services/messages';
import React, { PropsWithChildren, useContext } from 'react';
import { useAuthUser } from 'react-auth-kit';
import { useQuery } from 'react-query';
import { deepSelect } from '@util/react-query';

interface Context {
    hasUnreadMessages: boolean;
}

const MessagesContext = React.createContext<Context | undefined>(undefined);

export const MessagesProvider: React.FC<PropsWithChildren<unknown>> = ({ children }: PropsWithChildren<unknown>) => {
    const auth = useAuthUser();
    const user = auth() as { id: number; is_admin: boolean; is_mod: boolean };
    const isAdmin = user?.is_admin || user?.is_mod;
    const { data } = useQuery(['conversations', user?.id], MessagesApi.getConversations, {
        refetchInterval: 30 * 1000,
        enabled: !!user,
        select: deepSelect,
    });

    const value = {
        hasUnreadMessages: !!data?.conversations?.some((conversation) =>
            isAdmin ? !conversation.seen_by_admin : !conversation.seen_by_user,
        ),
    };

    return <MessagesContext.Provider value={value}>{children}</MessagesContext.Provider>;
};

export const useMessagesContext = (): Context => {
    const context = useContext(MessagesContext);
    if (context === undefined) {
        throw new Error('useMessagesContext must be used within MessagesProvider');
    }
    return context;
};
