import React from 'react';

import { useMutation, useQuery } from 'react-query';
import AccountApi, { AccountData } from '@services/account';
import { AxiosError } from 'axios';
import { Button, Card, Col, Form, Input, notification, Row, Spin, Switch } from 'antd';
import { ApiError } from '@components/api-error';
import { useForm } from 'antd/lib/form/Form';
import { useAuthUser } from 'react-auth-kit';
import { deepOptions } from '@util/react-query';

interface ProfileFormData {
    name: string;
    email: string;
    password: string;
    password_confirmation: string;
    role: string;
    company: string;
    address: string;
    telephone: string;
    homepage: string;
    receive_mail_notifications: boolean;
}

export const EditProfileForm: React.FC = () => {
    const [form] = useForm();
    const auth = useAuthUser();
    const user = auth() as { id: number };

    const { isLoading, data } = useQuery(['account', user?.id], AccountApi.me, deepOptions);
    const mutation = useMutation<void, AxiosError, AccountData>(
        async ({
            name,
            password,
            password_confirmation,
            role,
            company,
            address,
            telephone,
            homepage,
            receive_mail_notifications,
        }) => {
            // @todo handle server error
            await AccountApi.edit(user?.id, {
                name,
                // email,
                password,
                password_confirmation,
                role,
                company,
                address,
                telephone,
                homepage,
                receive_mail_notifications,
            });

            notification.success({
                message: 'Profile updated!',
            });
        },
    );

    const onFinish = ({
        name,
        email,
        password,
        password_confirmation,
        role,
        company,
        address,
        telephone,
        homepage,
        receive_mail_notifications,
    }: ProfileFormData) => {
        mutation.mutate({
            name,
            email,
            password,
            password_confirmation,
            role,
            company,
            address,
            telephone,
            homepage,
            receive_mail_notifications,
        });
    };

    return (
        <Row justify="center">
            <Col span={24} md={12} lg={12} xl={12}>
                <Card title="Update profile">
                    {isLoading ? (
                        <Spin size="large" spinning={true} />
                    ) : (
                        <Form
                            layout="vertical"
                            name="register"
                            form={form}
                            onFinish={onFinish}
                            initialValues={data?.user}
                        >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{ required: true, message: 'Please enter your email!' }]}
                            >
                                <Input readOnly disabled placeholder="Email" />
                            </Form.Item>

                            <Form.Item
                                label="Name"
                                name="name"
                                rules={[{ required: true, message: 'Please enter your name!' }]}
                            >
                                <Input placeholder="Name" />
                            </Form.Item>

                            <Form.Item label="Company name" name="company">
                                <Input placeholder="Company name" />
                            </Form.Item>

                            <Form.Item label="Address" name="address">
                                <Input placeholder="Company address" />
                            </Form.Item>

                            <Form.Item label="Telephone" name="telephone">
                                <Input placeholder="Company telephone" />
                            </Form.Item>

                            <Form.Item label="Homepage" name="homepage">
                                <Input placeholder="Company homepage" />
                            </Form.Item>

                            <Row>
                                <Col style={{ paddingTop: '6px', paddingRight: '5px' }}>
                                    <span>
                                        <strong>Receive e-mail notifications</strong>
                                    </span>
                                </Col>
                                <Col>
                                    <Form.Item name="receive_mail_notifications">
                                        <Switch
                                            defaultChecked={data?.user.receive_mail_notifications}
                                            checkedChildren="Yes"
                                            unCheckedChildren="No"
                                        />
                                    </Form.Item>
                                </Col>
                            </Row>

                            {mutation.isError && (
                                <Form.Item>
                                    <ApiError error={mutation.error} />
                                </Form.Item>
                            )}

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={mutation.isLoading}
                                    style={{ width: '100%' }}
                                >
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    )}
                </Card>
            </Col>
        </Row>
    );
};
