import React from 'react';

import { useMutation } from 'react-query';
import { Button, Card, Col, Input, Row, Spin, Form, notification } from 'antd';
import { useHistory } from 'react-router-dom';
import { AxiosError } from 'axios';
import { ApiError } from '@components/api-error';
import AccountApi, { AccountResetPasswordData } from '@services/account';

// import { Helmet } from 'react-helmet-async';
// import { UserLayout } from '@components/layouts/user';

interface ICallbackRoute {
    callbackRoute: string;
}

export const ChangePasswordForm = ({ callbackRoute }: ICallbackRoute): JSX.Element => {
    // const { callbackRoute } = props;
    const { push } = useHistory();

    const isLoading = false;

    const mutation = useMutation<void, AxiosError, AccountResetPasswordData>(async (data) => {
        try {
            await AccountApi.accountPasswordReset(data);
            notification.success({
                message: 'New password created!',
            });
            push(callbackRoute);
        } catch (error) {
            console.log('error: ', error);
            notification.error({
                message: 'Error creating new password',
            });
        }
    });

    const onFinish = (data: AccountResetPasswordData) => {
        mutation.mutate(data);
    };

    return (
        // <UserLayout>
        //     <Helmet>
        //         <title>Change Password | Copyknight</title>
        //     </Helmet>
        <Row justify="center">
            <Col span={24} md={12} lg={12} xl={12}>
                <Card title={`New password for you`}>
                    {isLoading ? (
                        <Spin size="large" spinning={true} />
                    ) : (
                        <Form layout="vertical" name="reset-password" onFinish={onFinish}>
                            <Form.Item
                                label="Old_password"
                                name="old_password"
                                rules={[{ required: true, message: 'Please enter old password!' }]}
                            >
                                <Input.Password placeholder="Old password" />
                            </Form.Item>
                            <Form.Item
                                label="New password"
                                name="new_password"
                                rules={[{ required: true, message: 'Please enter new password!' }]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>

                            <Form.Item
                                label="Confirm new password"
                                name="new_password_confirmation"
                                rules={[{ required: true, message: 'Please enter your password once more!' }]}
                            >
                                <Input.Password placeholder="Enter your password once more" />
                            </Form.Item>

                            {mutation.isError && (
                                <Form.Item>
                                    <ApiError error={mutation.error} />
                                </Form.Item>
                            )}

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={mutation.isLoading}
                                    style={{ width: '100%' }}
                                >
                                    Save
                                </Button>
                            </Form.Item>
                        </Form>
                    )}
                </Card>
            </Col>
        </Row>
        // </UserLayout>
    );
};
