import { UploadFile } from 'antd/lib/upload/interface';
import { VideoFrame } from '@interfaces/video-frame';
import { Pagination, ServerPagination } from '@interfaces/pagination';
import { Copyright } from './copyrights';
import http from './http';
import { File } from '@interfaces/file';
import { CopyrightGroup } from './copyright-groups';
export interface CopyrightEntry {
    id: number;
    title: string;
    approved: boolean;
    approval_denied: boolean;
    approval_denied_reason: string | null;
    copyright_group_id: number;
    copyright_id: number;
    copyrights?: Copyright;
    copyright_groups?: CopyrightGroup;
    ext: string;
    url: string;
    is_trusted?: boolean;
    created_at: string;
    updated_at: string;
    deleted_at: string | null;
}

export type CopyrightEntryListResponse = ServerPagination<CopyrightEntry[]>;

export interface CreateCopyrightEntryRequest {
    title: string;
    copyright_id: number;
    copyright_group_id: number;
    ext?: string;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface
export interface CreateCopyrightEntryResponse {
    data: CopyrightEntry;
}

export interface ChangeCopyrightEntryStatusRequest {
    approved: boolean;
    approval_denied: boolean;
    approval_denied_reason: string;
}

export interface UploadCopyrightEntryFileRequest {
    file: UploadFile;
}

export interface CopyrightEntryResponse {
    data: CopyrightEntry;
}

export interface CopyrightEntryFileVideoFrame extends VideoFrame {
    copyright_entry_file_id: number;
}
export interface CopyrightEntryFile extends File {
    copyright_entry_id: number;
    video_frames?: CopyrightEntryFileVideoFrame[];

    copyright_entries?: CopyrightEntry;
}

export interface CopyrightEntryFilesResponse {
    files: CopyrightEntryFile[];
}

export default class CopyrightEntriesApi {
    public static getEntries(copyrightId: string, pagination: Pagination): Promise<CopyrightEntryListResponse> {
        const url = `/copyright/${copyrightId}/entries`;
        return http.get(url, {
            params: {
                page: pagination.current,
            },
        });
    }

    public static getCopyrightEntry(
        copyrightId: string,
        copyrightEntryId: string,
    ): Promise<CopyrightEntryResponse & { data?: CopyrightEntryResponse }> {
        const url = `/copyright/${copyrightId}/entries/${copyrightEntryId}`;
        return http.get(url);
    }

    public static create(
        copyrightId: string,
        data: CreateCopyrightEntryRequest,
    ): Promise<CreateCopyrightEntryResponse> {
        return http.post(`/copyright/${copyrightId}/entries`, data);
    }

    public static edit(
        copyrightId: string,
        entryId: string,
        data: CreateCopyrightEntryRequest,
    ): Promise<CreateCopyrightEntryResponse> {
        return http.put(`/copyright/${copyrightId}/entries/${entryId}`, data);
    }

    public static getPendingCopyrightEntriesAdmin(pagination: Pagination): Promise<CopyrightEntryListResponse> {
        return http.get(`/admin/copyright/pending_entries`, {
            params: {
                page: pagination.current,
                per_page: pagination.pageSize,
            },
        });
    }

    public static changeStatus(
        copyrightId: string,
        entryId: string,
        data: ChangeCopyrightEntryStatusRequest,
    ): Promise<void> {
        return http.put(`/copyright/${copyrightId}/entries/${entryId}`, data);
    }

    public static uploadFile(
        copyrightId: string,
        entryId: string,
        data: UploadCopyrightEntryFileRequest,
    ): Promise<void> {
        const formData = new FormData();
        formData.append('copyright_id', copyrightId);
        formData.append('entry_id', entryId);
        formData.append('file', data.file.originFileObj as Blob);

        return http.post(`/copyright/${copyrightId}/entries/${entryId}/files`, formData, {
            headers: { 'Content-Type': 'multipart/form-data' },
        });
    }

    public static getUploadFileUrl(copyrightId: string, entryId: string): string {
        return `${process.env.REACT_APP_API_URL}/copyright/${copyrightId}/entries/${entryId}/files`;
    }

    public static getCopyrightEntryFiles(
        copyrightId: string,
        copyrightEntryId: string,
    ): Promise<CopyrightEntryFilesResponse> {
        return http.get(`/copyright/${copyrightId}/entries/${copyrightEntryId}/files`);
    }

    public static deleteFile(
        copyrightId: string,
        copyrightEntryId: string,
        fileId: string,
    ): Promise<CopyrightEntryFilesResponse> {
        return http.delete(`/copyright/${copyrightId}/entries/${copyrightEntryId}/files/${fileId}`);
    }

    public static uniquenessCheck(copyrightId: string, copyrightEntryId: string, fileId: string): Promise<void> {
        return http.post(`/copyright/${copyrightId}/entries/${copyrightEntryId}/files/${fileId}/unique-check`);
    }

    public static deleteEntry(copyrightId: string, copyrightEntryId: string): Promise<void> {
        return http.delete(`/copyright/${copyrightId}/entries/${copyrightEntryId}`);
    }

    public static submitForReapproval(copyrightId: string, copyrightEntryId: string): Promise<void> {
        return http.post(`/copyright/${copyrightId}/entries/${copyrightEntryId}/reapproval`);
    }
}
