import { ROUTES } from '@config/routes';
import { Space, Typography } from 'antd';
import { Footer } from 'antd/lib/layout/layout';
import React from 'react';
import { Link } from 'react-router-dom';

export function SiteFooter(): JSX.Element {
    return (
        <Footer style={{ textAlign: 'center' }}>
            <Space direction="vertical">
                <Typography.Text type="secondary">Copyknight Media ©{new Date().getFullYear()}</Typography.Text>

                <Space>
                    <Link to={ROUTES.SUPPORT.PRIVACY_POLICY}>Privacy Policy</Link>
                    <Link to={ROUTES.SUPPORT.TOS}>Terms of Service</Link>
                </Space>
            </Space>
        </Footer>
    );
}
