import React from 'react';
import { Layout, Menu, Button } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { useIsAuthenticated, useSignOut } from 'react-auth-kit';
import { Logo } from '@components/logo';

const { Header } = Layout;

export const GuestNav: React.FC = () => {
    const match = useRouteMatch();
    const signOut = useSignOut();
    const isAuthenticated = useIsAuthenticated();

    return (
        <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Logo />
            <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[match.path]}>
                {!isAuthenticated() && (
                    <>
                        <Menu.Item key="/login">
                            <Link to="/login">Login</Link>
                        </Menu.Item>
                        <Menu.Item key="/register">
                            <Link to="/register">Register</Link>
                        </Menu.Item>
                    </>
                )}

                {isAuthenticated() && (
                    <Menu.Item key="logout">
                        <Button onClick={signOut}>Logout</Button>
                    </Menu.Item>
                )}
            </Menu>
        </Header>
    );
};
