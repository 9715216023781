export const getInitials = function (string: string | undefined): string {
    if (!string) {
        return '';
    }

    const names = string.split(' ');
    let initials = names[0].substring(0, 1).toUpperCase();

    if (names.length > 1) {
        initials += names[names.length - 1].substring(0, 1).toUpperCase();
    }

    return initials;
};
