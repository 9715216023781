import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useMutation } from 'react-query';
import { Button, Card, Col, Input, PageHeader, Row, Spin, Form, notification, Space, Typography, Select } from 'antd';
import { useHistory } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { useForm } from 'antd/lib/form/Form';
import { AxiosError } from 'axios';
import { ApiError } from '@components/api-error';
import AdminUsersApi, { CreateUserRequest } from '@services/admin-users';
import { AdminLayout } from '@components/layouts/admin';

export const AdminUserCreatePage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);

    const { push } = useHistory();
    const [form] = useForm();

    const isLoading = false;

    const mutation = useMutation<void, AxiosError, CreateUserRequest>(async (data) => {
        try {
            await AdminUsersApi.createUser(data);
            notification.success({
                message: 'User created!',
            });
            push(ROUTES.ADMIN.USERS.INDEX);
        } catch (error) {
            notification.error({
                message: 'Error creating user',
            });
        }
    });

    return (
        <AdminLayout>
            <Helmet>
                <title>Create a Copyright Group | Copyknight</title>
            </Helmet>

            <PageHeader
                onBack={() => push(ROUTES.ADMIN.USERS.INDEX)}
                title={
                    <Space direction="vertical">
                        <Typography.Text>Create User</Typography.Text>
                    </Space>
                }
            >
                <Row justify="center">
                    <Col span={24} md={12} lg={12} xl={12}>
                        <Card title="Create User">
                            {isLoading ? (
                                <Spin size="large" spinning={true} />
                            ) : (
                                <Form layout="vertical" name="create-user" form={form} onFinish={mutation.mutate}>
                                    <Form.Item
                                        label="Name"
                                        name="name"
                                        rules={[{ required: true, message: 'Please enter the user name!' }]}
                                    >
                                        <Input placeholder="Name" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Email"
                                        name="email"
                                        rules={[{ required: true, message: 'Please enter the user email!' }]}
                                    >
                                        <Input placeholder="Email" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Password"
                                        name="password"
                                        rules={[{ required: true, message: 'Please enter the user password!' }]}
                                    >
                                        <Input.Password placeholder="Password" />
                                    </Form.Item>

                                    <Form.Item
                                        label="Role"
                                        name="role"
                                        rules={[{ required: true, message: 'Please enter the role!' }]}
                                    >
                                        <Select>
                                            <Select.Option value={ROLES.USER}>{ROLES.USER}</Select.Option>
                                            <Select.Option value={ROLES.PUBLISHER}>{ROLES.PUBLISHER}</Select.Option>
                                            <Select.Option value={ROLES.MODERATOR}>{ROLES.MODERATOR}</Select.Option>
                                            <Select.Option value={ROLES.ADMIN}>{ROLES.ADMIN}</Select.Option>
                                        </Select>
                                    </Form.Item>

                                    {mutation.isError && (
                                        <Form.Item>
                                            <ApiError error={mutation.error} />
                                        </Form.Item>
                                    )}

                                    <Form.Item>
                                        <Button
                                            type="primary"
                                            htmlType="submit"
                                            loading={mutation.isLoading}
                                            style={{ width: '100%' }}
                                        >
                                            Save
                                        </Button>
                                    </Form.Item>
                                </Form>
                            )}
                        </Card>
                    </Col>
                </Row>
            </PageHeader>
        </AdminLayout>
    );
};
