import React, { PropsWithChildren } from 'react';
import { Badge, Layout, Menu } from 'antd';
import { AdminNav } from '@components/nav/admin';
import { Link } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { useMessagesContext } from '@context/MessagesContext';
import Sider from 'antd/lib/layout/Sider';
import {
    BuildOutlined,
    DashboardOutlined,
    FileDoneOutlined,
    GlobalOutlined,
    MergeCellsOutlined,
    SwitcherOutlined,
    MessageOutlined,
    RetweetOutlined,
    PictureOutlined,
    SettingOutlined,
    TeamOutlined,
} from '@ant-design/icons';
import { SiteFooter } from '@components/footer';

const { Content } = Layout;

interface Props {
    showSidebar?: boolean;
}

export const AdminLayout: React.FC<PropsWithChildren<Props>> = ({
    children,
    showSidebar = true,
}: PropsWithChildren<Props>) => {
    const { hasUnreadMessages } = useMessagesContext();
    return (
        <Layout className={`layout ${showSidebar ? 'layout--sidebar' : 'layout--no-sidebar'}`}>
            <AdminNav />

            <Layout style={{ flexDirection: 'row' }}>
                {showSidebar && (
                    <Sider breakpoint="lg" collapsedWidth="0" width={200} className="site-layout-background">
                        <Menu mode="inline" style={{ height: '100%', borderRight: 0 }} theme="light">
                            <Menu.Item icon={<DashboardOutlined />} key="1">
                                <Link to={ROUTES.ADMIN.DASHBOARD}>Dashboard</Link>
                            </Menu.Item>

                            <Menu.Item icon={<FileDoneOutlined />} key="2">
                                <Link to={ROUTES.ADMIN.COPYRIGHTS.PENDING}>Copyrights</Link>
                            </Menu.Item>

                            <Menu.Item icon={<PictureOutlined />} key="3">
                                <Link to={ROUTES.ADMIN.COPYRIGHT_ENTRIES.PENDING}>Copyright Entries</Link>
                            </Menu.Item>

                            <Menu.Item icon={<GlobalOutlined />} key="4">
                                <Link to={ROUTES.ADMIN.WEBSITES.PENDING}>Websites</Link>
                            </Menu.Item>

                            <Menu.Item icon={<MergeCellsOutlined />} key="4-verification-matches">
                                <Link to={ROUTES.ADMIN.VERIFICATION_MATCHES}>Verification Matches</Link>
                            </Menu.Item>

                            <Menu.Item icon={<SwitcherOutlined />} key="4-user-duplicates">
                                <Link to={ROUTES.ADMIN.USER_DUPLICATES}>User Duplicates</Link>
                            </Menu.Item>

                            <Menu.Item icon={<TeamOutlined />} key="5">
                                <Link to={ROUTES.ADMIN.USERS.INDEX}>Users</Link>
                            </Menu.Item>

                            <Menu.Item icon={<BuildOutlined />} key="6">
                                <Link to={ROUTES.ADMIN.BANNER_SLOTS.INDEX}>Banner Slots</Link>
                            </Menu.Item>

                            <Menu.Item icon={<SettingOutlined />} key="7">
                                <Link to={ROUTES.ADMIN.SETTINGS}>Settings</Link>
                            </Menu.Item>

                            <Menu.Item icon={<MessageOutlined />} key="8">
                                <Link to={ROUTES.ADMIN.MESSAGES}>
                                    <Badge dot count={hasUnreadMessages ? 1 : 0}>
                                        Messages
                                    </Badge>
                                </Link>
                            </Menu.Item>

                            <Menu.Item icon={<RetweetOutlined />} key="9">
                                <Link to={ROUTES.ADMIN.WEBHOOKS}>Webhooks</Link>
                            </Menu.Item>
                        </Menu>
                    </Sider>
                )}
                <Layout>
                    <Content className="site-layout-content" style={{ height: '100%' }}>
                        <div style={{ flex: 1 }}>{children}</div>
                        <SiteFooter />
                    </Content>
                </Layout>
            </Layout>
        </Layout>
    );
};
