import { Pagination, ServerPagination } from '@interfaces/pagination';
import http from './http';

export enum OwnerType {
    ADMIN = 'admins',
    USER = 'users',
    PUBLISHER = 'publishers',
}
export interface Owner {
    name: string;
    email: string;
    id: string;
    roles: OwnerType[];
}

interface copyrightEntryFile {
    id: string;
    name: string;
    type: string;
    path: string;
    status: string;
    thumbnail_path?: string;
}

interface Matches {
    copyright_entry_file?: copyrightEntryFile[];
}
interface WebsiteInfo {
    base_url: string;
    name: string;
    owner: Owner;
    status: string;
}
interface SiteInfo {
    name: string;
    base_url: string;
    webhook_url: string;
    owner: Owner;
    created_at: string;
}

export interface WebhookErrorMassage {
    id?: string;
    content?: string;
    webhook_request?: string;
    created_at?: string;
    updated_at?: string;
}

export interface WebhookMatch {
    id: string | number;
    signature: string;
    call_status: string;
    attempt: number;
    website: number;
    messages: WebhookErrorMassage[];
    additionalInfo: {
        website: WebsiteInfo;
        matches?: Matches;
    };
    site: SiteInfo;
    created_at: string;
    updated_at: string;
}

export interface WebhookRecord {
    id: string | number;
    website: string;
    'website-owner': string;
    signature: string;
    status: string;
    attempts: number;
    errorMessages: string;
    'date-created': string;
    'last-tried': string;
}

export interface WebhookResponse {
    entity: WebhookMatch;
}

export interface WebhookResponse {
    data?: WebhookResponse;
}

type ColumnFields = 'website' | 'website-owner' | 'status' | 'copyright-entry';

export interface Sorter {
    field?: ColumnFields;
    order?: 'ASC' | 'DESC' | 'ascend' | 'descend';
}

export type WebhookMatchesResponse = { entities: ServerPagination<WebhookMatch[]> };

export default class WebhooksApi {
    public static get(pagination: Pagination): Promise<WebhookMatchesResponse & { data?: WebhookMatchesResponse }> {
        return http.get('/admin/webhook-requests', {
            params: {
                page: pagination.current,
                per_page: pagination.pageSize,
            },
        });
    }

    public static getSortedAndFiltered(
        pagination: Pagination,
        filter: Record<string, (string | number | boolean)[] | null>,
        sorter: Sorter,
    ): Promise<WebhookMatchesResponse & { data?: WebhookMatchesResponse }> {
        const filtersObject = Object.keys(filter)
            // .filter((item) => item !== 'status')
            .reduce((acum: Record<string, string>, current: string) => {
                const key = `filter-${current}` as string;
                const value = filter[current];
                if (value) {
                    acum[key] = value[0] as string;
                }
                return acum;
            }, {});

        const params = {
            page: pagination.current,
            per_page: pagination.pageSize,
            order: sorter.order,
            'order-by': sorter.field,
            ...filtersObject,
        };
        return http.get('/admin/webhook-requests', {
            params: params,
        });
    }

    public static getAll(): Promise<WebhookMatchesResponse> {
        return http.get('/admin/webhook-requests');
    }

    public static getWebhook(webhookId: string): Promise<WebhookResponse> {
        return http.get(`/admin/webhook-requests/${webhookId}`);
    }
}
