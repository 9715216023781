import React from 'react';
import { Layout, Menu, Button, Badge, Dropdown } from 'antd';
import { Link, useRouteMatch } from 'react-router-dom';
import { useSignOut } from 'react-auth-kit';
import { ROUTES } from '@config/routes';
import { useMessagesContext } from '@context/MessagesContext';
import { BellOutlined, MenuOutlined } from '@ant-design/icons';
import { isMobile } from 'react-device-detect';
import { Logo } from '@components/logo';
import { useNotificationsContext } from '@context/NotificationsContext';

const { Header } = Layout;

export const PublisherNav: React.FC = () => {
    const match = useRouteMatch();
    const signOut = useSignOut();
    const { hasUnreadMessages } = useMessagesContext();
    const { hasUnreadNotifications } = useNotificationsContext();

    const menu = (
        <Menu theme="dark" mode="horizontal" defaultSelectedKeys={[match.path]}>
            <Menu.Item key={ROUTES.PUBLISHER.MESSAGES}>
                <Link style={{ color: 'rgba(255, 255, 255, 0.65)' }} to={ROUTES.PUBLISHER.MESSAGES}>
                    <Badge dot count={hasUnreadMessages ? 1 : 0}>
                        <span style={{ color: 'rgba(255, 255, 255, 0.65)' }}>Messages</span>
                    </Badge>
                </Link>
            </Menu.Item>

            <Menu.Item key={ROUTES.PUBLISHER.WEBSITES.INDEX}>
                <Link to={ROUTES.PUBLISHER.WEBSITES.INDEX}>My Websites</Link>
            </Menu.Item>

            <Menu.Item key={ROUTES.PUBLISHER.VERIFICATION_MATCHES}>
                <Link to={ROUTES.PUBLISHER.VERIFICATION_MATCHES}>Verification Matches</Link>
            </Menu.Item>

            <Menu.Item key={ROUTES.PUBLISHER.ACCOUNT}>
                <Link to={ROUTES.PUBLISHER.ACCOUNT}>Profile</Link>
            </Menu.Item>

            <Menu.Item key={ROUTES.PUBLISHER.NOTIFICATIONS}>
                <Link style={{ color: 'rgba(255, 255, 255, 0.65)' }} to={ROUTES.PUBLISHER.NOTIFICATIONS}>
                    <Badge dot count={hasUnreadNotifications ? 1 : 0}>
                        <span style={{ color: 'rgba(255, 255, 255, 0.65)' }}>
                            <BellOutlined />
                        </span>
                    </Badge>
                </Link>
            </Menu.Item>

            <Menu.Item key={ROUTES.USER.CHANGE_PASSWORD}>
                <Link to={ROUTES.USER.CHANGE_PASSWORD}>Change password</Link>
            </Menu.Item>

            <Menu.Item key="logout" onClick={signOut}>
                Logout
            </Menu.Item>
        </Menu>
    );

    return (
        <Header style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
            <Logo />
            {!isMobile && menu}

            {isMobile && (
                <Dropdown overlayClassName="main-navigation" overlay={menu} trigger={['click']} placement="bottomRight">
                    <Button type="default">
                        <MenuOutlined />
                    </Button>
                </Dropdown>
            )}
        </Header>
    );
};
