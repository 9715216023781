import React, { PropsWithChildren, useEffect } from 'react';

import http from '@services/http';
import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useIsAuthenticated, useRefreshToken, useSignOut } from 'react-auth-kit';
import { useAppAuthContext } from './AppAuthContext';
import { getCookie } from '@util/cookie';
import useInterval from '@hooks/use-interval';
import AccountApi from '@services/account';

const HttpContext = React.createContext(null);

export const HttpProvider: React.FC<PropsWithChildren<unknown>> = ({ children }: PropsWithChildren<unknown>) => {
    const { cookieName } = useAppAuthContext();
    const signOut = useSignOut();
    const refreshToken = useRefreshToken();
    const isAuthenticated = useIsAuthenticated();

    const parseResponse = (res: AxiosResponse) => res.data;
    const attachToken = (req: AxiosRequestConfig) => {
        const token = getCookie(cookieName);
        req.headers['Authorization'] = `Bearer ${token}`;
        return req;
    };

    const logoutOn401 = (res: AxiosError) => {
        // console.log(res.config.url);

        if (res.response?.status === 401) {
            signOut();

            if (res.config.url === '/account/login') {
                throw res;
            }
        } else {
            throw res;
        }
    };

    http.interceptors.request.use(attachToken);

    useEffect(() => {
        const parseResponseId = http.interceptors.response.use(parseResponse);
        const logoutOn401Id = http.interceptors.response.use((r) => r, logoutOn401);

        return () => {
            // http.interceptors.request.eject(attachTokenId);
            http.interceptors.response.eject(parseResponseId);
            http.interceptors.response.eject(logoutOn401Id);
        };
    }, []);

    useInterval(
        () => {
            AccountApi.refreshToken().then((tokenData) => {
                refreshToken.updateAuthState(tokenData.access_token, 'Bearer', tokenData.expires_in);
            });
        },
        isAuthenticated() ? 15 * 60 * 1000 : null,
    );

    return <HttpContext.Provider value={null}>{children}</HttpContext.Provider>;
};
