import http from '@services/http';
import { CopyrightEntryFile, CopyrightEntry } from '@services/copyright-entries';
import { AccountData } from '@services/account';
import { Pagination, ServerPagination } from '@interfaces/pagination';

export interface UserDuplicate {
    id: number;
    original: number;
    original_file: CopyrightEntryFile;
    original_file_entry: CopyrightEntry;
    original_file_owner: AccountData;
    duplicate: number;
    duplicate_file: CopyrightEntryFile;
    duplicate_file_entry: CopyrightEntry;
    duplicate_file_owner: AccountData;
    distance: string;
    created_at: string;
    created_at_pretty: string[];
    updated_at: string;
}

export type UserDuplicatesResponse = ServerPagination<UserDuplicate[]>;

export default class UserDuplicatesApi {
    public static get(pagination: Pagination): Promise<UserDuplicatesResponse> {
        return http.get('admin/cross-matches', {
            params: {
                page: pagination.current,
            },
        });
    }
}
