import React from 'react';
import moment from 'moment';
import { Card } from 'antd';
import { DATE } from '@config/date';

// import { WebhookErrorMassage } from '@services/webhooks';

export interface WebhookErrorMassage {
    id?: string;
    content?: string;
    webhook_request?: string;
    created_at?: string;
    updated_at?: string;
}

interface Errors {
    errors?: WebhookErrorMassage[];
}

export default function ErrorMessages({ errors }: Errors): JSX.Element {
    const allErrorsMessages = errors?.map((error) => {
        return (
            <Card
                key={error.id}
                bordered={true}
                style={{
                    marginTop: 10,
                    marginBottom: 10,
                }}
            >
                <p>Received at: {moment(error.created_at).format(DATE.dateTimeWithSecondsFormat)}</p>
                <p style={{ color: '#fa8c16' }}>{error.content}</p>
            </Card>
        );
    });

    return <>{allErrorsMessages}</>;
}
