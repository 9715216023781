/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/display-name */
/* eslint-disable indent */
import React, { useEffect, useState, useRef } from 'react';
import { ROUTES } from '@config/routes';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { AdminLayout } from '@components/layouts/admin';
import { PageHeader, Table, TablePaginationConfig, Space, Tag, Input, Button, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import WebhooksApi, { WebhookMatch, WebhookRecord } from '@services/webhooks';
import { PAGINATION } from '@config/pagination';
import moment from 'moment';
import { DATE } from '@config/date';
import { Sorter } from '@services/webhooks';
import { FilterValue } from 'antd/lib/table/interface';

// import { InputRef } from 'antd/es/table';
import { SearchOutlined } from '@ant-design/icons';

interface RecordData {
    id: string | number;
    website: string;
    'website-owner': string;
    signature: string;
    status: string;
    attempts: number;
    errorMessages: string;
    matches: string;
    'date-created': string;
    'last-tried': string;
}

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

export const AdminWebhooksPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);

    const [pagination, setPagination] = useState<TablePaginationConfig>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [dataWebhooks, setDataWebhooks] = useState<WebhookMatch[]>([]);

    const fetchWebhooksData = async (pagination: TablePaginationConfig) => {
        setIsLoading(true);
        let dataWebhooks = await WebhooksApi.get(pagination);
        if (dataWebhooks?.data) {
            dataWebhooks = dataWebhooks?.data;
        }
        setDataWebhooks(dataWebhooks?.entities?.data);

        setPagination({
            total: dataWebhooks?.entities?.total,
            pageSize: dataWebhooks?.entities?.per_page,
            current: dataWebhooks?.entities?.current_page,
        });
        setIsLoading(false);
    };

    useEffect(() => {
        fetchWebhooksData(pagination);
    }, []);

    const record: RecordData[] = dataWebhooks?.map((dataWebhook) => {
        return {
            id: dataWebhook?.id,
            website: dataWebhook?.additionalInfo?.website?.name,
            'website-owner': dataWebhook?.additionalInfo?.website?.owner?.name,
            signature: dataWebhook?.signature.slice(0, 10),
            status: dataWebhook?.call_status,
            attempts: dataWebhook?.attempt,
            errorMessages: dataWebhook?.messages[0] ? 'In details ...' : '',
            matches: dataWebhook?.additionalInfo?.matches,
            'date-created': moment(dataWebhook.created_at).format(DATE.dateFormat),
            'last-tried': moment(dataWebhook.updated_at).format(DATE.dateFormat),
        };
    });

    const handleTableChange = async (
        pagination: TablePaginationConfig,
        filter: Record<string, FilterValue | null>,
        sorter: Sorter,
    ) => {
        setPagination((prevPagination: TablePaginationConfig) => {
            return { ...prevPagination, ...pagination };
        });

        let dataWebhooks = await WebhooksApi.getSortedAndFiltered(pagination, filter, {
            field: sorter.field,
            order: sorter.order?.toUpperCase().slice(0, -3) as 'ASC' | 'DESC',
        });

        if (dataWebhooks?.data) {
            dataWebhooks = dataWebhooks?.data;
        }

        setDataWebhooks(dataWebhooks?.entities?.data);

        const currentPagination = {
            total: dataWebhooks?.entities?.total,
            pageSize: dataWebhooks?.entities?.per_page,
            current: dataWebhooks?.entities?.current_page,
        };
        setPagination((prevPagination) => {
            const newPagination = { ...prevPagination, ...currentPagination };
            return newPagination;
        });
    };

    const handleSearch = (selectedKeys: string[], confirm: () => void, dataIndex: string) => {
        confirm();
    };

    const handleReset = (clearFilters: () => void) => {
        clearFilters();
    };

    const searchInput = useRef(null);

    const getColumnSearchProps = (dataIndex: string) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={`Search ${dataIndex}`}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered: boolean) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value: string, record: Record<string, string>) => {
            /* const recordCopy = record[dataIndex];
            console.log(
                'onFilter',
                value,
                record[dataIndex]?.toString().toLowerCase(),
                record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()),
            );
            return record[dataIndex]?.toString().toLowerCase().includes(value.toLowerCase()); */

            return true;
        },
        onFilterDropdownVisibleChange: (visible: boolean) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
    });

    const columns = [
        {
            title: '',
            key: 'website',
            render: function WebsiteNameColumn(text: string, record: WebhookRecord) {
                return (
                    <Space>
                        <Link to={ROUTES.ADMIN.WEBHOOK_DETAILS(record).INDEX}>
                            <SearchOutlined />
                        </Link>
                    </Space>
                );
            },
        },
        {
            title: 'Website',
            key: 'website',
            dataIndex: 'website',
            sorter: true,
            ...getColumnSearchProps('website'),
            //  TODO: should agree the link usage with customer
            render: function WebsiteNameColumn(text: string, record: WebhookRecord) {
                return <Space>{text}</Space>;
            },
        },
        {
            title: 'Website Owner',
            key: 'website-owner',
            dataIndex: 'website-owner',
            sorter: true,
            ...getColumnSearchProps('website-owner'),
            render: function WebsiteNameColumn(record: WebhookRecord) {
                return <Space>{record}</Space>;
            },
        },
        {
            title: 'Signature',
            key: 'signature',
            //  TODO: should agree the link usage with customer
            render: function PublisherNameColumn(record: WebhookRecord) {
                return (
                    <Space>
                        <Link to={ROUTES.ADMIN.WEBHOOK_DETAILS(record).INDEX}>{record?.signature + '...'}</Link>
                    </Space>
                );
            },
        },
        {
            title: 'Status',
            key: 'status',
            dataIndex: 'status',
            filterMultiple: false,
            filters: [
                {
                    text: 'SUCCESSFUL',
                    value: 'SUCCESSFUL',
                },
                {
                    text: 'FINAL_ATTEMPT_FAILED',
                    value: 'FINAL_ATTEMPT_FAILED',
                },
                {
                    text: 'PREV_ATTEMPT_FAILED',
                    value: 'PREV_ATTEMPT_FAILED',
                },
                {
                    text: 'SENT',
                    value: 'SENT',
                },
                {
                    text: 'NOT_SENT',
                    value: 'NOT_SENT',
                },
            ],
            sorter: true,
            onFilter: (value: string, record: WebhookRecord) => {
                return record.status.indexOf(value as string) === 0;
            },
            render: function WebsiteNameColumn(record: string) {
                return (
                    <Space>
                        {record === 'SUCCESSFUL' ? (
                            <Tag color="success">{record}</Tag>
                        ) : (
                            <Tag color="warning">{record}</Tag>
                        )}
                    </Space>
                );
            },
        },
        {
            title: 'Attempts',
            key: 'attempts',
            dataIndex: 'attempts',
            sorter: true,
            render: function WebsiteNameColumn(record: WebhookRecord) {
                return <Space>{record}</Space>;
            },
        },
        //  TODO: this column may be needed in the future
        // {
        //     title: 'Error messages',
        //     key: 'errorMessages',
        //     render: function WebsiteNameColumn(record: WebhookRecord) {
        //         return (
        //             <Space>
        //                 <Link to={ROUTES.ADMIN.WEBHOOK_DETAILS(record).INDEX}>{record ? 'In details ...' : ''}</Link>
        //             </Space>
        //         );
        //     },
        // },
        {
            title: 'Copyright Entry',
            key: 'copyright-entry',
            dataIndexEntry: 'copyright-entry',
            // fileEntry: 'copyright_entry_file',
            // websiteFile: 'website_file',
            sorter: true,
            width: '240px',
            ...getColumnSearchProps('copyright-entry'),
            render: function WebsiteNameColumn(record: WebhookRecord) {
                if (!('matches' in record)) {
                    return;
                }
                if (record['matches'] === undefined || !Array.isArray(record['matches'])) {
                    return;
                }
                if (record['matches'].length === 0) {
                    return;
                }

                let iteratorCounter = 0;
                let keyTemp = '';
                return record['matches'].map((file) => {
                    let copyrightEntryFile, websiteFile;
                    if (file?.copyright_entry_file?.status !== 'DELETED') {
                        copyrightEntryFile = (
                            <div>
                                <a href={file?.copyright_entry_file?.path} target="_blank" rel="noopener noreferrer">
                                    View Original
                                </a>
                            </div>
                        );
                    } else {
                        copyrightEntryFile = <div>View Original</div>;
                    }

                    if (file?.website_file?.status !== 'DELETED') {
                        websiteFile = (
                            <div>
                                <a href={file?.website_file?.path} target="_blank" rel="nooperner noreferrer">
                                    View Copyrighted
                                </a>
                            </div>
                        );
                    } else {
                        websiteFile = <div>View Copyrighted</div>;
                    }

                    keyTemp = `${record['id']}_${iteratorCounter++}_${file['copyright_entry_file']['id']}_${
                        file['website_file']['status']
                    }`;
                    return (
                        <Space key={keyTemp}>
                            {copyrightEntryFile}
                            {' | '}
                            {websiteFile}
                        </Space>
                    );
                });
            },
        },
        {
            title: 'Date Created',
            key: 'date-created',
            dataIndex: 'date-created',
            sorter: true,
        },
        {
            title: 'Last tried',
            key: 'last-tried',
            dataIndex: 'last-tried',
            sorter: true,
        },
    ];

    return (
        <AdminLayout>
            <Helmet>
                <title>Admin Webhooks | Copyknight Admin</title>
            </Helmet>

            <PageHeader title="Admin Webhooks">
                {isLoading ? (
                    <div style={{ display: 'flex', justifyContent: 'center', marginTop: '130px' }}>
                        <Spin indicator={antIcon} />
                    </div>
                ) : (
                    <>
                        <Table
                            locale={{ emptyText: 'No Webhook requests found in database!' }}
                            bordered
                            columns={columns}
                            pagination={pagination}
                            rowKey={(record) => record.id}
                            dataSource={record}
                            loading={isLoading}
                            onChange={handleTableChange}
                        />
                    </>
                )}
            </PageHeader>
        </AdminLayout>
    );
};
