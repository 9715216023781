import React from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useMutation, useQuery } from 'react-query';
import { Button, Card, Descriptions, notification, PageHeader, Space, Tag, Typography } from 'antd';
import { Link, useHistory, useParams } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import { AdminLayout } from '@components/layouts/admin';
import AdminUsersApi, { UserType } from '@services/admin-users';
import { ArrowLeftOutlined, ExclamationCircleOutlined } from '@ant-design/icons';
import { getUserRole } from '@util/user';
import { UserCopyrightList } from '@components/admin-user-details/user-copyright-list';
import useLoginAs from '@hooks/use-login-as';
import confirm from 'antd/lib/modal/confirm';
import { PublisherWebsiteList } from '@components/admin-user-details/publisher-website-list';
import { useAuthUser } from 'react-auth-kit';
import { deepSelect } from '@util/react-query';

interface Params {
    userId: UserType;
}

export const AdminUserDetailsPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);

    const { push } = useHistory();
    const { userId } = useParams<Params>();
    const auth = useAuthUser();
    const user = auth() as { is_admin: boolean; is_mod: boolean };

    const loggedInAsAdmin = user.is_admin;

    const loginAsMutation = useLoginAs();

    const { data, isLoading, refetch } = useQuery(['admin-user-details', userId], () => AdminUsersApi.getUser(userId), {
        keepPreviousData: true,
        select: deepSelect,
    });

    const isUser = !data?.user.is_admin && !data?.user.is_mod && !data?.user.is_publisher;
    const isPublisher = data?.user.is_publisher;
    const isMod = data?.user.is_mod;
    const isAdmin = data?.user.is_admin;

    const markUserAsTrusted = useMutation<void, void, { userId: string; trusted: boolean }>(
        async ({ userId, trusted }) => {
            try {
                await AdminUsersApi.markTrusted(String(userId), trusted);
                await refetch();
            } catch (error) {
                const errorMessage = error?.response?.data?.message;

                notification.error({
                    message: errorMessage ?? 'Error editing user',
                });
            }
        },
    );

    const confirmMarkAsTrusted = (userId: string, trusted: boolean) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure you want to mark this user as ${!trusted ? 'no longer' : ''} trusted?`,
            okText: trusted ? 'Mark as Trusted' : 'Mark as Untrusted',
            onOk() {
                markUserAsTrusted.mutate({ userId, trusted });
            },
        });
    };

    const markUserAsVerified = useMutation<void, void, { userId: string; verified: boolean }>(
        async ({ userId, verified }) => {
            try {
                await AdminUsersApi.edit(String(userId), { is_verified: verified });
                await refetch();
            } catch (error) {
                const errorMessage = error?.response?.data?.message;

                notification.error({
                    message: errorMessage ?? 'Error editing user',
                });
            }
        },
    );

    const confirmMarkAsVerified = (userId: string, verified: boolean) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure you want to mark this user as ${!verified ? 'no longer' : ''} verified?`,
            okText: verified ? 'Mark as verified' : 'Mark as not verified',
            onOk() {
                markUserAsVerified.mutate({ userId, verified });
            },
        });
    };

    const disableUser = useMutation<void, void, { userId: string; disabled: boolean }>(async ({ userId, disabled }) => {
        try {
            await AdminUsersApi.edit(userId, { is_disabled: disabled });
            await refetch();
        } catch (error) {
            const errorMessage = error?.response?.data?.message;

            notification.error({
                message: errorMessage ?? 'Error editing user',
            });
        }
    });

    const confirmDisableUser = (userId: string, disabled: boolean) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure you want to mark this user as ${!disabled ? 'no longer' : ''} disabled?`,
            okText: disabled ? 'Disable' : 'Enable',
            onOk() {
                disableUser.mutate({ userId, disabled });
            },
        });
    };

    const deleteUser = useMutation<void, void, { userId: string }>(async ({ userId }) => {
        try {
            await AdminUsersApi.deleteUser(String(userId));
            push(ROUTES.ADMIN.USERS.INDEX);
        } catch (error) {
            const errorMessage = error?.response?.data?.message;

            notification.error({
                message: errorMessage ?? 'Error deleting user',
            });
        }
    });

    const confirmDeleteUser = (userId: string) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: `Are you sure you want to delete this user?`,
            okText: 'Delete',
            okType: 'danger',
            onOk() {
                deleteUser.mutate({ userId });
            },
        });
    };

    return (
        <AdminLayout>
            <Helmet>
                <title>{`${data?.user.name} | Copyknight Admin`}</title>
            </Helmet>

            <PageHeader
                title={
                    <>
                        <Link to={ROUTES.ADMIN.USERS.INDEX}>
                            <Button type="link" icon={<ArrowLeftOutlined />} style={{ padding: 0 }}>
                                Users
                            </Button>
                        </Link>

                        <Typography.Title style={{ margin: 0 }} level={4}>
                            {data?.user.name}
                        </Typography.Title>
                    </>
                }
                extra={
                    <Space>
                        {!isMod && !isAdmin && (
                            <Button type="default" onClick={() => loginAsMutation.mutate(userId)}>
                                Login As
                            </Button>
                        )}
                        {loggedInAsAdmin && (
                            <Button type="default" onClick={() => confirmMarkAsTrusted(userId, !data?.user.is_trusted)}>
                                Make {data?.user.is_trusted ? 'Not' : ''} Trusted
                            </Button>
                        )}
                        <Button type="default" onClick={() => confirmMarkAsVerified(userId, !data?.user.is_verified)}>
                            Make {data?.user.is_verified ? 'Not' : ''} Verified
                        </Button>
                        {loggedInAsAdmin && (
                            <>
                                <Button
                                    type="default"
                                    onClick={() => confirmDisableUser(userId, !data?.user.is_disabled)}
                                >
                                    {data?.user.is_disabled ? 'Enable' : 'Disable'}
                                </Button>
                                {/* NOTE: Reset Password button should be deleted after agreement with the customer */}
                                {/* <Button
                                    type="default"
                                    onClick={() => push(`${ROUTES.ADMIN.USERS.RESET_PASSWORD}${data?.user.email}`)}
                                >
                                    {' '}
                                    Reset Password
                                </Button> */}
                            </>
                        )}
                        {loggedInAsAdmin && (
                            <Button type="ghost" danger onClick={() => confirmDeleteUser(userId)}>
                                Delete
                            </Button>
                        )}
                    </Space>
                }
            >
                <Card loading={isLoading}>
                    <Descriptions title="User Info">
                        <Descriptions.Item label="Role">{getUserRole(data?.user)}</Descriptions.Item>
                        <Descriptions.Item label="Is Trusted?">
                            {data?.user.is_trusted ? <Tag color="success">Yes</Tag> : <Tag color="warning">No</Tag>}
                        </Descriptions.Item>
                        <Descriptions.Item label="Name">{data?.user.name ?? 'N/a'}</Descriptions.Item>
                        <Descriptions.Item label="Email">{data?.user.email ?? 'N/a'}</Descriptions.Item>
                        <Descriptions.Item label="Address">{data?.user.address ?? 'N/a'}</Descriptions.Item>
                        <Descriptions.Item label="Homepage">{data?.user.homepage ?? 'N/a'}</Descriptions.Item>
                        <Descriptions.Item label="Company">{data?.user.company ?? 'N/a'}</Descriptions.Item>
                        <Descriptions.Item label="Telephone">{data?.user.telephone ?? 'N/a'}</Descriptions.Item>
                    </Descriptions>
                </Card>

                {isUser && <UserCopyrightList user={data?.user} />}
                {isPublisher && <PublisherWebsiteList user={data?.user} />}
            </PageHeader>
        </AdminLayout>
    );
};
