import http from './http';

export interface ForgotPasswordData {
    email: string;
}

export interface ResetPasswordData {
    email: string;
    token: string;
    password: string;
    password_confirmation: string;
}

export interface AccountResetPasswordData {
    email?: string;
    token?: string;
    old_password?: string;
    new_password: string;
    new_password_confirmation: string;
}

export interface LoginData {
    email: string;
    password: string;
    // hCaptchaToken: string;
}

export interface LoginResponse {
    message?: string;
    jwt: string;
    is_admin: boolean;
    is_mod: boolean;
    is_publisher: boolean;
    is_verified: boolean;
    is_trusted: boolean;
}

export interface RefreshTokenResponse {
    expires_in: number;
    access_token: string;
}

export interface AccountData {
    id?: number;
    name: string;
    email?: string;
    password: string;
    role: string;
    password_confirmation: string;
    company: string;
    address: string;
    telephone: string;
    homepage: string;
    receive_mail_notifications: boolean;
    data?: Record<string, unknown>;
}

export interface AccountResponse {
    jwt: string;
    is_admin: boolean;
    is_mod: boolean;
    is_verified: boolean;
    is_trusted: boolean;
}

export default class AccountApi {
    public static login(data: LoginData): Promise<LoginResponse> {
        return http.post('/account/login', data);
    }

    public static register(data: AccountData): Promise<AccountResponse> {
        return http.post('/account/register', data);
    }

    public static edit(id: number, data: AccountData): Promise<AccountResponse> {
        return http.put(`/account/${id}`, data);
    }

    // public static me(): Promise<{ user: AccountData }> {
    public static me(): Promise<{ user: AccountData; data?: { user: AccountData } }> {
        return http.get('/account/me');
    }

    public static forgotPassword(data: ForgotPasswordData): Promise<void> {
        return http.post('/account/forgotten_password', data);
    }

    public static resetPassword(data: ResetPasswordData): Promise<void> {
        console.log('resetPassword - data: ', data);
        return http.post(`/reset-password`, data);
    }
    public static accountPasswordReset(data: AccountResetPasswordData): Promise<void> {
        return http.post(`/account/password_reset`, data);
    }

    public static refreshToken(): Promise<RefreshTokenResponse> {
        return http.post(`/account/refresh`);
    }
}
