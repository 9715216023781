import React, { useEffect } from 'react';
import jwtDecode from 'jwt-decode';

import { GuestLayout } from '@components/layouts/guest';
import { useMutation } from 'react-query';
import AccountApi, { LoginData } from '@services/account';
import { AxiosError } from 'axios';
import { Button, Card, Col, Form, Input, Row } from 'antd';
import { useIsAuthenticated, useSignIn } from 'react-auth-kit';
import { Link, useHistory } from 'react-router-dom';
import { getDefaultRoute, useDefaultRoute } from '@hooks/default-route';
import { Helmet } from 'react-helmet-async';
import { ROUTES } from '@config/routes';
import { ApiError } from '@components/api-error';

interface LoginFormData {
    email: string;
    password: string;
}

export const LoginPage: React.FC = () => {
    const signIn = useSignIn();
    const { push } = useHistory();
    const isAuthenticated = useIsAuthenticated();
    const defaultRoute = useDefaultRoute();

    const hCaptchaConfig = {
        scriptUrl: 'https://js.hcaptcha.com/1/api.js',
        scriptElementId: 'h-captcha-load-script',
        elementClassName: 'h-captcha',
        dataSiteKey: process.env.REACT_APP_HCAPTCHA_DATA_SITE_KEY,
        responseToken: 'h-captcha-response',
        tokenBearingAttribute: 'data-hcaptcha-response',
    };
    const modalConfig = {
        title: 'CAPTCHA ERROR!!!',
        content: 'Please, solve provided captcha!',
    };

    const mutation = useMutation<void, AxiosError, LoginData>(async ({ email, password }) => {
        const loginData = await AccountApi.login({ email, password });

        if (
            loginData &&
            signIn({
                token: loginData?.jwt,
                expiresIn: 1000 * 60,
                tokenType: 'Bearer',
                authState: {
                    ...loginData,
                    id: jwtDecode<{ sub: number }>(loginData?.jwt)?.sub,
                },
            })
        ) {
            push(getDefaultRoute(loginData));
        } else {
            throw Error(loginData?.message ?? 'Failed to login');
        }
    });

    useEffect((): void => {
        const isAut = isAuthenticated();
        if (isAut) {
            push(defaultRoute);
        }

        // loadHCaptcha();
    }, []);

    const onFinish = ({ email, password }: LoginFormData) => {
        /* const validation = validateCaptcha();
        if (!validation.success) {
            Modal.error({
                title: modalConfig.title,
                content: modalConfig.content,
            });

            return;
        }

        const hCaptchaToken = validation.token; */

        mutation.mutate({
            email,
            password,
            // hCaptchaToken,
        });
    };

    const onFinishFailed = (values: any, errorFields: any, outOfDate: any) => {
        console.log('onFinishFailed', values, errorFields, outOfDate);
    };

    const loadHCaptcha = () => {
        const scriptElements = document.getElementsByTagName('script');
        let currentElement = null;
        for (let i = 0; i < scriptElements.length; i++) {
            currentElement = scriptElements[i];
            if (!currentElement.hasAttribute('id')) {
                continue;
            }
            if (currentElement.id === hCaptchaConfig.scriptElementId) {
                return;
            }
        }

        // bindCallbacks();

        insertElement();

        const scriptElement = document.createElement('script');
        scriptElement.src = hCaptchaConfig.scriptUrl;
        scriptElement.id = hCaptchaConfig.scriptElementId;
        scriptElement.async = true;
        scriptElement.defer = true;

        document.head.appendChild(scriptElement);
    };

    const bindCallbacks = () => {
        window['onload'] = (loadEvent: Event) => {
            console.log('@onload', loadEvent);
        };

        window['dataCallback'] = (responseToken: string) => {
            console.log('@dataCallback', responseToken);
        };

        window['dataExpiredCallback'] = () => {
            console.log('@dataExpiredCallback');
        };

        window['dataChalExpiredCallback'] = () => {
            console.log('@dataChalExpiredCallback');
        };

        window['dataOpenCallback'] = () => {
            console.log('@dataOpenCallback');
        };

        window['dataCloseCallback'] = () => {
            console.log('@dataCloseCallback');
        };

        window['dataErrorCallback'] = () => {
            console.log('@dataErrorCallback');
        };
    };

    const insertElement = () => {
        const formElement = document.getElementById('login-form');
        if (formElement === null) {
            return;
        }

        const hCaptchaContainer = document.createElement('div');
        hCaptchaContainer.className = hCaptchaConfig.elementClassName;
        hCaptchaContainer.setAttribute('data-sitekey', hCaptchaConfig.dataSiteKey);
        hCaptchaContainer.setAttribute('data-callback', 'dataCallback');
        hCaptchaContainer.setAttribute('data-expired-callback', 'dataExpiredCallback');
        hCaptchaContainer.setAttribute('data-chalexpired-callback', 'dataChalExpiredCallback');
        hCaptchaContainer.setAttribute('data-open-callback', 'dataOpenCallback');
        hCaptchaContainer.setAttribute('data-close-callback', 'dataCloseCallback');
        hCaptchaContainer.setAttribute('data-error-callback', 'dataErrorCallback');
        hCaptchaContainer.style.width = '100%';
        hCaptchaContainer.style.textAlign = 'center';
        hCaptchaContainer.style.marginBottom = '24px';

        const submitElement = findSubmitElement(formElement);
        // console.log(submitElement);
        formElement.insertBefore(hCaptchaContainer, formElement.children[submitElement.parentIndex]);
    };

    const findSubmitElement = (element: HTMLElement): any => {
        if (element.children.length > 0) {
            let found = null;
            for (let i = 0; i < element.children.length; i++) {
                found = findSubmitElement(element.children[i] as HTMLElement);
                if (found.found) {
                    return {
                        found: found.found,
                        element: found.element,
                        parentIndex: i,
                    };
                }
            }
        }

        if (element.nodeName.toLowerCase() !== 'input' && element.nodeName.toLowerCase() !== 'button') {
            return {
                found: false,
                element: null,
                parentIndex: null,
            };
        }
        if (!element.hasAttribute('type')) {
            return {
                found: false,
                element: null,
                parentIndex: null,
            };
        }
        if (element.getAttribute('type') !== 'submit') {
            return {
                found: false,
                element: null,
                parentIndex: null,
            };
        }

        return {
            found: true,
            element: element,
            parentIndex: null,
        };
    };

    const validateCaptcha = () => {
        const iFrames = document.getElementsByTagName('iframe');
        let token;
        let iFrameTemp = null;
        for (let i = 0; i < iFrames.length; i++) {
            iFrameTemp = iFrames[i];
            if (!iFrameTemp.hasAttribute(hCaptchaConfig.tokenBearingAttribute)) {
                continue;
            }

            token = iFrameTemp.getAttribute(hCaptchaConfig.tokenBearingAttribute);
            if (token === '' || token === null) {
                return {
                    success: false,
                    token: null,
                };
            }

            break;
        }

        return {
            success: true,
            token: token,
        };
    };

    return (
        <GuestLayout>
            <Helmet>
                <title>Login | Copyknight</title>
            </Helmet>

            <Row justify="center">
                <Col span={24} md={12} lg={10} xl={8}>
                    <Card title="Login to your account or change password" headStyle={{ textAlign: 'center' }}>
                        {mutation.isError ? <div>An error occurred: {mutation.error.message}</div> : null}
                        <Form
                            layout="vertical"
                            name="login"
                            id="login-form"
                            onFinish={onFinish}
                            onFinishFailed={onFinishFailed}
                        >
                            <Form.Item
                                label="Email"
                                name="email"
                                rules={[{ required: true, message: 'Please enter your email!' }]}
                            >
                                <Input placeholder="Email" />
                            </Form.Item>

                            <Form.Item
                                label="Password"
                                name="password"
                                rules={[{ required: true, message: 'Please enter your password!' }]}
                            >
                                <Input.Password placeholder="Password" />
                            </Form.Item>

                            {mutation.isError && (
                                <Form.Item>
                                    <ApiError error={mutation.error} />
                                </Form.Item>
                            )}

                            <Form.Item>
                                <Button
                                    type="primary"
                                    htmlType="submit"
                                    loading={mutation.isLoading}
                                    style={{ width: '100%' }}
                                >
                                    Login
                                </Button>
                            </Form.Item>

                            <Form.Item>
                                <Row justify="center">
                                    <Col>
                                        Need account? <Link to={ROUTES.REGISTER}> Register here</Link>
                                    </Col>
                                </Row>
                                <Row justify="center">
                                    <Col>
                                        Forgot password? <Link to={ROUTES.FORGOT_PASSWORD}> Create new</Link>
                                    </Col>
                                    {/* <Col>
                                        Change password? <Link to={ROUTES.FORGOT_PASSWORD}> Create new</Link>
                                    </Col> */}
                                </Row>
                            </Form.Item>
                        </Form>
                    </Card>
                </Col>
            </Row>
        </GuestLayout>
    );
};
