import { Pagination, ServerPagination } from '@interfaces/pagination';
import { VideoFrame } from '@interfaces/video-frame';
import { Nullable } from '@util/nullable';
import { User } from './admin-users';
import { CopyrightEntryFile, CopyrightEntryFileVideoFrame } from './copyright-entries';
import http from './http';
import { WebsiteFile } from './websites';

export interface VerificationMatch {
    id: number;
    distance: Nullable<string>;

    copyright_entry_file_id: number;
    copyright_entry_file: CopyrightEntryFile;

    website_file_id: number;
    website_file: WebsiteFile;

    website_owner_id: number;
    website_owner: User;

    copyright_entry_file_video_frame_id: Nullable<number>;
    copyright_entry_file_video_frame: Nullable<CopyrightEntryFileVideoFrame>;

    website_video_frame_id: Nullable<number>;
    website_video_frame: Nullable<VideoFrame>;

    created_at: string;
    updated_at: string;
}

export type VerificationMatchesResponse = ServerPagination<VerificationMatch[]>;

export default class VerificationMatchesApi {
    public static get(pagination: Pagination): Promise<VerificationMatchesResponse> {
        return http.get('/admin/verification-matches', {
            params: {
                page: pagination.current,
            },
        });
    }
}
