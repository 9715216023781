import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { UserLayout } from '@components/layouts/user';
import { useMutation, useQuery } from 'react-query';
import CopyrightsApi from '@services/copyrights';
import {
    Button,
    Card,
    Menu,
    notification,
    PageHeader,
    Result,
    Space,
    Table,
    TablePaginationConfig,
    Typography,
} from 'antd';
import { PAGINATION } from '@config/pagination';
import { Pagination } from 'interfaces/pagination';
import { Link, useParams } from 'react-router-dom';
import { ROUTES } from '@config/routes';
import CopyrightGroupsApi, { CopyrightGroup } from '@services/copyright-groups';
import { ArrowLeftOutlined, DeleteOutlined, EditFilled, ExclamationCircleOutlined } from '@ant-design/icons';
import confirm from 'antd/lib/modal/confirm';
import { AxiosError } from 'axios';
import { deepOptions, paginationSelect } from '@util/react-query';

interface UrlParams {
    copyrightId: string;
}

export const UserCopyrightGroupsIndexPage: React.FC = () => {
    useProtectedRoute(ROLES.USER);

    const { copyrightId: urlCopyrightId } = useParams<UrlParams>();

    const [copyrightId, setCopyrightId] = useState(urlCopyrightId);

    if (copyrightId !== ':copyrightId') {
        localStorage.setItem('copyrightId', copyrightId);
    } else {
        setCopyrightId(localStorage.getItem('copyrightId') as string);
    }

    const { data: copyright, isLoading: copyrightLoading } = useQuery(
        ['copyright', copyrightId],
        () => CopyrightsApi.getCopyright(localStorage.getItem('copyrightId') as string),
        deepOptions,
    );
    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { data, isLoading, refetch } = useQuery(
        ['my-copyright-groups', copyrightId, pagination],
        () => CopyrightGroupsApi.getGroups(Number(localStorage.getItem('copyrightId') as string), pagination),
        {
            keepPreviousData: true,
            select: paginationSelect,
        },
    );

    const deleteCopyrightGroup = useMutation<void, AxiosError, CopyrightGroup>(async (group) => {
        try {
            if (!group) {
                return;
            }
            await CopyrightGroupsApi.delete(localStorage.getItem('copyrightId') as string, String(group.id));
            await refetch();

            notification.success({
                message: 'Copyright group deleted!',
            });
        } catch (err) {
            const error = err as AxiosError;
            const errorMessage = error?.response?.data?.message;

            notification.error({
                message: errorMessage ?? 'Error deleting copyright group',
            });
        }
    });

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: data?.total,
            pageSize: data?.per_page,
            current: data?.current_page,
        });
    }, [data]);

    const onDelete = (group: CopyrightGroup) => {
        confirm({
            type: 'error',
            icon: <ExclamationCircleOutlined />,
            content: 'Are you sure you want to delete this copyright group?',
            okText: 'Delete',
            okType: 'danger',
            onOk() {
                deleteCopyrightGroup.mutate(group);
            },
        });
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filters: Record<string, (string | number | boolean)[] | null>,
    ) => {
        setPagination(pagination);
    };

    const columns = [
        {
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
        },
        {
            title: 'Action',
            dataIndex: 'action',
            key: 'action',
        },
        {
            title: 'Banner Collection',
            render: function BannerCollecitonColumn(copyrightGroup: CopyrightGroup) {
                return copyrightGroup.copyright_banner_collections?.name ?? '';
            },
        },
        {
            width: 30,
            title: '',
            render: function Actions(group: CopyrightGroup) {
                return (
                    <Space>
                        <Link to={ROUTES.USER.COPYRIGHT_GROUPS(copyright).DETAILS(group)}>
                            <Button type="link">
                                <EditFilled />
                            </Button>
                        </Link>
                        <Button danger onClick={() => onDelete(group)}>
                            <DeleteOutlined />
                        </Button>
                    </Space>
                );
            },
        },
    ];

    return (
        <UserLayout>
            <Helmet>
                <title>My Copyright Groups | Copyknight</title>
            </Helmet>

            <PageHeader
                title={
                    <Space direction="vertical">
                        <Link to={ROUTES.USER.COPYRIGHTS.INDEX}>
                            <Button type="link" icon={<ArrowLeftOutlined />} style={{ padding: 0 }}>
                                Copyrights
                            </Button>
                        </Link>
                        <Typography.Title level={4}>{copyright?.name}</Typography.Title>
                    </Space>
                }
            >
                <Menu mode="horizontal" selectedKeys={['groups']}>
                    <Menu.Item key="entries">
                        <Link to={ROUTES.USER.COPYRIGHT_ENTRIES(copyright).INDEX}>Copyright Entries</Link>
                    </Menu.Item>
                    <Menu.Item key="groups">
                        <Link to={ROUTES.USER.COPYRIGHT_GROUPS(copyright).INDEX}>Copyright Groups</Link>
                    </Menu.Item>
                    <Menu.Item key="collections">
                        <Link to={ROUTES.USER.BANNER_COLLECTIONS(copyright).INDEX}>Banner Collections</Link>
                    </Menu.Item>
                </Menu>

                <Card>
                    <PageHeader
                        title={
                            <Space direction="vertical">
                                <Typography.Title level={4}>Copyright Groups</Typography.Title>
                            </Space>
                        }
                        extra={[
                            <Link key="1" to={ROUTES.USER.COPYRIGHT_GROUPS(copyright).CREATE}>
                                <Button type="primary" disabled={!copyright?.approved}>
                                    Create
                                </Button>
                            </Link>,
                        ]}
                    >
                        {!copyrightLoading && !copyright?.approved && (
                            <Result
                                status="warning"
                                title="Your Copyright is not accepted yet"
                                subTitle="Once accepted, you'll be able to add copyright entries, groups and banner collections."
                            />
                        )}
                        <Table
                            locale={{ emptyText: 'No Copyright groups yet' }}
                            bordered
                            columns={columns}
                            pagination={pagination}
                            rowKey="id14"
                            dataSource={data?.data}
                            loading={isLoading}
                            onChange={handleTableChange}
                        />
                    </PageHeader>
                </Card>
            </PageHeader>
        </UserLayout>
    );
};
