import React, { useEffect, useState } from 'react';

import { Helmet } from 'react-helmet-async';
import { useProtectedRoute } from '@hooks/protected-route';
import { ROLES } from '@config/roles';
import { useQuery } from 'react-query';
import { AdminLayout } from '@components/layouts/admin';
import { PageHeader, Table, TablePaginationConfig, Space, Tag, Typography, Tooltip } from 'antd';
import { UserDuplicate } from '@services/user-duplicates';
import { Pagination } from '@interfaces/pagination';
import { PAGINATION } from '@config/pagination';
import UserDuplicatesApi from '@services/user-duplicates';
import { paginationSelect } from '@util/react-query';

export const AdminUserDuplicatesPage: React.FC = () => {
    useProtectedRoute(ROLES.ADMIN);

    const { Link } = Typography;

    const [pagination, setPagination] = useState<Pagination>({
        current: 1,
        pageSize: PAGINATION.pageSize,
    });

    const { isLoading, data } = useQuery(
        ['admin', 'cross-matches', pagination],
        () => UserDuplicatesApi.get(pagination),
        { keepPreviousData: true, select: paginationSelect },
    );

    useEffect(() => {
        /**
         * Set pagination state from server response
         */
        setPagination({
            total: data?.total,
            pageSize: data?.per_page,
            current: data?.current_page,
        });
    }, [data]);

    const handleTableChange = (
        pagination: TablePaginationConfig,
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        filters: Record<string, (string | number | boolean)[] | null>,
    ) => {
        setPagination(pagination);
    };

    const columns = [
        {
            title: 'Original File',
            render: function getOriginalFile(userDuplicate: UserDuplicate) {
                if (userDuplicate.original_file.deleted_at !== null) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            <Space>
                                <Tag color="red">DELETED</Tag>
                            </Space>
                        </div>
                    );
                }

                return (
                    <div style={{ textAlign: 'center' }}>
                        <Space>
                            <Link href={userDuplicate.original_file.path} target="_blank">
                                Open file
                            </Link>
                        </Space>
                    </div>
                );
            },
        },
        {
            title: 'Original Copyright Entry',
            render: function getOriginalFileCopyrightEntry(userDuplicate: UserDuplicate) {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <Space>{userDuplicate.original_file_entry.title}</Space>
                    </div>
                );
            },
        },
        {
            title: 'Original File Owner',
            render: function getOriginalFileOwner(userDuplicate: UserDuplicate) {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <Tooltip title={userDuplicate.original_file_owner.email}>
                            <Space>{userDuplicate.original_file_owner.name}</Space>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: 'Uniqueness Status',
            render: function getOriginalFileUniquenessStatus(userDuplicate: UserDuplicate) {
                if (userDuplicate.original_file.uniqueness_checked) {
                    const tagChecked = (
                        <Tooltip title={'Check done at: ' + userDuplicate.original_file.uniqueness_checked_at}>
                            <Tag color="geekblue">CHECKED</Tag>
                        </Tooltip>
                    );

                    let tagUnique = null;
                    if (userDuplicate.original_file.is_unique) {
                        tagUnique = <Tag color="green">UNIQUE</Tag>;
                    } else {
                        tagUnique = <Tag color="orange">NOT_UNIQUE</Tag>;
                    }

                    return (
                        <div style={{ textAlign: 'center' }}>
                            <Space>
                                {tagChecked}
                                {tagUnique}
                            </Space>
                        </div>
                    );
                } else {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            <Space>
                                <Tag color="magenta">NOT_CHECKED</Tag>
                            </Space>
                        </div>
                    );
                }
            },
        },
        {
            title: 'Duplicate File',
            render: function getDuplicateFile(userDuplicate: UserDuplicate) {
                if (userDuplicate.duplicate_file.deleted_at !== null) {
                    return (
                        <div style={{ textAlign: 'center' }}>
                            <Space>
                                <Tag color="red">DELETED</Tag>
                            </Space>
                        </div>
                    );
                }

                return (
                    <div style={{ textAlign: 'center' }}>
                        <Space>
                            <Link href={userDuplicate.original_file.path} target="_blank">
                                Open file
                            </Link>
                        </Space>
                    </div>
                );
            },
        },
        {
            title: 'Duplicate Copyright Entry',
            render: function getDuplicateFileCopyrightEntry(userDuplicate: UserDuplicate) {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <Space>{userDuplicate.duplicate_file_entry.title}</Space>
                    </div>
                );
            },
        },
        {
            title: 'Duplicate File Owner',
            render: function getDuplicateFileOwner(userDuplicate: UserDuplicate) {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <Tooltip title={userDuplicate.original_file_owner.email}>
                            <Space>{userDuplicate.original_file_owner.name}</Space>
                        </Tooltip>
                    </div>
                );
            },
        },
        {
            title: 'Distance',
            render: function getDistance(userDuplicate: UserDuplicate) {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <Space>{userDuplicate.distance}</Space>
                    </div>
                );
            },
        },
        {
            title: 'Date Detected',
            render: function getDateChecked(userDuplicate: UserDuplicate) {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <Tooltip title={userDuplicate.created_at_pretty.full}>
                            <Space>{userDuplicate.created_at_pretty.date}</Space>
                        </Tooltip>
                    </div>
                );
            },
        },
    ];

    return (
        <AdminLayout>
            <Helmet>
                <title>Admin User Duplicates | Copyknight Admin</title>
            </Helmet>

            <PageHeader title="Admin User Duplicates(cross-matches)">
                {!isLoading && (
                    <Table
                        locale={{ emptyText: 'No user duplicates yet' }}
                        bordered
                        columns={columns}
                        pagination={pagination}
                        rowKey="id3"
                        dataSource={data?.entries?.data}
                        loading={isLoading}
                        onChange={handleTableChange}
                    />
                )}
            </PageHeader>
        </AdminLayout>
    );
};
