import React from 'react';
import { Tag } from 'antd';
import { User } from '@services/admin-users';

export function getUserRole(user?: User): JSX.Element {
    if (!user) {
        return <Tag>N/a</Tag>;
    }

    if (user.is_admin) {
        return <Tag color="green">Admin</Tag>;
    }

    if (user.is_mod) {
        return <Tag color="lime">Mod</Tag>;
    }

    if (user.is_publisher) {
        return <Tag color="volcano">Publisher</Tag>;
    }

    return <Tag color="purple">User</Tag>;
}
