import React from 'react';

import { GuestLayout } from '@components/layouts/guest';
import { Card, Col, Row, Typography } from 'antd';
import { useIsAuthenticated } from 'react-auth-kit';
import { Link, useHistory } from 'react-router-dom';
import { useDefaultRoute } from '@hooks/default-route';
import { Helmet } from 'react-helmet-async';
import { ROUTES } from '@config/routes';

export const RegisterSuccessPage: React.FC = () => {
    const { push } = useHistory();
    const isAuthenticated = useIsAuthenticated();
    const defaultRoute = useDefaultRoute();

    if (isAuthenticated()) {
        push(defaultRoute);
        return null;
    }

    return (
        <GuestLayout>
            <Helmet>
                <title>Account created | Copyknight</title>
            </Helmet>

            <Row justify="center">
                <Col span={24} md={12} lg={10} xl={8}>
                    <Card title="Account created">
                        <Row>
                            <Col>
                                <Typography.Text>
                                    Your account was successfully created. Please activate your account by clicking the
                                    link sent to your email.
                                </Typography.Text>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Link to={ROUTES.LOGIN}>Back to Login</Link>
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        </GuestLayout>
    );
};
