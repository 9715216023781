import React from 'react';
import { Card } from 'antd';

enum OwnerType {
    ADMIN = 'admins',
    USER = 'users',
    PUBLISHER = 'publishers',
}
interface Owner {
    roles: OwnerType[];
}

export default function OwnerRoles({ roles }: Owner): JSX.Element {
    const rolesArray = roles.map((role) => (
        <Card
            key={role}
            bordered={true}
            style={{
                marginTop: 2,
                marginBottom: 2,
            }}
        >
            <span>{role}</span>
        </Card>
    ));

    return <>{rolesArray}</>;
}
